import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-carrier-mobile',
  templateUrl: './landing-carrier-mobile.component.html',
  styleUrls: ['./landing-carrier-mobile.component.scss']
})
export class LandingCarrierMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
