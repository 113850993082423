import { HttpClient, HttpResponse, } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { StorageService } from './storage.service';
import { API_Routes } from '../../environments/environment'
import { OrderCleaner } from './cleaners/order.cleaner.service';
import { Volume } from '../models/volume.model';
import { SharedService } from './shared.service';
import { DateService } from './date.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import axios, { AxiosInstance } from 'axios'



@Injectable({
  providedIn: 'root'
})
export class OrderService {
  userInfo
  axios: AxiosInstance
  itemDec: any;
  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private actRoute: ActivatedRoute,
    private orderCleaner: OrderCleaner,
    private sharedService: SharedService,
    private dateService: DateService
  ) {
    this.actRoute.data.subscribe(v => {
      this.userInfo = v.userInfo
    })
  }

  // payment for pingbank standard
  sendPayment(data) {
    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/TransactionCard`, data)
      .toPromise()
      .then(async response => {
        return response
      })
  }

  getCurrentOrder() {
    return JSON.parse(this.storageService.get('currentOrder'))
  }

  setCurrentOrder(volume, freight, calculosFromEach) {


    const order = {
      volume: [...volume],
      freight: { ...freight },
      allCalcs: [...calculosFromEach]
    }


    let orderString = JSON.stringify(order)

    // this.storageService.add('currentOrder', orderString)
    this.sharedService.changeCurrentOrder(orderString)
    return true
  }

  getSending = params => {
    const reqBody = {
      "userId": params.userId || 0,
      "searchVendedor": params.searchVendedor || '',
      "searchPagamento": params.searchPagamento || '',
      "searchUnidade": params.searchUnidade || '',
      "startDate": params.startDate || '',
      "endDate": params.endDate || '',
      "searchOperador": params.searchOperador || '',
      "pageNumber": params.pageNumber || 1,
      "rowsPerPage": params.rowsPerPage || 10,
      "searchCarrier": params.searchCarrier,
      "searchCarrierId": params.searchCarrierId,
      "searchStatus": params.searchStatus || ''
    }

    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Sending/GetSending`, reqBody)
      .toPromise()
      .then(response => {
        response.data = this.orderCleaner.cleanOrder(response.data)
        return response
      })
      .catch(e => console.error(e))
  }

  createOrder = (params) => {
    let carrierId = params.addOrderFreight[0].carrierId
    localStorage.getItem('isDc')
    let userCookies = this.storageService.get('userInfo')
    userCookies = userCookies ? JSON.parse(atob(userCookies)) : null
    let token = userCookies.token

    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Order`, params, { headers: { Authorization: `Bearer ${token}` } })
      .toPromise()
      .then(async response => {
        if (response.sucess) {
          let orderId = response.id

          let correios = carrierId < 3 ? true : false

          await this.getLabel(orderId, correios)
          return response
        } else {
          return response.message
        }
      })
      .catch(e => console.error(e))
  }


  async getLabel(orderId, correios,) {
    const label = await this.createLabel(orderId, correios)

    const item = localStorage.getItem('dc')
    const itemDec = localStorage.getItem('contentData')

    if (item == 'true' && itemDec != null || itemDec !=undefined) { const download = await this.createContentDeclaration(orderId, correios) }

    for (let l of label) {
      const labelDownload = await this.downloadLabel(l.trackingNumber, correios)
      const labelName = this.dateService.getLabelName()
      this.downloadFile(labelDownload, `${labelName}.zpl`)


    }
    this.downloadPdf(orderId)

  }

  createOrderPDF = (params) => {

    let carrierId = params.addOrderFreight[0].carrierId
    let userCookies = this.storageService.get('userInfo')
    userCookies = userCookies ? JSON.parse(atob(userCookies)) : null
    let token = userCookies.token

    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Order`, params, { headers: { Authorization: `Bearer ${token}` } })
      .toPromise()
      .then(async response => {

        if (response.sucess) {
          let orderId = response.id

          let correios = carrierId < 3 ? true : false

          await this.getLabelPDF(orderId, correios)

          return response
        } else {
          return response.message
        }
      })
      .catch(e => console.error(e))
  }


  async getLabelPDF(orderId, correios) {

    const label = await this.createLabel(orderId, correios)
    const item = localStorage.getItem('dc')
    const itemDec = localStorage.getItem('contentData')
    if (item == 'true' && itemDec != null || itemDec !=undefined) { const download = await this.createContentDeclaration(orderId, correios) }
    for (let l of label) {

      const labelDownload = await this.downloadLabelPDF(l.trackingNumber, correios)

      const labelName = this.dateService.getLabelName()
      this.downloadFile(labelDownload, `ETI${labelName}.pdf`)
    }

    this.downloadPdf(orderId)

  }

  createMKTPOrder(order) {
    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Order`, order)
      .toPromise()
      .then(async response => {

        if (response.sucess) {
          this.downloadMKTPPdf(response.data.id)
          return response
        }
      }).catch(e => console.error(e))
  }

  async getOrderSumary(orderId): Promise<any> {
    const res = await this.http.get(`${API_Routes.DATA_ORDER_API}/api/Order/DownloadOrderSummary?orderId=${orderId}`, { headers: { accept: 'application/pdf', contentType: 'application/pdf' } })
      .toPromise()
      .then(response => {

        return response
      }).catch(e => console.error(e))
    return res
  }

  downloadPdf(orderId) {
    window.open(`${API_Routes.DATA_ORDER_API}/api/Order/DownloadOrderSummary?orderId=${orderId}`, '_blank')
  }
  downloadMKTPPdf(orderId) {
    window.open(`${API_Routes.DATA_ORDER_API}/api/Order/DownloadCustomerOrderSummary?orderId=${orderId}`, '_blank')
  }

  downloadLabel(trackingNumber, correios) {
    let url = correios ? '/api/Correios/DownloadLabel' : '/api/LabelGenerics/DownloadLabel'

    if (trackingNumber) {

      const params = {
        trackingNumber: trackingNumber,
        typeFile: 'zpl'
      }

      return this.http.post(`${API_Routes.DATA_API + url}`, params, { responseType: 'blob', headers: { contentType: 'application/zpl' } })
        .toPromise()
        .then(response => {

          return response
        })
    }
  }
  downloadLabelPDF(trackingNumber, correios) {

    let url = correios ? '/api/Correios/DownloadLabel' : '/api/LabelGenerics/DownloadLabel'

    if (trackingNumber) {


      const params = {
        trackingNumber: trackingNumber,
        typeFile: 'pdf'
      }

      return this.http.post(`${API_Routes.DATA_API + url}`, params, { responseType: 'blob', headers: { contentType: 'application/pdf' } })
        .toPromise()
        .then(response => {

          return response
        })
    }
  }



  downloadFile(file, fileName) {
    var url = URL.createObjectURL(file)
    var a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    return true
  }

  createLabel(orderId, correios) {
    const params = {
      'orderId': orderId
    }
    let url = correios ? '/api/Correios/CreateLabel' : '/api/LabelGenerics/CreateLabel'
    this.storageService.add('currentLabel', JSON.stringify({ orderId: orderId, correios: correios }))
    return this.http.post<any>(`${API_Routes.DATA_ORDER_API + url}`, params)
      .toPromise()
      .then(response => {

        return response.data.objects
      }).catch(e => console.error(e))
  }

  //declaração de conteúdo
  createContentDeclaration(orderId, item) {
    item = localStorage.getItem('contentData')
    item = JSON.parse(item)



    const params = {
      'orderId': orderId,
      'items': item
    }

    if(params != null){
    return this.http.post(`${API_Routes.DATA_ORDER_API}/api/ContentDeclaration/Create`, params, { responseType: 'blob' })
      .toPromise()
      .then(response => {

        this.downloadFile(response, 'declaracao.pdf')
        return response
      })
    }
  }

  getContentDownload(orderId) {
    return this.http.get(`${API_Routes.DATA_ORDER_API}/api/ContentDeclaration/Download?orderId=${orderId}`, { responseType: 'blob' })
      .toPromise()
      .then(response => {

        this.downloadFile(response, 'declaracao.pdf')
        return response
      })
      .catch(e => console.error(e))
  }

  getAddress(cep) {
    return this.http.get(`${API_Routes.DATA_API}/api/Correios?zip=${cep}`)
      .toPromise()
      .then(response => {

        return response
      })
      .catch(e => console.error(e))
  }

  getReportPDF(params) {

    return this.http.post(`${API_Routes.DATA_ORDER_API}/api/Order/DowloadRelatorio`, params, { responseType: 'blob', headers: { contentType: 'application/pdf' } })
      .toPromise()
      .then(response => {

        this.downloadFile(response, 'relatorio.pdf')
        return response
      })
  }
  getReportXLSX(params) {
    return this.http.post(`${API_Routes.DATA_ORDER_API}/api/Order/DowloadRelatorioXLSX`, params, { responseType: 'blob', headers: { contentType: 'application/xlsx' } })
      .toPromise()
      .then(response => {

        this.downloadFile(response, 'relatorio.xlsx')
        return response
      })
  }

  getTrackingInfo(trackCode) {
    return this.http.get(`${API_Routes.DATA_API}/api/Correios/GetInfoTracking?tracking=${trackCode}`)
      .toPromise()
      .then(response => {

        return response
      })
      .catch(e => console.error(e))
  }

  downloadTracking(trackCode) {
    return this.http.get(`${API_Routes.DATA_API}/api/Correios/DowloadTracking?trackingNumber=${trackCode}`, { responseType: 'blob', headers: { contentType: 'application/pdf' } })
      .toPromise()
      .then(response => {

        // if(!response.status){
        //   return response
        // }else {

        let download = this.downloadFile(response, `${trackCode}.pdf`)
        if (download) {
          return { sucess: true }
        } else {
          return { sucess: false }
        }
        // }
      })
      .catch(e => console.error(e))
  }

  getAllCarriers() {
    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Correios/GetAllCarrier`, {})
      .toPromise()
      .then(response => {
        response.data = this.orderCleaner.cleanCarriers(response.data)
        return response
      })
      .catch(e => console.error(e))
  }

  getCarrierFilters() {
    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Correios/GetListCarrier`, {})
      .toPromise()
      .then(response => {
        response.data = this.orderCleaner.cleanCarriers(response.data)
        return response
      })
      .catch(e => console.error(e))
  }

  async downloadLabelS(trackingNumber, correios) {
    const labelDownload = await this.downloadLabel(trackingNumber, correios)

    const labelName = this.dateService.getLabelName()
    this.downloadFile(labelDownload, `${labelName}.zpl`)
  }
  async downloadLabelSForPDF(trackingNumber, correios) {
    const labelDownload = await this.downloadLabelPDF(trackingNumber, correios)

    const labelName = this.dateService.getLabelName()
    this.downloadFile(labelDownload, `${labelName}.pdf`)
  }

  setOrderPaid(orderId) {
    const params = {
      "id": orderId,
      "statusId": 3,
    }
    return this.http.post(`${API_Routes.DATA_ORDER_API}/api/Order`, params)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }


  editOrderPaid(orderId, userId) {
    const params = {
      "id": orderId,
      "userId": userId,
      "statusId": 3,
    }
    return this.http.put(`${API_Routes.DATA_ORDER_API}/api/Order/UpdateStatusOrder`, params)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }
  askCancel(orderId, userId) {
    const params = {
      "id": orderId,
      "userId": userId,
      "statusId": 8,
    }
    return this.http.put(`${API_Routes.DATA_ORDER_API}/api/Order/UpdateStatusOrder`, params)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }
  cancelOrder(orderId, userId) {
    const params = {
      "id": orderId,
      "userId": userId,
      "statusId": 7,
    }
    return this.http.put(`${API_Routes.DATA_ORDER_API}/api/Order/UpdateStatusOrder`, params)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }



}
