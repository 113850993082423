<!-- header -->


    <div class="fixed-head" [class.headVisible]="headVisible">
        <div class="franchising">
            <div class="wrapper">
            <div class="col-lg-6 pr-0">
                <div class="container head">
                    <div class="row">
                        <div class="col-md-4 pr-0"><span class="hover-fst" routerLink="seja-um-franqueado">Seja um franqueado</span></div>
                        <div class="icon">
                    <img class="w-80" src="../../../assets/img/Grupo 1689.png">
                </div>
                        <div class="col-md-2 pr-0"><span class="hover-scnd"  (click)="scrollTracking()">Rastreio</span></div>
                        <div class="icon">
                            <img class="w-80" src="../../../assets/img/map-marked-alt.png">
                        </div>
                        <div class="col-md-2 pr-0"><span class="hover-trd"  (click)="scrollToCalc()">Cotação</span></div>
                        <div class="icon">
                            <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="wrapper mt-3">
            <div class="logo-box">
                <div class="logo logo-white" aria-label="Logotipo Express" routerLink="/" [inlineSVG]="'assets/svg/Logo_Branco_SVG.svg'"></div>
                <div class="logo logo-classic" aria-label="Logotipo Express" routerLink="/" [inlineSVG]="'assets/svg/Logo_Principal_SVG.svg'"></div>
            </div>
            <nav role="navigation" class="show">
                <div id="menuToggle">
                    <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul id="menu">
                        <img src="../../../assets/svg/Logo_FundoEscuro_SVG.svg">
                        <a >
                            <li routerLink="seja-um-franqueado">Seja um Franqueado</li>
                        </a>
                        <a routerLink='login'>
                            <li>Login</li>
                        </a>
                        <a routerLink="cadastre-se">
                            <li>Cadastre-se</li>
                        </a>
                        <a >
                            <li class="hide" (click)="scrollTracking()">Rastreio</li>
                        </a>
                        <a>
                            <li (click)="scrollToCalc()">Cotação</li>
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5511911246997&text=Ol%C3%A1%20Pic%20Envios!" target="_blank">
                            <li>Ajuda</li>
                        </a>
                    </ul>
                </div>
            </nav>
            <nav class="nav-menu" >
                <ul class="menu">
                    <li style="display: none;" class="menu-item"><a class="menu-link">Suporte</a></li>
                    <li *ngIf="userSigned" class="menu-item box-submenu" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseOver($event)" >
                        <div class="menu-item-content">
                            <div class="user-icon">
                                <div class="icon">
                                    <fa-icon [icon]="faUserCircle"></fa-icon>
                                </div>
                            </div>
                            <a class="menu-link">Olá, <b>{{ userInfo?.name }}</b>!</a>
                            <div class="submenu-toggler">
                                <div class="icon">
                                    <fa-icon [class.toggler-up]="submenuVisible" [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>
                            <div class="badge-access" *ngIf="userInfo?.outpost != 1">
                                <div class="icon">
                                    <fa-icon *ngIf="userInfo.typeUserId != 1" [icon]="faUserTie"></fa-icon>
                                    <fa-icon *ngIf="userInfo.typeUserId == 1" [icon]="faUserCrown"></fa-icon>
                                </div>
                                {{ userInfo?.typeUser }}
                            </div>
                        </div>
                        <ul [class.active]="submenuVisible" class="submenu" >
                            <li class="menu-item" *ngIf="userInfo.outpost != 1">
                                <a [routerLink]="'painel/cotacao'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                                    </div>
                                    Cotação
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6 && userInfo?.typeUserId !=1 && userInfo?.outpost != 1">
                                <a [routerLink]="'painel/atendimento'" class="submenu-link">
                                    <div class="icon">
                                    <img style="width: 1.5rem;"
                                        src="../../../../assets/svg/customer-service-cinza.svg" alt="atendimento">
                                    </div>
                                    Atendimento
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.outpost == 1 && userInfo?.typeUserId !=6">
                                <a [routerLink]="'painel/cotacao-pa'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                                    </div>
                                    Cotação e Etiqueta
                                </a>
                            </li>

                            <li class="menu-item" *ngIf="userInfo?.typeUserId !=6">
                                <a [routerLink]="'painel/envios'" class="submenu-link" >
                                    <div class="icon">
                                        <fa-icon [icon]="faBoxCheck"></fa-icon>
                                    </div>
                                    Envios
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.outpost != 1 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6">
                                <a [routerLink]="'painel/clientes'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faUsers"></fa-icon>
                                    </div>
                                    Clientes
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.outpost == 1 || userInfo?.typeUserId !=6">
                                <a [routerLink]="'painel/destinatarios'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faUsers"></fa-icon>
                                    </div>
                                    Destinatários
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.typeUserId != 1 && userInfo?.typeUserId == 5 || userInfo?.typeUserId !== 3 && userInfo?.typeUserId != 6">
                                <a [routerLink]="'painel/expedition'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faShippingFast"></fa-icon>
                                    </div>
                                    Expedição
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.typeUserId == 5 || userInfo?.typeUserId == 1 && userInfo?.typeUserId != 6">
                                <a [routerLink]="'painel/minha-expedicao'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faShippingFast"></fa-icon>
                                    </div>
                                    Minha Expedição
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.typeUserId != 1 && userInfo?.outpost != 1 && userInfo?.typeUser == 3 && userInfo?.typeUserId != 6">
                                <a [routerLink]="'painel/postagem'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faPallet"></fa-icon>
                                    </div>
                                    Postagem
                                </a>
                            </li>
                            <li class="menu-item" >
                                <a [routerLink]="'painel/rastreio'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faMapMarker"></fa-icon>
                                    </div>
                                    Rastreio
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="userInfo?.outpost != 1 && userInfo?.typeUserId != 3 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6">
                                <a [routerLink]="'painel/minhas-unidades'" class="submenu-link">
                                    <div class="icon">
                                    <img style="width: 1.5rem;"
                                        src="../../../../assets/svg/commerce-azul.svg" alt="atendimento">
                                    </div>
                                    Unidades
                                </a>
                            </li>
                            <!-- <li class="menu-item" *ngIf="userInfo?.outpost != 1  && userInfo?.typeUserId != 5">
                                <a [routerLink]="'painel/cofre'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faPiggyBank"></fa-icon>
                                    </div>
                                    Cofre
                                </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.typeUserId == 3">
                            <a [routerLink]="'painel/cofre'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faPiggyBank"></fa-icon>
                                </div>
                                Fatura
                            </a>
                        </li> -->
                            <li class="menu-item log-out">
                                <div class="submenu-separator"></div>
                                <a class="submenu-link" (click)="logOut()">
                                    <div class="icon">
                                        <fa-icon [icon]="faSignOutAlt"></fa-icon>
                                    </div>
                                    Sair
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="!userSigned" [routerLink]="['login']" class="menu-item"><a class="menu-link">Login</a></li>
                    <li *ngIf="!userSigned" [routerLink]="['cadastre-se']" class="menu-item"><a class="menu-link">Cadastre-se</a></li>
                </ul>
            </nav>

        </div>
    </div>



<!-- fim header -->

<!-- primeiro banner desktop -->
<section class="page home-banner-top">
    <div class="wrapper">
        <div class="page-banner" aria-label="Page Banner"></div>
        <div class="banner-content">
            <h1 class="home-title">Vocês vendem...</h1>
            <h1 class="home-title-orange">Nós cuidamos do envio...</h1>
            <h1 class="home-title">Eles recebem em casa!</h1>
            <span class="home-subtitle">
                Somos uma empresa de <strong>hub logístico</strong> voltado para <br><strong>envio de
                    encomendas</strong>, que tem como visão<br> auxiliar nos envios de seus produtos.
            </span>
            <div class="container-btn">
                <a class="btn btn-lg btn-orange" href="{{!userSigned ? 'login' : 'painel/bem-vindo'}}">Área do
                    Cliente</a>
            </div>
        </div>
    </div>
</section>
<!-- fim primeiro banner -->

<!-- primeiro banner mobile -->
<div class="header-mobile">
    <app-header-mobile></app-header-mobile>
</div>
<!-- fim primeiro banner mobile -->

<!-- parceiros desktop -->
<section class="partners">

    <app-landing-carriers></app-landing-carriers>

</section>
<!-- fim parceiros -->

<!-- parceiros mobile -->
<section class="partners-mobile">
    <app-carrier-mobile></app-carrier-mobile>
</section>
<!-- fim parceiros -->


<!-- sobre nós parte 1 desktop -->
<section class="about-us-section home-banner-middle ">
    <div class="wrapper">

        <div class="row about-us">
            <div class="col-md-6">
                <div class="about-us-text">
                    <h2>Quem somos</h2>
                    <div class="d-flex pb-5">
                        <img class="icon-text" src="../../../assets/img/about-us/magic copiar.png">
                        <h3>Fornecemos os melhores<br> serviços de envios<br> de encomendas</h3>
                    </div>
                    <span class="about-us-subtitle">
                        Somos uma empresa de <strong>hub logístico</strong> voltado para <strong>envio de
                            encomendas</strong>,
                        oferecendo <strong>agilidade</strong> e cotações com valores competitivos.
                    </span>
                </div>

            </div>
            <div class="col-md-6">
                <img src="../../../assets/img/about-us/Grupo 1739 (1).png">
            </div>
        </div>

        <div class="row mr-0 ml-0 about-us" >
            <div class="col-md-6">
                <img src="../../../assets/img/about-us/Grupo 1699.png">
            </div>
            <div class="col-md-6 ">
                <div class="our-history-text">
                    <h2>Nossa história</h2>
                    <span class="about-us-subtitle">
                        Inspirados pelo espirito <strong>empreendedor</strong> dos lojistas empresários brasileiros, nós
                        da Pic Envios nascemos
                        com o intuito de <strong>facilitar</strong> e <strong>agilizar</strong> as vendas do
                        comerciante.
                    </span>
                </div>

            </div>
        </div>
        <div class="row services" >
            <div class="col-md-6">
                <div class="services-text" style="padding-bottom: 33rem ;">
                    <h2>Etiqueta<br>Rápida</h2>
                    <span class="services-subtitle">
                        Possuímos um sistema de etiqueta online pelo qual você consegue gerar a etiquetação da sua
                        mercadoria diretamente pela internet, agilizando sua entrega na agência.
                    </span>
                    <br>

                    <button class="btn-orange" (click)="scrollToCalc()">Faça sua cotação</button>

                </div>
            </div>
            <div class="col-md-6">
                <img class="services-images img-pc"
                    src="../../../assets/img/services/pic-pc.png">
            </div>
        </div>
        <div class="row services">
            <div class="col-md-6  pl-0">
                <img class="pl-0 img-rastreio" src="../../../assets/img/services/Grupo 1682.png">
            </div>
            <div class="col-md-6">
                <div class="services-text tracking-text" style="padding-bottom: 20rem !important;">
                    <h2>Rastreamento</h2>
                    <span class="services-subtitle">
                        Acompanhe sua mercadoria a qualquer momento<br> de qualquer lugar.
                    </span>
                    <br>
                    <button class="btn-orange" (click)="scrollTracking()">IR PARA ÁREA DE RASTREAMENTO</button>
                </div>
            </div>
        </div>
        <div class="row services" >
            <div class="col-md-6">
                <div class="services-text">
                    <h2>Aplicativo</h2>
                    <span class="services-subtitle">
                        Pelo <strong><i>App Pic Envios</i></strong> você tem na palma da mão todos os serviços que oferecemos em nosso site, de qualquer lugar do Brasil.
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <img class="services-images" src="../../../assets/img/services/app.png">
            </div>
        </div>
        <div class="row services" >
            <div class="col-md-6">
                <img class="services-images" src="../../../assets/img/services/deliveryman.png">
            </div>
            <div class="col-md-6">
                <div class="services-text">
                    <h2>Posto Avançado</h2>
                    <span class="services-subtitle">
                        Possuímos um serviço onde criamos uma estrutura dentro do ambiente cliente, agilizando a emissão das etiquetas, efetuando coletas programadas, economizando tempo no processo dos envios dae suas encomendas.
                    </span>
                </div>
            </div>
        </div>

        <div class="row services mt-5">
            <div class="col-md-6">
                <div style="padding: 101px 0 28vh 0;">
                    <h2>Click Retire</h2>
                    <span class="services-subtitle">
                        Faça seu pedido através de um marketplace ou<br> e-commerce e economize na entrega utilizando <br>nosso  <i>Click Retire</i>
                    </span>
                </div>
            </div>
            <div class="col-md-6 pr-0" >
                <img class="img-click-retire" sty src="../../../assets/img/services/click-retire.png">
            </div>
        </div>
</div>
</section>
<!-- fim sobre nós parte 1 -->

<!-- sobre nós parte 1 mobile -->
<div class="our-history-mobile">
    <app-our-history-mobile></app-our-history-mobile>
</div>
<!-- fim sobre nós parte 1 mobile -->

<!-- razões desktop -->
<section class="page home-banner-bottom mt-0 pt-5 pic">
        <div class="pic">
            <h2 class="pic-title"> Por quê enviar <br> pela PIC envios?</h2>
        </div>
        <div class="wrapper">
        <div class="row mt-5">
            <div class="col-md-3">
                <div class="card" style="width: 28rem;  height: 98%;">
                    <img style="width: 37%; margin: 22px 80px;" src="../../../assets/img/pic-env/caixa copiar.png">
                    <div class="card-body">
                        <h5 class="card-title">Envie vários pacotes de uma só vez</h5>
                        <p class="card-text">Você pode enviar um só pacote, ou se preferir vários de uma só vez. A gente
                            encontra a transportadora certa para sua quantidade!</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 28rem;  height: 98%;">
                    <img src="../../../assets/img/pic-env/clock copiar.png">
                    <div class="card-body">
                        <h5 class="card-title">Sem demora para entregar suas encomendas</h5>
                        <p class="card-text">Sempre terá uma transportadora parceira que irá corresponder à sua
                            necessidade. Com a Pic envios é sem complicações!</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 28rem; height: 98%;">
                    <img src="../../../assets/img/pic-env/delivery copiar.png">
                    <div class="card-body">
                        <h5 class="card-title">Envio adaptado<br> ao seu tipo de encomenda</h5>
                        <p class="card-text">Temos a solução ideal para envios pessoais. Para os empreendedores,
                            ajudamos a enviar sua mercadoria direto para seus clientes.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 28rem; height: 98%;">
                    <img src="../../../assets/img/pic-env/price-tag copiar.png">
                    <div class="card-body">
                        <h5 class="card-title">Melhores preços do mercado</h5>
                        <p class="card-text">Você pode escolher a melhor logística para o seu bolso. Quer saber mais sobre nossos preços? Clique e faça uma cotação na  <a routerLink="area-do-cliente">"Área do cliente"</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- fim razões -->

<!-- razões mobile -->
<div class="reasons-mobile">
    <app-reasons-mobile></app-reasons-mobile>
</div>

<!-- fim razões mobile -->

<!-- sobre nós parte 2 desktop-->
<section class="about-us-2-section home-banner-middle ">
    <div class="wrapper">
        <div class="row about-us-2" >
            <div class="col-md-6 pr-0">
                <div class="about-us-2-text">
                    <div class="about-container">
                        <div class="d-flex ">
                            <img class="icon-text" src="../../../assets/img/shipped.png">
                            <h2>Alta<br><h3>Eficiência</h3></h2>
                        </div>

                    <span class="about-us-subtitle">
                        Nós da Pic envios temos um alto índice<br> de eficiência (comparado com o do mercado)<br> em relação a entrega das suas mercadorias.
                    </span>
                </div>
            </div>

            </div>
            <div class="col-md-6 pl-0">

                <img src="../../../assets/img/about-us/Grupo 1777.png">
            </div>
        </div>

        <div class="row about-us-2" >
            <div class="col-md-6 pr-0">
                <img class="w-100"  src="../../../assets/img/about-us/Grupo 1776.png">
            </div>
            <div class="col-md-6 pl-0">
                <div class="our-history-2-text">
                    <div class="about-container" style="padding-bottom: 35.1% !important;">
                        <div class="d-flex ">
                            <img class="icon-text" src="../../../assets/img/about-us/hand (1).png">
                            <h2>Melhores<br><h3>preços</h3></h2>
                        </div>

                    <span class="about-us-subtitle">
                        Nossas cotações possuem os melhores<br> preços do mercado.
                    </span>
                </div>
            </div>
            </div>
        </div>

        <div class="row about-us-2">
            <div class="col-md-6 pr-0">
                <div class="about-us-2-text pt-0 pb-5">
                    <div class="about-container">
                        <div class="d-flex ">
                            <img class="icon-text" src="../../../assets/img/about-us/support.png">
                            <h2>Integrações</h2>
                        </div>

                    <span class="about-us-subtitle">
                        Sempre vamos ter um operador logístico<br> que se encaixe melhor para suas necessidades,<br>contando com um grande número de<br> transportadoras cadastradas.
                    </span>
                </div>
            </div>

            </div>
            <div class="col-md-6 pl-0">
                <app-pic-carrier-slide></app-pic-carrier-slide>
            </div>
        </div>
    </div>
</section>
<!-- fim sobre nós parte 2 -->

<!-- sobre nós parte 2 mobile-->
<div class="about-us-mobile">
<app-about-us-mobile></app-about-us-mobile>
</div>
<!-- fim sobre nós parte 2 mobile-->

<!-- unidades pic desktop-->
<section class="unity">
    <div class="container ">
        <h1>Estamos localizados no coração dos centros comerciais</h1>
        <p>Temos 8 agências espalhadas pela região central da grande São Paulo.
    </div>
    <div class="carrier-slide">
        <app-unity-card></app-unity-card>
    </div>
</section>
<!-- fim unidades pic -->

<!-- segundo banner desktop -->
<section class=" home-banner-truck">
    <div class="wrapper">
        <div class="banner-content">
            <h1 class="home-title-orange" style="line-height:105%">Enviamos para todo Brasil</h1>
            <span class="home-subtitle">
                A Pic envios conta com transporte aéreo e transporte<br> terrestre para enviar suas encomendas
            </span>
        </div>
    </div>
</section>
<!-- fim segundo banner -->

<!-- segundo banner mobile -->
<div class="banner-truck-mobile">
   <app-banner-truck></app-banner-truck>
</div>

<!-- fim segundo banner mobile -->

<!-- cotação -->
<section #cotacao class="calc section bg-gray">
    <app-calculadora-h [_calcType]="1"></app-calculadora-h>
</section>
<!-- fim cotação -->

<!-- rastreio -->
<section #rastreio class="rastreio section bg-white">
    <div class="wrapper">
        <h2 class="section-title">
            <fa-icon [icon]="faMapMarkedAlt"></fa-icon>
            Rastreie sua entrega
        </h2>
        <div class="d-flex flex-column align-items-center">
            <ng-container *ngIf="isTrack">
                <form [formGroup]="trackForm" class="trackForm" (ngSubmit)="trackDeliver(trackForm)">
                    <p class="section-subtitle">Para rastreamento digite o código do objeto</p>
                    <input placeholder="Código do Objeto" class="w-80 my-5" formControlName="trackCode"
                        name="trackCode">
                    <button class="btn btn-blue btn-lg btn-withicon btn-withicon-rotate py-3" type="submit"
                        [disabled]="!trackForm.valid">
                        <div class="icon">
                            <fa-icon [icon]="faMapMarkerSmile"></fa-icon>
                        </div>
                        Buscar
                    </button>
                </form>
            </ng-container>
            <ng-container *ngIf="!isTrack" #trackResult>
                <span class="rastreio-title">Informações do Rastreio</span>
                <div class="rastreio-info">
                    <span class="rastreio-subtitle">Código do Objeto</span>
                    <span class="rastreio-code">{{ trackCode }}</span>
                </div>
                <div class="rastreio-not-found" *ngIf="trackNotFound">
                    <div class="icon">
                        <fa-icon [icon]="faSearchLocation"></fa-icon>
                    </div>
                    <div>
                        <span>Ops! </span>
                        <p>
                            {{ this.message || 'Objeto não encontrado em nossa base de dados.' }}
                            Aguarde o processamento do pedido</p>
                    </div>
                </div>
                <div *ngIf="!trackNotFound" class="rastreio-list mt-5">
                    <div class="rastreio-list-head">
                        <div class="rastreio-list-row">
                            <span class="rastreio-list-label">
                                Status
                            </span>
                        </div>
                    </div>
                    <div class="rastreio-list-body">
                        <div class="rastreio-list-row" *ngFor="let track of trackList">
                            <div class="rastreio-list-label">
                                <div class="icon">
                                    <fa-icon [icon]="faHandHoldingBox"></fa-icon>
                                </div>
                                <div class="right">
                                    <span class="rastreio-list-title">{{ track.status }}</span>
                                    <div class="row">
                                        <p class="rastreio-data">
                                            {{ track.date | date: 'dd/MM/yyyy' }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <button class="btn btn-blue btn-lg btn-withicon btn-withicon-toLeft py-3 mt-5 mr-5" type="button"
                        (click)="isTrack = !isTrack; trackForm.reset()">
                        <div class="icon">
                            <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
                        </div>
                        Novo rastreio
                    </button>
                    <button *ngIf="!trackNotFound"
                        class="btn btn-blue btn-lg btn-withicon btn-withicon-toLeft py-3 mt-5" type="button"
                        (click)="downloadTracking()">
                        <div class="icon">
                            <fa-icon [icon]="faFileDownload"></fa-icon>
                        </div>
                        Baixar Rastreio
                    </button>
                </div>

            </ng-container>
        </div>
    </div>
</section>
<!-- fim rastreio -->
<app-footer></app-footer>
