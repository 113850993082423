import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad } from '@angular/router';
import { UnitService } from './unit.service';
import { SharedService } from './shared.service';
import { UserService } from './user.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  userSigned = this.sharedService._userSigned

  constructor(
    public router: Router,
    private sharedService: SharedService,
    private userService: UserService,
    private storageService: StorageService
    ) { 
      sharedService.userSigned.subscribe(v => {
        v ? '' : this.router.navigate([''])
        this.userSigned = v
      })
      
    }

  canActivate(): boolean {
    if (!this.userSigned) {

      this.router.navigate([''])
      this.userService.logOut()
      return false;
    }
    return true
  }

}