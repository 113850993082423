<div class="home-banner">
  <div class="wrapper">
  <h1>Nossos Parceiros</h1>
  <section class="container-carrier">
    <div id="wrapper-3d">
      <div id="image">
        <div class="image i1">
          <div class="row">
            <div class="col-md-4"><img src="../../../assets/img/carriers/correios.png"></div>
            <div class="col-md-4"><img src="../../../assets/img/carriers/onlog.png"></div>
            <div class="col-md-4"><img src="../../../assets/img/carriers/jadlog.png"></div>
          </div>
        </div>
        <div class="image i2">
          <div class="row">
            <div class="col-md-4"><img src="../../../assets/img/carriers/gol.png"></div>
            <div class="col-md-4"><img src="../../../assets/img/carriers/azul.png"></div>
            <div class="col-md-4"><img src="../../../assets/img/carriers/tnt.png"></div>
          </div>
        </div>
        <div class="image i3">
          <div class="row">
            <div class="col-md-6"><img src="../../../assets/img/carriers/loggi.png"></div>
          <div class="col-md-6"><img style="margin-top:105px" src="../../../assets/img/carriers/sequoia.png"></div>
        </div>
        </div>
        <div class="image i4">
          <div class="row">
          <div class="col-md-6"><img src="../../../assets/img/carriers/rodojr.png"></div>
          <div class="col-md-6"><img src="../../../assets/img/carriers/amazonas.png"></div>
        </div>
      </div>

      <div class="image i5">
        <div class="row">
          <div class="col-md-4"><img src="../../../assets/img/carriers/correios.png"></div>
          <div class="col-md-4"><img src="../../../assets/img/carriers/onlog.png"></div>
          <div class="col-md-4"><img src="../../../assets/img/carriers/jadlog.png"></div>
        </div>
      </div>
			<div class="image i6">
        <div class="row">
          <div class="col-md-4"><img src="../../../assets/img/carriers/gol.png"></div>
          <div class="col-md-4"><img src="../../../assets/img/carriers/azul.png"></div>
          <div class="col-md-4"><img src="../../../assets/img/carriers/tnt.png"></div>
        </div>
      </div>
			<div class="image i7">
        <div class="row">
          <div class="col-md-6"><img src="../../../assets/img/carriers/loggi.png"></div>
        <div class="col-md-6"><img style="margin-top:105px" src="../../../assets/img/carriers/sequoia.png"></div>
      </div>
      </div>
			<div class="image i8">
        <div class="row">
          <div class="col-md-6"><img src="../../../assets/img/carriers/rodojr.png"></div>
          <div class="col-md-6"><img src="../../../assets/img/carriers/amazonas.png"></div>
        </div>
      </div>
      </div>
    </div>
  </section>
</div>
</div>

