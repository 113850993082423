import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { faCalculatorAlt, faBoxCheck, faUsers, faStore, faMapMarker} from '@fortawesome/pro-light-svg-icons';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  page_category: string = 'Welcome'
  konduto: any

  userInfo
    // Icons
    faCalculatorAlt = faCalculatorAlt
    faBoxCheck = faBoxCheck
    faUsers = faUsers
    faStore = faStore
    faMapMarker = faMapMarker
  constructor(
    private sharedService: SharedService,
    private userService: UserService
  ) {

    this.userInfo = this.userService.getUserData()


}

  ngOnInit(): void {

  }

  logOut(){

    this.sharedService.changeUserSigned(false)
    this.userService.logOut()
  }

    kontudoDisparo = () => {
    var period = 300;
    var limit = 20 * 1e3;
    var nTry = 0;
    var intervalID = setInterval(function() {
      var clear = limit/period <= ++nTry;
      if ((typeof(this.Konduto) !== "undefined") &&
          (typeof(this.Konduto.sendEvent) !== "undefined")) {
      this.Konduto.sendEvent('page', this.page_category); // Disparo programático de evento
      clear = true;
      }
    if (clear) {
  clearInterval(intervalID);
    }
  },
  period);
  };

}

