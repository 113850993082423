import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { faCalculatorAlt, faBoxCheck, faUsers, faSignOutAlt, faUserCircle, faChevronDown, faUserTie, faPiggyBank, faPallet, faShippingFast, faUserCrown, faMapMarker } from '@fortawesome/pro-light-svg-icons';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faCalculatorAlt = faCalculatorAlt
  faBoxCheck = faBoxCheck
  faUsers = faUsers
  faSignOutAlt = faSignOutAlt
  faUserCircle = faUserCircle
  faChevronDown = faChevronDown
  faUserTie = faUserTie
  faPiggyBank = faPiggyBank
  faPallet = faPallet
  faShippingFast = faShippingFast
  faUserCrown = faUserCrown
  faMapMarker = faMapMarker
  isCadCot = false

  @ViewChild('header', { static: false }) header: ElementRef

  @HostListener("window:scroll", [])
  onWindowScroll() {

    const scroll = window.scrollY
    if(this.router.url != '/login'){

      if(scroll > 0 && !this.headVisible ){
        this.renderer.addClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(true)
      } else if(!this.isPanel && scroll <= 0) {
        this.renderer.removeClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(false)
      }
    }
    if(this.router.url != '/cadastre-se'){

      if(scroll > 0 && !this.headVisible ){
        this.renderer.addClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(true)
      } else if(!this.isPanel && scroll <= 0) {
        this.renderer.removeClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(false)
      }
    }
  }

  isPanel = this.sharedService._isPanel
  html = document.getElementById('scroll')
  headVisible: boolean = false;
  submenuVisible: boolean= false;
  userSigned = false
  currentRoute
  userInfo


  constructor(
    private renderer: Renderer2,
    private sharedService: SharedService,
    private router: Router,
    private userService: UserService,
  ) {
    sharedService.userSigned.subscribe(v => {
      this.userSigned = v
      this.userInfo = v ? this.userService.getUserData() : undefined
    })

    sharedService.userSigned.subscribe(v => {
      this.userSigned = v

      this.userInfo = v ? this.userService.getUserData() : undefined
    })
    sharedService.isPanel.subscribe(v => {
      this.isPanel = v
    })
    sharedService.headVisible.subscribe(v => {
      this.headVisible = v
    })
    router.events.subscribe(r  => {
      this.submenuVisible = false
    })
  }

  ngOnInit(): void {
    // this.login()
    this.userInfo = this.userService.getUserData()
    this.userInfo ? null : this.logOut()

  }

  logOut() {

    this.sharedService.changeUserSigned(false)
    this.userService.logOut()
    localStorage.removeItem('dados')
    localStorage.removeItem('dc')
  }


  mouseEnter(event){
    this.submenuVisible = true
  }
  mouseOver(event){
    this.submenuVisible = false
  }

  ngOnDestroy(): void {
    this.userService.logOut()
  }

  isLogin() {
    return this.router.url == '/login'
  }
  isCad() {
    return this.router.url == '/cadastre-se'
  }
}
