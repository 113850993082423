<div class="home-banner">
    <div class="wrapper">
        <h1>Nossos parceiros</h1>
        <div class="carriers-slide">

            <div class="slider" >
                <div class="slide-track" >
                    <div class="slide" *ngFor="let carrier of carriers">
                        <img  style="padding: 0 40px;  width: 60vw;" [src]="'./assets/img/carriers/' + carrier.name + '.png'" [alt]="carrier.name">
                    </div>
        </div>
    </div>
</div>