<div class="painel-page bg-gray" style="padding-bottom: 85px; padding-top: 130px">
    <div class="welcome-container">
        <div>
            <p class="welcome-p">Olá, <span class="name-span">{{userInfo?.name}}</span></p>

        </div>
        <!-- <div class="row" style="margin-right: -140px;">
        <div class="container">
            <div class="grid-1 callout primary">
                <div class="icon ">
                    <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                    <div class="card-panel" *ngIf="userInfo?.typeUserId != 1  && userInfo?.outpost != 1 && userInfo?.typeUserId != 6"  [routerLink]="['/painel/atendimento']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary">Atendimento</span>
                    </div>
                    <div class="card-panel" *ngIf="userInfo?.typeUserId != 1  && userInfo?.outpost == 1 && userInfo?.typeUserId != 6"  [routerLink]="['/painel/cotacao-pa']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary" style="font-size: 2.5rem;">Cotação e Etiqueta</span>
                    </div>
                    <div class="card-panel" *ngIf="userInfo?.typeUserId == 1 ||  userInfo?.typeUserId == 6"  [routerLink]="['/painel/cotacao']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary">Cotação</span>
                    </div>

                </div>
            </div>
            <div class="grid-2 callout warning" [routerLink]="['/painel/envios']" *ngIf="userInfo?.typeUserId != 6">
                <div class="icon ">
                    <fa-icon [icon]="faBoxCheck"></fa-icon>
                    <div class="card-panel">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary">Meus Envios</span>
                    </div>
                </div>
            </div>

            <div class="grid-2 callout warning" [routerLink]="['/painel/rastreio']" *ngIf="userInfo?.typeUserId == 6">
                <div class="icon ">
                    <fa-icon [icon]="faMapMarker"></fa-icon>
                    <div class="card-panel">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary">Rastreio</span>
                    </div>
                </div>
            </div>

            <div class="grid-3 callout alert" *ngIf="userInfo?.typeUserId != 6">
                <div class="icon ">
                    <fa-icon [icon]="faUsers"></fa-icon>
                    <div class="card-panel"  [routerLink]="['/painel/destinatarios']" style="bottom: 80px;" *ngIf="userInfo?.outpost == 1" >
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary" >Meus Destinatários</span>
                    </div>
                    <div class="card-panel"  *ngIf="userInfo?.outpost != 1"  [routerLink]="['/painel/clientes']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary">Meus Clientes</span>
                    </div>
                </div>
            </div>
            <div class="grid-4 callout success" *ngIf="userInfo?.typeUserId != 6">
                <div class="icon" >
                    <fa-icon [icon]="faStore"></fa-icon>
                    <div class="card-panel" *ngIf="userInfo?.typeUserId != 1 && userInfo?.typeUserId != 3 && userInfo?.typeUserId != 5" [routerLink]="['/painel/minha-unidade']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary">Minha Unidade</span>
                    </div>
                    <div class="card-panel" *ngIf="userInfo?.typeUserId == 1 && userInfo?.typeUserId != 3 && userInfo?.typeUserId != 5" [routerLink]="['/painel/minhas-unidades']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary" style="font-size: 2.8rem;">Minhas Unidades</span>
                    </div>
                    <div class="card-panel" *ngIf="userInfo?.typeUserId == 3" [routerLink]="['/painel/cotacao']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary" style="font-size: 2.8rem;">Cotação</span>
                    </div>
                    <div class="card-panel" *ngIf="userInfo?.typeUserId == 5" [routerLink]="['/painel/postagem']">
                        <p class="text-secondary">Ir para</p>
                        <span class="text-secondary" style="font-size: 2.8rem;">Postagem</span>
                    </div>
                </div>
            </div>
          </div>

        </div> -->
        <div class="grid-welcome">

          <a routerLink="/painel/cotacao" *ngIf="userInfo?.outpost != 1">
            <img  src="../../../assets/img/menus/cotacao.png" onmousemove="this.src='../../../assets/img/menus/cotacaoHover.png'" onmouseout="this.src='../../../assets/img/menus/cotacao.png'" alt="icone de cotação">
         </a>

         <a routerLink="/painel/cotacao-pa" *ngIf="userInfo?.outpost == 1 || userInfo?.typeUserId == 7">
          <img   src="../../../assets/img/menus/etiqueta.png" onmousemove="this.src='../../../assets/img/menus/etiquetaHover.png'" onmouseout="this.src='../../../assets/img/menus/etiqueta.png'" alt="icone de cotação">
        </a>

          <a routerLink="/painel/atendimento" *ngIf="userInfo?.typeUserId != 1 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6 && userInfo?.outpost != 1">
            <img src="../../../assets/img/menus/atendimento.png" onmousemove="this.src='../../../assets/img/menus/atendimentoHover.png'" onmouseout="this.src='../../../assets/img/menus/atendimento.png'" alt="icone de atendimento">
          </a>

          <a   routerLink="/painel/envios"  *ngIf="userInfo?.typeUserId != 6">
            <img src="../../../assets/img/menus/meusEnvios.png" onmousemove="this.src='../../../assets/img/menus/enviosHover.png'" onmouseout="this.src='../../../assets/img/menus/meusEnvios.png'"   alt="icone de meus envios">
          </a>

          <a routerLink="/painel/clientes" *ngIf="userInfo?.outpost != 1 && userInfo?.typeUserId !=  5 && userInfo?.typeUserId != 6">
            <img src="../../../assets/img/menus/meusClientes.png"  onmousemove="this.src='../../../assets/img/menus/clientesHover.png'" onmouseout="this.src='../../../assets/img/menus/meusClientes.png'"  alt="icone de meus clientes">
          </a>

          <a routerLink="/painel/destinatarios" *ngIf="userInfo?.outpost === 1 && userInfo?.typeUserId != 6">
            <img src="../../../assets/img/menus/destinatarios.png" onmousemove="this.src='../../../assets/img/menus/destinatariosHover.png'" onmouseout="this.src='../../../assets/img/menus/destinatarios.png'"   alt="icone de meus destinatarios">
          </a>

          <a routerLink="/painel/expedition" *ngIf="userInfo?.typeUserId == 0 || userInfo?.typeUserId == 2 && userInfo?.typeUserId != 6">
            <img src="../../../assets/img/menus/expedicao.png" onmousemove="this.src='../../../assets/img/menus/expedicaoHover.png'" onmouseout="this.src='../../../assets/img/menus/expedicao.png'" alt="icone de meus Expedição">
         </a>

         <a routerLink="/painel/minha-expedicao" *ngIf="userInfo?.typeUserId == 5 || userInfo?.typeUserId == 1 && userInfo?.typeUserId != 6">
           <img src="../../../assets/img/menus/expedicao.png" onmousemove="this.src='../../../assets/img/menus/expedicaoHover.png'" onmouseout="this.src='../../../assets/img/menus/expedicao.png'" alt="icone de meus Expedição">
         </a>



          <a routerLink="/painel/listagem-pa"  *ngIf="userInfo?.typeUserId == 1 && userInfo?.typeUserId != 6"  >
            <img src="../../../assets/img/menus/pa.png" onmousemove="this.src='../../../assets/img/menus/paHover.png'" onmouseout="this.src='../../../assets/img/menus/pa.png'" alt="icone de meus Expedição">
          </a>



          <a routerLink="/painel/postagem" *ngIf="userInfo?.typeUserId != 1 && userInfo?.typeUserId != 3  && userInfo?.outpost != 1 && userInfo?.typeUserId != 6">
            <img src="../../../assets/img/menus/postagem.png" onmousemove="this.src='../../../assets/img/menus/postagemHover.png'" onmouseout="this.src='../../../assets/img/menus/postagem.png'"  alt="icone de minhas postagens">
          </a>

          <a routerLink="/painel/rastreio" >
            <img src="../../../assets/img/menus/rasteio.png"  onmousemove="this.src='../../../assets/img/menus/rastreioHover.png'" onmouseout="this.src='../../../assets/img/menus/rasteio.png'" alt="icone de minhas rasteio">
          </a>

          <a routerLink="/painel/minhas-unidades" *ngIf="userInfo?.typeUserId == 1 && userInfo?.outpost != 1 && userInfo?.typeUserId != 3 && userInfo?.typeUserId != 6">
            <img src="../../../assets/img/menus/minhasUnidades.png" onmousemove="this.src='../../../assets/img/menus/minhasUnidadesHover.png'" onmouseout="this.src='../../../assets/img/menus/minhasUnidades.png'" alt="icone de minha unidades">
          </a>

          <a routerLink="/painel/minha-unidade" *ngIf="userInfo?.typeUserId != 1 &&  userInfo?.typeUserId != 3 && userInfo?.outpost != 1 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6">
            <img src="../../../assets/img/menus/MinhaUnidade.png" onmousemove="this.src='../../../assets/img/menus/MinhaUnidadeHover.png'" onmouseout="this.src='../../../assets/img/menus/MinhaUnidade.png'"   alt="icone de minha unidade">
          </a>

          <a (click)="logOut()" >
            <img src="../../../assets/img/menus/sair.png"  onmousemove="this.src='../../../assets/img/menus/sairHover.png'" onmouseout="this.src='../../../assets/img/menus/sair.png'" alt="icone de minhas rasteio">
          </a>

        </div>
    </div>
    <script type="text/javascript">
      Konduto.sendEvent('page','welcome');
    </script>
</div>


