import { UserService } from './../../services/user.service';
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { faSortAlt, faCreditCardFront, faCoins, faMoneyBillWave, IconDefinition, faSearch, faLongArrowAltLeft, faInfoCircle, faPlus, faTrashAlt, faLongArrowAltRight, faWindowClose } from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Calculo, Volume } from 'src/app/models/volume.model';
import { SharedService } from 'src/app/services/shared.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import anime from 'animejs/lib/anime.es.js';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ClientService } from 'src/app/services/client.service';
import { StorageService } from 'src/app/services/storage.service';
import { RequiredValidator } from '@angular/forms';
import Swal from 'sweetalert2'
import { Observable, Observer, of } from 'rxjs';

@Component({
  selector: 'app-calculadora',
  templateUrl: './calculadora.component.html',
  styleUrls: ['./calculadora.component.scss']
})
export class CalculadoraComponent implements OnInit {
  total: any;
  transpSelected = false
  remData
  calc: any[];
  vol: any[];
  data: any;
  decData: string;
  isDc: string;
  receiveData: any;

  @Input() set _userInfo(v: any) {

    this.userInfo = v


  }

  @Input() set _orderData(v: any) {
    this.calculo = v
    this.calculo ? this.populateCalc(this.calculo) : null
  }
  calculo
  @ViewChild('volumeRow', { static: false }) volumeRow: ElementRef

  @ViewChild('payment', { static: false }) payment: ElementRef

  @ViewChild('option0', { static: false }) option0: ViewContainerRef
  @ViewChild('option1', { static: false }) option1: ViewContainerRef
  @ViewChild('option2', { static: false }) option2: ViewContainerRef

  //Icons
  faSortAlt = faSortAlt
  faLongArrowAltLeft = faLongArrowAltLeft
  faInfoCircle = faInfoCircle
  faPlus = faPlus
  faTrashAlt = faTrashAlt
  faLongArrowAltRight = faLongArrowAltRight
  faClose = faWindowClose
  faSearch = faSearch

  change = 0
  paymentDeadline
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  activeFreights
  paymentSuccess = false
  activeOption
  isClicked;
  userInformation
  obs: any;
  allFreightsLength

  // Exhibition Variables
  isForm: boolean = true
  allCalcs
  isDrops: string = ""
  isEmail: string
  isPhone: string
  isLoading = false
  reverseLogistic: boolean = false
  freightList: any[] = []
  formUpdated = false
  activeFreight
  destinatario
  receiveDatas: any
  clientForm: FormGroup
  volume
  userInfo
  freight
  calculos = [{ id: 1 }]
  activeCalc = 1
  client
  order
  calculosFromEach = []
  groupCalc = [];
  modalRef: BsModalRef;
  paymentOption: Option[] = []
  search
  search$: Observable<any>
  fee
  carrierValue
  declared

  constructor(
    private actRoute: ActivatedRoute,
    private homeService: HomeService,
    private router: Router,
    private orderService: OrderService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private storageService: StorageService,
    private clientService: ClientService,
    private modalService: BsModalService,
    private userService: UserService,
    private FormBuilder: FormBuilder,
  ) {
    actRoute.data.subscribe(data => {
      this.userInformation = data.userInfo;
    })
    this.sharedService.currentOrder.subscribe(v => {
      this.data = JSON.parse(v);
      this.order = this.data.volume
      this.freight = this.data.freight
      this.allCalcs = this.data.allCalcs

      this.order.forEach(v => {
        for (let i = 0; i < v.quantity; i++) {
          // this.total = v.value ||0
          this.declared = v.declaredValue
        }
      });
    })
    this.userInfo = this.userService.getUserData()
  }

  @ViewChild('calcSection', { static: false }) calcTable: ElementRef;

  openModal(template) {
    if (this.isForm == false && this.remData != null) {
      this.modalRef = this.modalService.show(template, this.config);
    }
  }


  openModalDone(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.isForm = false
  }

  closeModal() {
    this.modalService.hide();
    this.transpSelected = false
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    autoWidth: true,
    autoHeight: true,
    items: 1,
  }

  ngOnInit(): void {
    this.setPaymentOptions();

    this.receiveDatas = localStorage.getItem('dados');
    this.receiveData = JSON.parse(this.receiveDatas)


    window.localStorage.clear();
    this.validateUserAccess()
  }


  mountCalc() {




    this.isDc = localStorage.getItem('dc')
    this.remData = localStorage.getItem('data-rem')
    this.remData = JSON.parse(this.remData)

    this.decData = localStorage.getItem('contentData')
    this.decData = JSON.parse(this.decData)
    this.calculosFromEach = []
    this.sharedService.changeMountCalc(true)

    if (this.remData === null || this.remData === undefined || this.remData === '') {
      this.toastr.warning('Verifique os dados do remetente!');
      !this.clientForm.valid
    }
  }



  async getFreightC() {

    this.getDeadlineByClient(this.remData.id)
    this.freightList = []
    this.isLoading = true
    this.isForm = false

    let cepOrigem
    let volumes = []

    this.calculosFromEach.forEach(c => {

      cepOrigem = c.cepOrigem || this.userInfo?.zipCode
      c.volume.forEach(v => {
        volumes.push(v)
      });

      c.calculo = new Calculo(
        cepOrigem,
        c.volume

      )
      c.volume.valorDeclarado = c.volume[0].valorDeclarado * c.volume[0].quantity

      this.homeService.calculateFreteExterna(c.calculo)
        .then(res => {
          if (!res.data.volumes.length) {

            this.isLoading = false
            this.toastr.error('Nao encontramos resultados para sua pesquisa!', 'Erro!');
            this.isForm = true
          }

          if (res.data.groupedVolumes) {
            res.data.groupedVolumes.forEach(gV => {
              gV = this.formatFreight(gV)
            });
            c.freight = res.data.groupedVolumes
            c.allFreights = res.data.volumes
          }
          // }


          if (res.data.volumes.length) {
            let freightList = res.data.groupedVolumes

            for (let freight of freightList) {
              this.formatFreight(freight)
            }
            this.formatTableData(freightList)

          }
        })

      c.volumeInfo = []

    });
    return false


  }
  setPaymentOptions() {



    this.paymentOption.push({
      id: 3,
      name: 'PagéPay',
      info: '(Crédito/Débito)',
      icon: faCreditCardFront,
      active: false,
      template: this.option0
    },
      {
        id: 1,
        name: 'Faturado',
        info: '',
        icon: faCoins,
        active: false,
        template: this.option1
      },
      {
        id: 2,
        name: 'Dinheiro',
        info: '',
        icon: faMoneyBillWave,
        active: false,
        template: this.option2
      })
  }

  async getDeadlineByClient(id) {


    const paymentDeadline = await this.clientService.getDeadlineByClient(id)
    this.paymentDeadline = paymentDeadline

  }

  formatTableData(freightList) {
    let anterior = 0
    let index = 0


    this.freightList = freightList
    this.fadeTable()
  }

  formatFreight(freight) {
    freight.active = false
  }

  setActiveFreight(freight, i) {
    localStorage.setItem('declaredVal', freight.declaredValue)
    localStorage.setItem('fee', freight.fee)
    localStorage.setItem('carrierVal', freight.carrierValue)
    this.total = freight.value

    this.calculosFromEach[i].freight = this.calculosFromEach[i].freight.map(f => {
      f.active = false
      return f
    })
    freight.active = !freight.active
  }

  async setCurrentOrder() {
    const calculosFromEach = Array.from(this.calculosFromEach, x => Object.assign({}, x))

    let volumesAlt: Volume[] = []
    calculosFromEach.forEach(v => {
      v.volume.forEach(volume => {
        volumesAlt.push(volume)
      });
    })


    let freights = this.calculosFromEach.map(f => {
      return f = f.freight.find(v => v.active)
    })

    const volumes: any[] = volumesAlt.map(v => {
      let f = freights.find(freight => freights.indexOf(freight) == volumesAlt.indexOf(v))

      return v = Object.assign({}, { ...v, ...f })
    })



    if (freights.length == volumes.length) {
      calculosFromEach.forEach(v => {
        v.volumeInfo = v.volume.map(volume => {

          let f = freights.find(freight => freights.indexOf(freight) == volumesAlt.indexOf(volume))
          return Object.assign({}, { ...volume, ...f })
        })
        delete v.result
        delete v.volumeLength
        delete v.volume
      })

      const freight = {
        cepOrigem: calculosFromEach[0].cepOrigem
      }

      // return false
      let orderAdded = await this.orderService.setCurrentOrder(volumes, freight, calculosFromEach)
      if (orderAdded) {
        this.router.navigate(['painel/pagamento'])
      } else {
        this.toastr.warning('Houve um problema com sua cotação, tente novamente.', 'Erro!')
      }
    } else {
      this.toastr.warning('Verifique seus volumes e opções de fretes selecionadas.')
    }

  }

  setActiveFreightG(freight) {
    this.groupCalc.forEach(f => {
      if (f.volume == freight.volume) {
        f.active = false
      }
    })
    freight.active = !freight.active
  }

  async setGroupedOrder() {





    const calculosFromEach = Array.from(this.calculosFromEach, x => Object.assign({}, x))

    let freights = []
    let volumes = []

    let volumesAlt: Volume[] = []


    calculosFromEach.forEach(c => {

      c.volume.forEach(volume => {
        volumesAlt.push(volume)
      });

      let activeF = c.freight.find(f => f.active)

      freights.push(activeF)
      localStorage.setItem("valor total", JSON.stringify(activeF))

      let freightsArr: any[] = c.allFreights.filter(f => f.carrierId == activeF.carrierId)
      freightsArr = freightsArr.filter((value, index) => freightsArr.indexOf(value) === index)
      c.volumeInfo = c.volume.map(v => {

        return Object.assign({}, {
          ...v, ...freightsArr.find(f => {

            return f.volume == c.volume.indexOf(v) + 1

          })
        })
      })
      volumes.push(...c.volumeInfo)

    })

    this.calc = calculosFromEach

    this.vol = volumes

    const freight = {
      cepOrigem: calculosFromEach[0].cepOrigem
    }

    let orderAdded = await this.orderService.setCurrentOrder(volumes, freight, calculosFromEach)

    if (orderAdded) {
      this.transpSelected = true
    } else {
      this.toastr.warning('Houve um problema com sua cotação, tente novamente.', 'Erro!')
    }
  }



  addVolume() {
    this.sharedService.changeVolume({ id: this.activeCalc })
  }

  calcularVarios() {
    const nextId = this.calculos.length + 1
    this.calculos.push({ id: nextId })
    this.activeCalc = nextId
  }


  calcMounted($event) {



    this.calculosFromEach.push($event)

    if (this.calculosFromEach.findIndex(v => v.valid == false) == -1) {
      this.getFreightC()
    } else {
      for (let i = 0; i < this.calculosFromEach.length; i++) {

        if (this.calculosFromEach[i].volume[i].altura == 0) {

          this.toastr.warning('Verifique a altura do produto!')
        }

        if (this.calculosFromEach[i].volume[i].cepDestino == '') {
          this.toastr.warning('Verifique o CEP do destinatário!');
        }

        if (this.calculosFromEach[i].volume[i].comprimento == 0) {
          this.toastr.warning('Verifique o comprimento do produto!')
        }

        if (this.calculosFromEach[i].volume[i].largura == 0) {
          this.toastr.warning('Verifique a largura do produto!')
        }

        if (this.calculosFromEach[i].volume[i].peso == 0) {
          this.toastr.warning('Verifique o peso do produto!')
        }

        if (this.calculosFromEach[i].volume[i].valorDeclarado == 0) {
          this.toastr.warning('Verifique o valor do produto!')
        }

        if (this.calculosFromEach[i].destinatario.address.length < 1) {

          this.toastr.warning('Verifique o endereço do destinatário!')
        }

        if (this.calculosFromEach[i].destinatario.city.length < 1) {

          this.toastr.warning('Verifique a cidade do destinatário!')
        }

        if (this.calculosFromEach[i].destinatario.documentNumber.length < 1 || this.calculosFromEach[i].destinatario.documentNumber == '') {
          this.toastr.warning('Verifique o documento do destinatário!')
        }

        if (this.calculosFromEach[i].destinatario.neighborhood.length < 1) {
          this.toastr.warning('Verifique o bairro do destinatário!')
        }

        if (this.calculosFromEach[i].destinatario.nota == null && this.calculosFromEach[i].destinatario.documentNumber.length == 14) {
          this.toastr.warning('Verifique a nº da nota/tipo da nota do produto!')
        }

        if (this.calculosFromEach[i].destinatario.number.length < 1 || this.calculosFromEach[i].destinatario.number == null) {
          this.toastr.warning('Verifique o número do destinatário')
        }


        if (this.calculosFromEach[i].destinatario.recipient.length < 1) {
          this.toastr.warning('Verifique o nome do destinatário!')
        }

        if (this.calculosFromEach[i].destinatario.serieNFE == null && this.calculosFromEach[i].volume[i].nfe == true && this.calculosFromEach[i].destinatario.documentNumber.length == 14) {
          this.toastr.warning('Verifique a série NFE do destinatário!')
        }

        if (this.calculosFromEach[i].destinatario.keyNFE == null && this.calculosFromEach[i].volume[i].nfe == true && this.calculosFromEach[i].destinatario.documentNumber.length == 14) {
          this.toastr.warning('Verifique a chave da NFE!')
        }

        if (this.calculosFromEach[i].destinatario.stateRegistration === null && this.calculosFromEach[i].destinatario.documentNumber.length == 14) {

          this.toastr.warning('Campo Inscrição estadual é obrigatório')
        }

        if (this.calculosFromEach[i].destinatario.uf.length < 1) {
          this.toastr.warning('Verifique o estado do destinatário')
        }
      }

    }

    if (this.remData.paymentCondition === 'À vista') {

      var paymentList = this.paymentOption;

      for (var i = 0; i < paymentList.length; i++) {
        if (paymentList[i].id === 1) {
          paymentList.splice(i, 1);
        }
      }

    } else if (this.remData.paymentCondition != 'À vista') {
      var paymentList = this.paymentOption;
      for (var i = 0; i < paymentList.length; i++) {
        if (paymentList[i].id === 1) {
          paymentList.splice(i, 1);

        }
        if (paymentList[i].id === 2) {
          paymentList.splice(i, 2);

        }
        if (paymentList[i].id === 3) {
          paymentList.splice(i, 3);

        }
      }
      this.setPaymentOptions();
    }
  }

  async buildForm(template?) {

    this.isClicked = true


    this.isEmail =  null
    this.isPhone = null


    const calculos = this.allCalcs
    const order = this.order
    const paymentType = this.paymentOption.find(v => v.id == this.activeOption)
    let paymentCondition
    if (paymentType.id == 1) {
      let paymentOptionsArr: any[] = []
      const paymentOptions = document.querySelectorAll('input[name="billed"]')
      paymentOptions.forEach(v => {
        paymentOptionsArr.push(v)
      })

      if (paymentType.id == 1) {

        paymentCondition = this.paymentDeadline[0].id;

      } else {
        this.toastr.warning('Selecione uma opção de pagamento.')
        return false
      }
    } else {
      paymentCondition = null
    }
    let orderFreightArr: any[] = []
    calculos.forEach(c => {
      const destinatario = c.destinatario

      if(destinatario.neighborhood.includes('Conjunto Habitacional')) {
        destinatario.neighborhood = destinatario.neighborhood.replace('Conjunto Habitacional', 'C Hab.')
      }

      this.obs = destinatario.observation


      c.volumeInfo.forEach(v => {
        for (let i = 0; i < v.quantity; i++) {
          orderFreightArr.push({
            "carrierId": v.carrierId,
            "weight": JSON.parse(v.peso),
            "height": v.altura,
            "width": v.comprimento,
            "length": v.largura,
            "destName": destinatario.recipient,
            "destPhone": destinatario.phone,
            "destEmail": destinatario.email,
            "invoiceNumber": destinatario.nota,
            "destCity": destinatario.city,
            "destUf": destinatario.uf,
            "destAddress": destinatario.address,
            "destZipCode": v.cepDestino,
            "destNeighborhood": destinatario.neighborhood,
            "destNumber": destinatario.number,
            "destComplement": destinatario.complement == null ? destinatario.complement = "" : String(destinatario.complement),
            "destDocument": destinatario.documentNumber,
            "additionalServiceCode": v.additionalServiceCode || '',
            "keyNFE": destinatario.keyNFE,
            "serieNFE": destinatario.serieNFE,
            "stateRegistration": destinatario.stateRegistration,
            "value": v.value,
            "declaredValue": v.declaredValue,
            "maoPropria": v.maoPropria,
            "avisoRecebimento": v.avisoRecebimento,
            "deliveryTime": v.deliveryTime,

          })
          this.isDrops =  v.carrierId
          this.isEmail =  destinatario.email
          this.isPhone = destinatario.phone
        }
      })
    });

    if(this.isDrops == "29"  && (this.isEmail == null  || this.isPhone == null)){
      this.paymentSuccess= false
      this.transpSelected = false
      this.isClicked = undefined ;
      this.isLoading= false
      Swal.fire({
        html: `<svg version="1.1" style="width:100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/></svg><p class="error">Não foi possível gerar as etiquetas!</p><p class="error">${this.isEmail == null ? "Por Favor informe o e-mail do remetente" : ""} </p> <p class="error">${this.isPhone == null ? "Por Favor informe o telefone do remetente" : ""} </p>`,
      })


      this.closeModal();
    } else{
      const params = {
        "userId": this.userInformation.id,
        "clientId": this.remData.id,
        "code": "",
        "carrierValue": Number(localStorage.getItem('carrierVal')),
        "declaredValue": Number(localStorage.getItem('declaredVal')),
        "fee": Number(localStorage.getItem('fee')),
        "payTypeId": paymentType.id,
        "unitId": this.userInformation.unitId,
        "orderTypeId": 1,
        "cepOrigem": this.userInformation.zipCode,
        "paymentConditionId": Number(paymentCondition) || 3,
        "value": this.total,
        "observation": this.obs,
        "addOrderFreight": orderFreightArr
      }
      let response = await this.orderService.createOrder(params)
      if (response?.sucess) {
        this.paymentSuccess = true
        this.isClicked = false;
        this.isLoading = false
        this.transpSelected = false
        this.change = 0
        Swal.fire({
          confirmButtonText: 'OK',
          html: '<div ><svg style="width: 100px;" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"> <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/></svg><p class="success" style="font-size: 2.5rem;">Etiquetas geradas!</p></div>'
        }).then((isConfirm) => {
          Swal.fire({
            icon: 'question',
            text: 'Deseja criar outro envio?',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value == true) {
              Swal.fire({
                icon: 'question',
                text: 'Manter os dados do pedido anterior?',
                title: '(Poderão ser alterados!)',
                confirmButtonText: 'Sim',
                showCancelButton: true,
                cancelButtonText: 'Não',
              }).then((result) => {
                if (result.value == 'true') {
                  this.resetCalc();
                  this.isLoading = false
                  this.change = 0
                  this.isClicked = false;
                } else if (result.dismiss) {
                  location.reload()
                  localStorage.removeItem('data-rem')
                }
              })
            } else {
              location.reload()
            }
          })

        })
        this.closeModal();
      }
      else {
        if (response != undefined) {
          Swal.fire({
            html: `<svg version="1.1" style="width:100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/></svg><p class="error">Não foi possível gerar as etiquetas!</p><p> ${response}</p>`,
          })
          this.isClicked = false;
          this.closeModal()
        } else {
          Swal.fire({
            html: `<svg version="1.1" style="width:100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/></svg><p class="error">Não foi possível gerar as etiquetas!</p>`,
          })
          this.isClicked = false;
          this.closeModal()
        }
      }

    }


  }
  async buildFormPDF(template?) {


    this.isClicked = true


    this.isEmail =  null
    this.isPhone = null
    const calculos = this.allCalcs
    const order = this.order

    const paymentType = this.paymentOption.find(v => v.id == this.activeOption)

    let paymentCondition
    if (paymentType.id == 1) {
      let paymentOptionsArr: any[] = []
      const paymentOptions = document.querySelectorAll('input[name="billed"]')
      paymentOptions.forEach(v => {
        paymentOptionsArr.push(v)
      })

      // paymentCondition = paymentOptionsArr.find(v => v.checked)

      if (paymentType.id == 1) {

        paymentCondition = this.paymentDeadline[0].id;

      } else {
        this.toastr.warning('Selecione uma opção de pagamento.')
        return false
      }
    } else {
      paymentCondition = null
    }


    let orderFreightArr: any[] = []


    calculos.forEach(c => {

      const destinatario = c.destinatario

      if(destinatario.neighborhood.includes('Conjunto Habitacional')) {
        destinatario.neighborhood = destinatario.neighborhood.replace('Conjunto Habitacional', 'C Hab.')
      }

      this.obs = destinatario.observation
      c.volumeInfo.forEach(v => {

        for (let i = 0; i < v.quantity; i++) {
          orderFreightArr.push({
            "carrierId": v.carrierId,
            "weight": JSON.parse(v.peso),
            "height": v.altura,
            "width": v.comprimento,
            "length": v.largura,
            "destName": destinatario.recipient,
            "destPhone": destinatario.phone,
            "destEmail": destinatario.email,
            "invoiceNumber": destinatario.nota,
            "destCity": destinatario.city,
            "destUf": destinatario.uf,
            "destAddress": destinatario.address,
            "destZipCode": v.cepDestino,
            "destNeighborhood": destinatario.neighborhood,
            "destNumber": destinatario.number,
            "destComplement": destinatario.complement == null ? destinatario.complement = "" : String(destinatario.complement),
            "destDocument": destinatario.documentNumber,
            "additionalServiceCode": v.additionalServiceCode || '',
            "keyNFE": destinatario.keyNFE,
            "serieNFE": destinatario.serieNFE,
            "stateRegistration": destinatario.stateRegistration,
            "value": v.value,
            "declaredValue": v.declaredValue,
            "maoPropria": v.maoPropria,
            "avisoRecebimento": v.avisoRecebimento,
            "deliveryTime": v.deliveryTime,

          })
          this.isDrops =  v.carrierId
          this.isEmail =  destinatario.email
          this.isPhone = destinatario.phone

        }
      })
    });

    if(this.isDrops == "29"  && (this.isEmail == null  || this.isPhone == null)){
      this.paymentSuccess= false
      this.transpSelected = false
      this.isClicked = undefined ;
      this.isLoading= false
      Swal.fire({
        html: `<svg version="1.1" style="width:100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/></svg><p class="error">Não foi possível gerar as etiquetas!</p><p class="error">${this.isEmail == null ? "Por Favor informe o e-mail do remetente" : ""} </p> <p class="error">${this.isPhone == null ? "Por Favor informe o telefone do remetente" : ""} </p>`,
      })


      this.closeModal();

    } else {
      const params = {
        "userId": this.userInformation.id,
        "clientId": this.remData.id,
        "code": "",
        "carrierValue": Number(localStorage.getItem('carrierVal')),
        "declaredValue": Number(localStorage.getItem('declaredVal')),
        "fee": Number(localStorage.getItem('fee')),
        "payTypeId": paymentType.id,
        "cepOrigem": this.userInformation.zipCode,
        "unitId": this.userInformation.unitId,
        "orderTypeId": 1,
        "paymentConditionId": Number(paymentCondition) || 3,
        "value": this.total,
        "observation": this.obs,
        "addOrderFreight": orderFreightArr
      }



        let response = await this.orderService.createOrderPDF(params)
        if (response?.sucess) {
          this.paymentSuccess = true
          this.isClicked = false;
          this.isLoading = false
          this.transpSelected = false
          Swal.fire({
            confirmButtonText: 'OK',
            html: '<div ><svg style="width: 100px;" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"> <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/></svg><p class="success" style="font-size: 2.5rem;">Etiquetas geradas!</p></div>'
          }).then((isConfirm) => {
            Swal.fire({
              icon: 'question',
              text: 'Deseja criar outro envio?',
              confirmButtonText: 'Sim',
              showCancelButton: true,
              cancelButtonText: 'Não'
            }).then((result) => {
              if (result.value == true) {
                Swal.fire({
                  icon: 'question',
                  text: 'Manter os dados do pedido anterior?',
                  title: '(Poderão ser alterados!)',
                  confirmButtonText: 'Sim',
                  showCancelButton: true,
                  cancelButtonText: 'Não',
                }).then((result) => {
                  if (result.value == 'true') {
                    this.resetCalc();
                    this.isLoading = false
                    this.isClicked = false;
                  } else if (result.dismiss) {
                    location.reload()
                  }
                })
              } else {
                location.reload()
              }
            })

          })
          this.closeModal();
        }
        else {
          if (response != undefined) {
            Swal.fire({
              html: `<svg version="1.1" style="width:100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/></svg><p class="error">Não foi possível gerar as etiquetas!</p><p> ${response}</p>`,
            })
            this.isClicked = false;
            this.closeModal()
          } else {
            Swal.fire({
              html: `<svg version="1.1" style="width:100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/><line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/></svg><p class="error">Não foi possível gerar as etiquetas!</p>`,
            })
            this.isClicked = false;
            this.closeModal()
          }
        }

    }






  }
  resetCalc() {
    this.freightList = [];
    this.isForm = !this.isForm
    this.isLoading = false;
  }



  fadeTable() {
    setTimeout(() => {

      this.isLoading = false
      anime({
        targets: '.table-row',
        opacity: 1,
        delay: anime.stagger(300),
        easing: 'spring(1, 80, 10, 0)'
      })
    }, 0);
  }
  populateCalc(calcs) {

  }
  validateUserAccess(){

      if(this.userInfo.typeUserId == 2 || this.userInfo.typeUserId == 3 || this.userInfo.typeUserId == 7 ){
        return
      }else{
        return this.router.navigate(['/']);

      }

  }
}
interface Option {
  id: number
  name: string
  info: string
  icon: IconDefinition
  active: boolean
  template: ViewContainerRef
}
