import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/layout/header/header.component';
import { HeaderLandingComponent } from './components/layout/header-landing/header-landing.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SideMenuComponent } from './components/layout/side-menu/side-menu.component';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarriersSlideComponent } from './components/carriers-slide/carriers-slide.component';
import { LandingCarriersComponent } from './components/landing-carriers/landing-carriers.component';
import { PicCarrierSlideComponent } from './components/pic-carrier-slide/pic-carrier-slide.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SharedModule } from './shared.module';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TermosComponent } from './pages/institutional/termos/termos.component';
import { AboutComponent } from './pages/institutional/about/about.component';
import { WorkWithUsComponent } from './pages/institutional/work-with-us/work-with-us.component';
import { CalculadoraHComponent } from './components/calc-home/calc-home.component';
import { CotacaoSimplesComponent } from './painel/components/cotacao-simples/cotacao-simples.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { SejaParceiroComponent } from './pages/seja-parceiro/seja-parceiro.component';
import { BeAFranchiseeComponent } from './pages/be-a-franchisee/be-a-franchisee.component';
import {WelcomeComponent} from './pages/welcome/welcome.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CadCotacaoComponent } from './pages/cad-cotacao/cad-cotacao.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FranchisingComponent } from './pages/franchising/franchising.component';
import { UnityCardComponent } from './components/unity-card/unity-card.component'
import { SafeUrlPipe } from './pipes/safe-url.pipe'
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxHorizontalTimelineModule } from 'ngx-horizontal-timeline';
import { TimelineComponent } from './components/timeline/timeline.component'
import { AboutUsMobileComponent } from './components/mobile/about-us-mobile/about-us-mobile.component'
import { BannerTruckComponent } from './components/mobile/banner-truck/banner-truck.component'
import { HeaderMobileComponent } from './components/mobile/header-mobile/header-mobile.component'
import { LandingCarrierMobileComponent } from './components/mobile/landing-carrier-mobile/landing-carrier-mobile.component'
import { ReasonsMobileComponent } from './components/mobile/reasons-mobile/reasons-mobile.component'
import { OurHistoryMobileComponent } from './components/mobile/our-history-mobile/our-history-mobile.component'
import { UnityMobileComponent } from './components/mobile/unity-mobile/unity-mobile.component'
import { BigNumbersMobileComponent } from './components/mobile/franchising/big-numbers-mobile/big-numbers-mobile.component'
import { HeaderFranchisingMobileComponent } from './components/mobile/franchising/header-franchising-mobile/header-franchising-mobile.component'
import { ProfileMobileComponent } from './components/mobile/franchising/profile-mobile/profile-mobile.component'
import { SolutionsComponent } from './components/mobile/franchising/solutions/solutions.component'
import { CarrierMobileComponent } from './components/mobile/carrier-mobile/carrier-mobile.component'
import { RouterModule } from '@angular/router';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeaderLandingComponent,
    SideMenuComponent,
    CarriersSlideComponent,
    LandingCarriersComponent,
    FranchisingComponent,
    LoginComponent,
    LandingPageComponent,
    TermosComponent,
    BeAFranchiseeComponent,
    TimelineComponent,
    AboutComponent,
    CadCotacaoComponent,
    WorkWithUsComponent,
    CalculadoraHComponent,
    CotacaoSimplesComponent,
    SejaParceiroComponent,
    BigNumbersMobileComponent,
    PicCarrierSlideComponent,
    HeaderFranchisingMobileComponent,
    ProfileMobileComponent,
    SolutionsComponent,
    UnityCardComponent,
    WelcomeComponent,
    SafeUrlPipe,
    AboutUsMobileComponent,
    BannerTruckComponent,
    HeaderMobileComponent,
    LandingCarrierMobileComponent,
    ReasonsMobileComponent,
    OurHistoryMobileComponent,
    UnityMobileComponent,
    CarrierMobileComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    RouterModule,
    BrowserModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    FontAwesomeModule,
    InlineSVGModule.forRoot(),
    SlickCarouselModule,
    BrowserAnimationsModule,
    NgxHorizontalTimelineModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TabsModule.forRoot(),

  ],

  providers: [ BsModalService,LandingPageComponent,
    {
    provide: LOCALE_ID,
    useValue: 'pt',
   
  },
  {
      provide:  DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
