
<!-- frst banner -->
<section class="page home-banner-top">
    <div class="wrapper" style="margin: 0;">
        <div class="page-banner" aria-label="Page Banner"></div>
        <div class="banner-content">
            <h1 class="home-title">A 1º franquia de <strong>hub logístico<br></strong> em soluções de encomendas<br> para e-commerce</h1>
            <div class="container-btn  mt-5">
                <button class="btn btn-lg btn-orange" routerLink="/cadastro-franqueado" target="_blank">Seja um
                    franqueado</button>
            </div>
        </div>
    </div>
</section>
<!-- fim frst banner desktop -->

<!-- fst banner mobile -->
<div class="header-mobile">
    <app-header-franchising-mobile></app-header-franchising-mobile>
</div>
<!--fim  fst banner mobile -->

<!-- timeline -->
<section class="ps-timeline-sec pb-5 home-banner-bottom">
    <h1>Estamos em constante<br><b>Evolução</b></h1>
    <div class="container">
        <ol class="ps-timeline">
            <li>
                <div class="img-handler-top">
                    <p><strong style="font-weight: 700;">Nascimento da PIC envios,</strong> abrimos nossa primeira loja na Galeria Pagé</p>
                </div>
                <div class="ps-bot">
       
                </div>
                <span class="ps-sp-top">2019</span>
            </li>
            <li>
                <div class="img-handler-bot">
                    <p>Começo da expansão para a nossa <strong style="font-weight: 700;">terceira loja</strong></p>
                </div>
                <div class="ps-top">
         
                </div>
                <span class="ps-sp-bot">2020</span>
            </li>
            <li>
                <div class="img-handler-top">
                    <p>A empresa bate um <strong style="font-weight: 700;">crescimento de 75%</strong> em relação ao ano anterior</p>
                </div>
                <div class="ps-bot">

                </div>
                <span class="ps-sp-top">2021</span>
            </li>
            <li>
                <div class="img-handler-bot">
                    <p style="padding-bottom: 28px;"><strong style="font-weight: 700;">160% de crescimento</strong> em comparação a 2021</p>
                    <p style="padding-bottom: 28px;">Lançamento projeto <strong style="font-weight: 700;">Franchising</strong>  e Desenvolvimento do <strong style="font-weight: 700;">aplicativo</strong></p>
                    <p style="padding-bottom: 28px;"> Desenvolvimento da tecnologia de<strong style="font-weight: 700;"> etiqueta digital...</strong></p>
                </div>
                <div class="ps-top">

                </div>
                <span class="ps-sp-bot">2022</span>
            </li>
        </ol>
    </div>
</section>
    <!-- fim teste timeline -->


    <!-- big numbers desktop -->
<section class="page home-banner-bottom mt-0 pt-5">
    <div class="wrapper">
        <div class="our-numbers pt-5">
            <h2>Números da Pic Envios</h2>
        </div>

        <div class="row " style="margin-top: 75px">
            <div class="col-md-4">
                <img style="width: 70%;" src="../../../assets/img/franchising/Caixa etiqueta copiar.png">
            </div>
            <div class="col-md-4">
                <div class="card" style="width: 32rem; border: none; border-radius: 17px; ">
                    <div class="card-body">
                        <h5 class="card-title">+1.1 mil clientes fidelizados</h5>
                    </div>
                </div>
            </div>
 
                <div class="col-md-4">
                    <div class="card" style="width: 32rem; border: none; border-radius: 17px;">
                        <div class="card-body" style="padding-bottom: 64px;">
                            <h5 class="card-title">+166 mil objetos entregues</h5>
                        </div>
                    </div>
                </div>

        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <div class="card" style="width: 32rem; border: none; border-radius: 17px;">
                    <div class="card-body" style="padding: 80px 13px;">
                        <h5 class="card-title">Mais de 20 parceiros homologados</h5>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card" style="width: 32rem; border: none; border-radius: 17px;">
                    <div class="card-body">
                        <h5 class="card-title">Alta eficiência nas entregas</h5>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="container-btn">
                    <button class="btn btn-lg btn-orange be-a-franchising-btn" routerLink="/cadastro-franqueado"><span>Clique aqui e</span> <br><h1>Seja um <br>Franqueado!</h1></button>
                </div>
            </div>
        </div>

    </div>

</section>
<!-- fim big numbers desktop-->

<!-- big numbers mobile -->
<div class="numbers-mobile">
    <app-big-numbers-mobile></app-big-numbers-mobile>
</div>
<!-- fim big numbers mobile -->

<section class="page home-banner-bottom mt-0 pt-5 reasons">
    <div class="wrapper">
        <div class="our-numbers pt-5">
            <h2>Temos ainda mais motivos para<br>você ser um franqueado</h2>
        </div>
            <div class="row truck">
                    <img class="img-icon" src="../../../assets/img/franchising/Grupo 1649.png">
                    <div class="col-md-6 mt-5">
                    <h3>Pioneira em soluções de <br> encomendas para<br> e-commerce</h3>
                </div>
                <div class="col-md-3">
                    <img class="img-truck" src="../../../assets/img/franchising/truck.png">
                </div>
                <div class="mt-2">
                    <span>A PIC envios é a primeira franquia de soluções em<br> encomendas para e-commerces.</span>
                </div>
            </div>
            <div>
            <div class="row mt-5">

          
                    <div class="col-md-6 mt-5">
                        <div class="d-flex">
                            <img class="icon-reasons" src="../../../assets/img/franchising/35_.png">
                             <h3>Setor cresce<br> ao ano</h3>
                        </div>
                     
                        <p class="pt-3">O e-commerce brasileiro já vende mais<br> do que todos os shoppings do país.</p>
                    </div>
                
                    <div class="col-md-6 mt-5">
                        <div class="d-flex">
                            <img class="icon-reasons" src="../../../assets/img/franchising/Grupo 1647.png">
                             <h3>Grandes geradores de demanda</h3>
                        </div>
                        <p class="pt-3">Mercado Livre, Shopee, Magalu, Americanas,<br> Shoptime e muitos outros marketplaces<br> e
                            e-commerces.</p>
                    </div>
            </div>
            <div class="row mt-5">

                    <div class="col-md-6">
                        <div class="d-flex">
                            <img class="icon-reasons" src="../../../assets/img/franchising/Grupo 1624.png">
                             <h3 style="padding-top: 24px;">Alta rentabilidade</h3>
                        </div>
                        <p class="pt-3">O faturamento médio de uma unidade da Pic envios<br> é de R$ 100.000 com a lucratividade média ficando <br>acima de 20% mensal*</p>
                    </div>
                 
  
                    <div class="col-md-6 mt-5">
                        <div class="d-flex">
                            <img class="icon-reasons" src="../../../assets/img/franchising/Grupo 1657.png">
                             <h3 >Operação simples</h3>
                        </div>
                        <p class="pt-3">Nossa operação pode ser iniciada com <br> o franqueado e apenas 2 funcionários.</p>
                    </div>
            </div>
            <div class="row mt-5">
   
                    <div class="col-md-6 mt-5">
                        <div class="d-flex">
                            <img class="icon-reasons" src="../../../assets/img/franchising/franchise copiar.png">
                             <h3 style="padding-top: 24px;">Baixo investimento</h3>
                        </div>
                        <p class="pt-3">Micro franquia com baixo investimento.</p>
                    </div>

                    <div class="col-md-6 mt-5">
                        <div class="container-btn  mt-5" >
                            <button class="btn btn-lg btn-orange" routerLink="/cadastro-franqueado">Seja um franqueado</button>
                        </div>
                    </div>
            </div>
        </div>

       
    </div>

</section>

<!-- reasons mobile -->
<div class="reasons-mobile">
    <app-solutions></app-solutions>
</div>
<!-- fim reasons mobile -->
<section class="page home-banner-bottom mt-0 pt-5 candidate-profile">
    <div class="wrapper">
        <div class="our-numbers mt-5 mb-5">
            <h2>Perfil ideal do candidato</h2>
        </div>

        <div class="row" style="text-align:center">
            <div class="col-md-6">
                <img class="img-icon-profile" src="../../../assets/img/franchising/Prancheta 6@4x.png">
                <p class="mt-5">Visão de futuro</p>
            </div>
            <div class="col-md-6 pl-0">
                <img class="w-100" src="../../../assets/img/franchising/Grupo 1747.png">
            </div>
        </div>

        <div class="row" style="text-align:center">
            <div class="col-md-6 pr-0">
                <img class="w-100" src="../../../assets/img/franchising/Grupo 1744.png">
            </div>
            <div class="col-md-6">
                <img class="img-icon-profile" src="../../../assets/img/franchising/Prancheta 3@4x.png">
                <p class="mt-5">Relacionamento<br> interpessoal</p>
            </div>
        </div>

        <div class="row" style="text-align:center">
            <div class="col-md-6">
                <img class="img-icon-profile" src="../../../assets/img/franchising/Prancheta 1@4x.png">
                <p class="mt-5">Experiência <br> em comércio</p>
            </div>
            <div class="col-md-6 pl-0">
                <img class="w-100" src="../../../assets/img/franchising/Grupo 1745.png">
            </div>
        </div>
        
        <div class="row" style="text-align:center">
            <div class="col-md-6 pr-0">
                <img class="w-100" src="../../../assets/img/franchising/Grupo 1770.png">
            </div>
            <div class="col-md-6">
                <img class="img-icon-profile" src="../../../assets/img/franchising/Prancheta 5@4x.png">
                <p class="mt-5">Disponibilidade<br> total</p>
            </div>
        </div>

        <div class="row" style="text-align:center">
            <div class="col-md-6">
                <img class="img-icon-profile" style="margin-left: 33px;" src="../../../assets/img/franchising/Prancheta 4@4x.png">
                <p class="mt-5">Habilidade<br>de prospecção</p>
            </div>
            <div class="col-md-6 pl-0">
                <img class="w-100" src="../../../assets/img/franchising/Grupo 1766.png">
            </div>
        </div>
        <div class="container-btn  mt-5 mb-5" style="margin-left: 43rem!important;">
            <button class="btn btn-lg btn-orange" routerLink="/cadastro-franqueado">Seja um franqueado</button>
        </div>

        <div class="contact pb-5">
                <div class="col-md-6"><span class="alert">*Números baseados nos resultados das unidades atuais</span></div>
            </div>

    </div>

</section>

<app-footer></app-footer>