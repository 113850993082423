import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_Routes } from '../../environments/environment'
import { Calculo } from '../models/volume.model';
import { FreightCleaner } from './cleaners/freight.cleaner.service';
import { StorageService } from './storage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  userInfo
  constructor(
    private http: HttpClient,
    private freightCleaner: FreightCleaner,
    private storageService: StorageService,
    private actRoute: ActivatedRoute,
  ) {
    this.actRoute.data.subscribe(v => {
      this.userInfo = v.userInfo
    })
  }


  calculateFrete(volume: Calculo) {



    return this.http.post<any>(`${API_Routes.DATA_API}/api/Freight/GetFreight`, volume)
      .toPromise()
      .then(response => {
        return response

      }).catch(error => {
        return { sucess: false }
      })

  }

  calculateFreteExterna(volume: Calculo) {

    let userCookies = this.storageService.get('userInfo')
    userCookies = userCookies ? JSON.parse(atob(userCookies)) : null
    let token = userCookies.token

    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Freight/GetFreight`, volume, { headers: { Authorization: `Bearer ${token}` } })
      .toPromise()
      .then(response => {
        return response
      }).catch(error => {
        return { sucess: false }
      })
  }

  getTracking(trackCode) {
    return this.http.get<any>(`${API_Routes.DATA_ORDER_API}/api/Freight/GetTracking?trackingNumber=${trackCode}`)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }

  bePartner(params) {
    return { sucess: false }
  }
}
