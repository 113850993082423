<div class="painel-page bg-white" #calcSection>

    <h2 class="section-title">Calculadora de Frete</h2>
    <p class="section-subtitle">Faça a medição do seu pacote e insira os dados abaixo para comparar as cotações de
        entrega!</p>
    <ng-container #calcForm>
        <div class="calc-form">
            <ng-container *ngFor="let calc of calculos">
                <div class="p-relative">
                    <div class="calc-collapse" [class.show]="activeCalc == calc.id">
                        <app-calc-panel (calcMounted)="calcMounted($event)" [_calculo]="calc" [_userInfo]="userInfo" [_receiveData]="receiveData">
                        </app-calc-panel>
                    </div>
                </div>
            </ng-container>


            <ng-container #panelCalcBtn>
                <div class="row buttons">
                    <span class="w-50"></span>
                    <button type="submit" (click)="mountCalc(); openModal(template)" class="btn btn-lg btn-blue " >
                        Fazer cálculo
                    </button>
                    <span class="w-30"></span>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-template #template>
        <ng-container *ngIf="!isForm">
            <div class="modal-body table-color">
                <owl-carousel-o [options]="customOptions">
                    <ng-container *ngFor="let calc of calculosFromEach; let in = index">
                        <ng-template carouselSlide [id]="'calc' + in" style="margin: 15rem 2rem">
                            <div class="calc-table" #calcTable id='calcTable'>
                                <table class="table groupedVol">
                                    <thead class="table-head">
                                        <div class="icon mx-3">
                                            <fa-icon [icon]="faClose" (click)="closeModal()"></fa-icon>
                                        </div>
                                        <span class="d-block text-center mb-5">Selecione uma opção de envio para o <span
                                                class="volume-label">volume {{in +1}}</span> </span>

                                        <tr class="table-row">
                                            <th class="table-cell"><span>Transportadora</span></th>
                                            <th class="table-cell"><span>Modalidade</span></th>
                                            <th class="table-cell"><span>Prazo <br>Estimado</span></th>
                                            <th class="table-cell"><span>Valor <br>do frete</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="isLoading" class="loading-container" style="border: none;">
                                            <app-loading></app-loading>
                                        </tr>
                                        <ng-container *ngIf="freightList.length > 0">
                                            <tr *ngFor="let freight of calc.freight; let i = index"
                                                (click)="setActiveFreight(freight, in)" class="table-row"
                                                [class.bg-out]="i % 2 == 0" [class.active]="freight.active"
                                                [class.disabled]="freight.erro">

                                                <td class="table-cell cell-img"><img [src]="freight.logo"
                                                        [alt]="freight.carrier"></td>
                                                <td class="table-cell">{{ freight.modality }}</td>
                                                <td class="table-cell" *ngIf="freight.erro">{{ freight.erro }}</td>
                                                <td class="table-cell" *ngIf="!freight.erro">{{ freight.deliveryTime + '
                                                    a ' +
                                                    (freight.deliveryTime + 1) }} dias</td>
                                                <td class="table-cell" *ngIf="!freight.erro"><b>{{ freight.value |
                                                        currency }}</b></td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>

                            </div>
                            <div class="row justify-content-center mt-5">
                                <button class="btn btn-withicon btn-withicon-toRight btn-blue"
                                    (click)="setGroupedOrder()">
                                    Ir Para Pagamento
                                </button>
                            </div>


                            <div class="payment-section" [ngStyle]="{'display': !transpSelected ? 'none' : 'block'}" style="height: 315px">
                                <p class="my-5 payment-topic">
                                    <span class="d-block text-center mb-5">Selecione a forma de pagamento </span>
                                </p>
                                <div class="flex payment-options align-payment">
                                    <div class="payment-option" *ngFor="let option of paymentOption"
                                        [class.active]="activeOption == option.id" (click)="activeOption = option.id">
                                        <div class="icon">
                                            <fa-icon [icon]="option.icon"></fa-icon>
                                        </div>
                                        <span>{{option.name}}</span>
                                        <p>{{option.info}}</p>
                                        <div class="option-container shadow" *ngIf="activeOption == option.id">
                                            <ng-container *ngTemplateOutlet="optionTemplate; context: option">
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #optionTemplate let-billed="false" let-id="id" let-option="name">
                            <div [ngSwitch]="id">
                                <ng-container *ngSwitchCase="3">
                                    <ul class="options-list">
                                        <li>
                                            <div class="form-field">
                                                <input name="card" id="credit" value="credit" type="radio" checked>
                                                <label for="credit" class="input-radio">Crédito</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-field">
                                                <input name="card" id="debit" value="debit" type="radio">
                                                <label for="debit" class="input-radio">Débito</label>
                                            </div>
                                        </li>
                                    </ul>
                                </ng-container>
                                <ng-container *ngSwitchCase="1" >
                                        <div *ngFor="let option of paymentDeadline" class="options-cash">
                                                <span class="text-center text-primary" id="billed" >O prazo para pagamento é de</span>  <span class=" text-secondary text-center">{{option?.paymentCondition }} </span>
                                        </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="2">
                                    <div class="options-cash" [class.height]="change - total > 0">
                                        <span class="text-primary text-center">Total: {{ total | currency: 'R$'}}</span>
                                        <div class="form-field">
                                            <label for="recebido">Recebido</label>
                                            <input type="text" placeholder="R$" [(ngModel)]="change" currencyMask
                                                [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                id="recebido">
                                        </div>
                                        <div [class.active]="change - total > 0"
                                            class="mt-3 options-change row justify-content-center">
                                            <span class="text-primary mr-2">Troco: </span>
                                            <span class="text-secondary" value=""> {{ change - total | currency: 'R$'}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>


                        </ng-template>
                    </ng-container>

                </owl-carousel-o>

                <div *ngIf="isClicked " class="loading-container" style="border: none; padding-bottom: 20px;">
                    <app-loading></app-loading>
                </div>
                <div class="row justify-content-space-evenly mt-5">
                    <button class="btn btn-lg btn-blue" (click)="buildForm()" *ngIf="!isClicked && transpSelected">
                        Finalizar Zebra
                    </button>
                    <button class="btn btn-lg btn-blue" (click)="buildFormPDF() " *ngIf="!isClicked && transpSelected">
                        Finalizar PDF
                    </button>


                </div>

            </div>
        </ng-container>

        <ng-template #optionTemplate let-id="id" let-option="name">
            <div class="options-wrapper" [ngSwitch]="id">
                <ng-container *ngSwitchCase="3">
                    <ul class="options-list">
                        <li>
                            <div class="form-field">
                                <input name="card" id="credit" value="credit" type="radio" checked>
                                <label for="credit" class="input-radio">Crédito</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-field">
                                <input name="card" id="debit" value="debit" type="radio">
                                <label for="debit" class="input-radio">Débito</label>
                            </div>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <ul class="options-list options-billed">
                        <span>O prazo para pagamento é:  </span>
                        <li *ngFor="let option of paymentDeadline">
                                <label [for]="option.id" class="input-radio">{{option?.paymentCondition }}</label>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <div class="options-cash" [class.height]="change - total > 0">
                        <span class="text-primary text-center">Total: {{ total | currency: 'R$'}}</span>
                        <div class="form-field">
                            <label for="recebido">Recebido</label>
                            <input type="text" placeholder="R$" [(ngModel)]="change"
                                [ngModelOptions]="{standalone: true}" currencyMask
                                [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                id="recebido">
                        </div>
                        <div [class.active]="change - total > 0" class="mt-3 options-change row justify-content-center">
                            <span class="text-primary mr-2">Troco: </span>
                            <span class="text-seconda   ry"> {{ change - total | currency: 'R$'}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </ng-template>
</div>
