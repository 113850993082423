<div class="reasons wrapper">
<h1>Temos ainda mais<br> motivos para você<br> ser um franqueado</h1>
    <img src="../../../../../assets/img/truck-mobile.png">
<div>
    <div class="d-flex">
        <img class="icon-reasons" src="../../../../../assets/img/franchising/Grupo 1649.png">
         <h3>Pioneira em soluções<br> de envios para<br> e-commerce</h3>
    </div>
 
    <p class="pt-3">A PIC envios é a primeira franquia de soluções em envios para e-commerces.</p>
</div>
</div>
