export class Calculo {
    cepOrigem: string
    onlyCorreios: boolean
    // sCepDestino: string
    volumes: Volume[]

    constructor(
        cepOrigem,
        // sCepDestino,
        volumes) {
        this.onlyCorreios = false
        this.cepOrigem = cepOrigem || '',
        // this.sCepDestino = sCepDestino || '',
        this.volumes = volumes || []
    }
}

export class Volume {
    cepDestino: string
    quantity: number
    peso: number
    comprimento: number
    altura: number
    largura: number
    maoPropria: string
    valorDeclarado: number
    avisoRecebimento: string
    nfe: boolean
    semCPF: boolean
    constructor(
        cepDestino,
        quantity,
        peso,
        comprimento,
        altura,
        largura,
        maoPropria,
        valorDeclarado,
        avisoRecebimento,
        nfe,
        semCPF) {
            this.cepDestino = cepDestino || '',
            this.quantity = quantity || 0,
            this.peso = peso || 0,
            this.comprimento = comprimento || 0,
            this.altura = altura || 0,
            this.largura = largura || 0,
            this.maoPropria = maoPropria || '',
            this.valorDeclarado = valorDeclarado || 0,
            this.avisoRecebimento = avisoRecebimento || ''
            this.nfe = nfe
            this.semCPF = semCPF
    }
}