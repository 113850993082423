<form class="form" autocomplete="off" [formGroup]="freightForm">
  <div class="row w-90 mx-auto">
    <div class="cepInput form-field">
      <input mask="00000-000" type="text" class="w-100" placeholder="CEP Origem" name="cepOrigem" #cepOrigem
        [readonly]="userInformation.typeUserId != 1" formControlName="cepOrigem">

      <small class="text-danger">Valor obrigatório</small>
    </div>
    <!-- <div class="calc-change">
            <div class="icon">
                <fa-icon [icon]="faSortAlt" [class.toggle-rotate]="reverseLogistic" class="calc-icon-sort">
                    </fa-icon>
            </div>
            <p class="calc-text">Logística Reversa</p>
            <div class="togglers" (click)="reverseLogistic = !reverseLogistic">
                <div class="icon">
                    <fa-icon [class.toggle-fade]="reverseLogistic" [icon]="faToggleOn">
                    </fa-icon>
                    <fa-icon [class.toggle-fade]="!reverseLogistic" [icon]="faToggleOff">
                    </fa-icon>
                </div>
            </div>
        </div> -->
    <div class="cepInput form-field">
      <input mask="00000-000" type="text" placeholder="CEP Destino" class="w-100" name="cepDestino" #cepDestino
        formControlName="cepDestino">
      <small class="text-danger">Valor obrigatório</small>
    </div>
  </div>
  <div *ngFor="let volume of volumesFormFor; let i = index" class="my-5 w-90 mx-auto" formArrayName="volumesForm">
    <div class="row" [formGroupName]="i">
      <div *ngIf="formUpdated" class="volume-label volume-i volume-i-left">Volume {{i + 1}}</div>
      <div *ngIf="formUpdated" class="volume-quantity volume-i">
        <div class="form-field form-field-quantity">
          <div class="quantity-selector">
            <button [disabled]="qtdInput.value <= 1" name="Diminuir em um a quantide de volumes" type="button"
              (click)="removeQuantity(i)"> - </button>
            <input type="number" formControlName="quantity" #qtdInput>
            <button [disabled]="qtdInput.value >= 99" name="Aumentar em um a quantide de volumes" type="button"
              (click)="addQuantity(i)"> + </button>
            <small class="text-danger">Mínimo 1 volume:</small>
          </div>
        </div>
      </div>
      <div class="form-field">
        <label class="mt-auto" for="height">Altura <small>(unitário)*</small></label>
        <input type="number" maxlength="3" min="0" id="height" name="height" formControlName="height" required>
        <p class="input-placeholder">cm</p>
        <small class="text-danger">Valor obrigatório</small>
      </div>
      <div class="form-field">
        <label class="mt-auto" for="width">Largura <small>(unitário)*</small></label>
        <input type="number" min="0" maxlength="3" id="width" name="width" formControlName="width" required>
        <p class="input-placeholder">cm</p>
        <small class="text-danger">Valor obrigatório</small>
      </div>
      <div class="form-field">
        <label class="mt-auto" for="length">Comprimento <small>(unitário)*</small></label>
        <input type="number" min="0" maxlength="3" id="length" name="length" formControlName="length" required>
        <p class="input-placeholder">cm</p>
        <small class="text-danger">Valor obrigatório</small>
      </div>
      <div class="form-field">
        <label class="mt-auto" for="weight">Peso <small>(unitário)*</small></label>
        <input type="text" id="weight" name="weight" maxlength="12" #weight [suffix]="renderWeightSuffix(weight.value)"
          mask="separator.3" (keydown)="checkWeight($event, i)" formControlName="weight" required>
        <!-- <p class="input-placeholder">kg</p> -->
        <small class="text-danger">Valor obrigatório</small>
      </div>
      <div class="form-field">
        <label class="mt-auto" for="value">Valor da Carga <small><br>(por volume)*</small></label>
        <input type="text" id="value" currencyMask
          [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }" name="value" formControlName="value"
          required>
        <p class="input-placeholder">R$</p>
        <small class="text-danger">Valor obrigatório</small>
      </div>
      <div *ngIf="volumesFormFor.length > 1" (click)="volumesForm.removeAt(i)"
        class="volume-delete volume-i volume-i-right">
        <div class="icon">
          <fa-icon [icon]="faTrashAlt"></fa-icon>
        </div>
      </div>
      <div class="vol-btn">
        <div class="icon" (click)="addVolume()">
          <fa-icon [icon]="faPlus"></fa-icon>
          <span class="duplicate">Duplicar</span>
        </div>
      </div>
    </div>
  </div>


  <div class="calc-destinatario">
    <div class="tabbed">
      <input type="radio" id="tab1" name="css-tabs" checked>
      <input type="radio" id="tab2" name="css-tabs">
      <input type="radio" id="tab3" name="css-tabs">

      <ul class="tabs">
        <li class="tab"><label for="tab1">Dados do Destinatário</label></li>
        <li class="tab"><label for="tab2">Dados do Remetente</label></li>
        <li class="tab" *ngIf="isDc"><label for="tab3">Declaração de Conteúdo</label></li>
        <small class="small-field">(Campos com * são obrigatórios)</small>
      </ul>

      <div class="tab-content">
        <div *ngIf="formUpdated" style="padding-top: 50px;">

          <div class="row btn-radio">
            <div class="form-field justify-start">
              <label for="recipient">Destinatário*</label>
              <input type="text" id="recipient" formControlName="recipient" required>
              <small class="text-danger">Valor obrigatório</small>
            </div>
            <div class="form-field justify-start ">
              <label for="documentNumber">CPF ou CNPJ*</label>
              <input class="doc-input " type="text" id="documentNumber" formControlName="documentNumber" mask="CPF_CNPJ"
                [readonly]="isDoc == true" required>
              <small class="text-danger" style="top: 94px;" *ngIf="docValue != 'Indefinido' && docValue != ''">Digite um
                número válido</small>


              <!-- <div class="form-field form-top justify-start">
                                <input type="radio" formControlName="documentNumber" id="isDoc" (click)="forDoc()"
                                    value="Indefinido">
                                <label for="isDoc" class="input-radio mx-5 label-radio doc-check">Indefinido</label>
                            </div>
                            <div class="form-field form-top justify-start clear-doc">
                                <small class="text-secondary" (click)="clearCheckDoc()">Limpar</small>
                            </div> -->
            </div>
            <div class="form-field justify-start ">
              <label for="nota">Número da Nota*</label>
              <input type="text" id="nota" formControlName="nota" class="note-input nfe-input" [readonly]="isDc == true"
                required>
              <small class="text-danger">Digite o número ou selecione uma opção ao lado</small>
            </div>

            <div class="form-field form-top">
              <input formControlName="nota" name="nota" id="isDc" value="DC" type="radio"
                (click)="forDc(); isIe = true">
              <label for="isDc" class="input-radio mx-5 label-radio">Declaração Conteúdo
              </label>
            </div>
            <div class="form-field form-top clear-btn">
              <small class="clear-btn" (click)="clearCheck()"> Limpar
              </small>
            </div>
          </div>

          <div class="row btn-radio">
            <div class="form-field justify-start">
              <label for="keyNFE" *ngIf="!isDc">Chave NFE*</label>
              <label for="keyNFE" *ngIf="isDc">Chave NFE</label>
              <input type="text" id="keyNFE" formControlName="keyNFE" [readonly]="isDc == true || dc == true"
                mask="0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000" class="nfe-input"
                [required]="isDc != true">
              <small class="text-danger">Valor obrigatório</small>
            </div>
            <div class="form-field justify-start">
              <label for="serieNFE" *ngIf="!isDc">Série NFE*</label>
              <label for="serieNFE" *ngIf="isDc">Série NFE</label>
              <input type="text" id="serieNFE" formControlName="serieNFE" class="nfe-input"
                [readonly]="isDc == true || dc == true" [required]="isDc != true">
              <small class="text-danger">Valor obrigatório</small>
            </div>
            <div class="form-field justify-start">
              <label for="stateRegistration">Inscrição Estadual*</label>
              <input type="text" class="nfe-input" formControlName="registration" id="inscricao"
                [readonly]="isIe == true" [required]="isIe == false">
              <small class="text-danger">Campo obrigatório. Caso seja pessoa física, marcar
                Isento.</small>
            </div>

            <div class="form-field form-top">
              <input type="radio" formControlName="registration" id="isIe" (click)="forIe()" value="Isento">
              <label for="isIe" class="input-radio mx-5 label-radio">Isento</label>
            </div>
            <div class="form-field form-top clear-btn ">
              <small class="clear-btn text-secondary" (click)="clearCheckIe()">Limpar</small>
            </div>

          </div>
          <div class="row calc-dest">
            <div class="form-field justify-start">
              <label for="address">Endereço*</label>
              <input type="text" id="address" formControlName="address" required>
              <small class="text-danger">Valor obrigatório</small>
            </div>
            <div class="form-field justify-start">
              <label for="number ">Número*</label>
              <input type="text" id="number" formControlName="number" required>
              <small class="text-danger">Valor obrigatório</small>
            </div>
            <div class="form-field justify-start">
              <label for="complemento">Complemento</label>
              <input type="text" id="complemento" formControlName="complemento" maxlength="20">
            </div>
          </div>
          <div class="row">
            <div class="form-field justify-start">
              <label for="neighborhood">Bairro*</label>
              <input type="text" id="neighborhood" formControlName="neighborhood" required>
              <small class="text-danger">Valor obrigatório</small>
            </div>
            <div class="form-field justify-start">
              <label for="city">Cidade*</label>
              <input [readonly]="addressLock ==  true" type="text" id="city" formControlName="city" required>
              <small class="text-danger">Valor obrigatório</small>
            </div>

            <div class="form-field justify-start">
              <label for="uf">Estado*</label>
              <input [readonly]="addressLock ==  true" type="text" id="uf" formControlName="uf" required>
              <small class="text-danger">Valor obrigatório</small>
            </div>
          </div>
          <div class="row">
            <div class="form-field justify-start">
              <label for="email">E-mail</label>
              <input type="text" id="email" formControlName="email" >
            </div>
            <div class="form-field justify-start">
              <label for="phone">Telefone</label>
              <input  type="text" id="phone" formControlName="phone"  mask="(00) 00000-0000">

            </div>

            <div class="form-field justify-start">
              <label for="observation">Observações</label>
              <textarea type="text" id="observation" formControlName="observation" class="input-obs"
                maxlength="45"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content">
        <div class="search calc-destinatario form-remetente search-remetente">
          <span>Dados do Rementente</span>
          <div class="search-box">
            <label for="search">Buscar por CNPJ/CPF/Código/Nome</label>
            <div class="search-field" style="width: 80%;">
              <input type="text" id="search" #searchText [typeahead]="search$" [(ngModel)]="search"
                (typeaheadOnSelect)="onSelect($event)" [ngModelOptions]="{standalone: true}">

              <div class="icon" (click)="setSearch(searchText.value)">
                <fa-icon [icon]="faSearch"></fa-icon>
              </div>
            </div>
            <div class="container-btn row-btn-add">
              <button class="btn btn-withicon w-100" (click)="cadVisible = !cadVisible">
                <div class="icon">
                  <fa-icon [icon]="faUserPlus"></fa-icon>
                </div>
                Adicionar Cliente
              </button>
            </div>
          </div>



          <form [formGroup]="clientForm">

            <div class="form-field w-100 my-4">
              <label for="cnpj">CNPJ*</label>
              <input formControlName="cnpj" disabled type="text" id="cnpj">
            </div>
            <div class="form-field w-100 my-4">
              <label for="nome">Nome*</label>
              <input formControlName="nome" disabled type="text" id="nome">
            </div>
            <div class="form-field w-100 my-4">
              <label for="cnpj">Responsável</label>
              <input formControlName="responsavel" disabled type="text" id="resposavel">
            </div>
            <div class="form-field w-100 my-4">
              <label for="loja">Loja</label>
              <input formControlName="loja" disabled type="text" id="loja">
            </div>
            <div class="form-field w-100 my-4">
              <label for="email">E-mail*</label>
              <input formControlName="email" disabled type="email" id="email">
            </div>
          </form>
        </div>


      </div>

      <div class="tab-content">
        <div class="row-dc">
          <form autocomplete="off" [formGroup]="contentForm">
            <div>
              <span class="span-dc">Adicionar item na declaração:</span>
              <div class="form-field justify-start">
                <label for="content">Conteúdo do pacote (1 item)*</label>
                <input type="text" id="content" formControlName="content" required>
                <small class="text-danger-dc">Valor obrigatório</small>
              </div>
              <div class="form-field justify-start">
                <label for="typePacking">Tipo de embalagem:*</label>
                <input type="text" id="typePacking" formControlName="typePacking" required>
                <small class="text-danger-dc">Valor obrigatório</small>
              </div>
              <div class="row" style="grid-template-columns: 1fr 1fr;">
                <div class="form-field justify-start">
                  <label for="quantity">Qtd. Itens*</label>
                  <input type="number" id="quantity" formControlName="quantity" required>
                  <small class="text-danger-dc">Valor obrigatório</small>
                </div>

                <div class="form-field justify-start">
                  <label for="value">Valor (R$)*</label>
                  <input type="text" id="valor" formControlName="value" currencyMask
                    [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }" required>
                  <small class="text-danger-dc">Valor obrigatório</small>
                </div>

              </div>
              <div>

                <button class="btn-dc" type="submit" (click)="addItems()">Adicionar objeto</button>
              </div>
            </div>
          </form>
          <div class="col-md-6">
            <div class="container-dc mx-5">
              <span class="span-dc">Identificação</span>
              <table class="dc-table">
                <thead class="table-head">
                  <tr class="table-row">
                    <th class="table-cell">Item</th>
                    <th class="table-cell">Qntd</th>
                    <th class="table-cell">Conteúdo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of items; let i = index">
                    <td class="table-cell">{{i + 1}}</td>
                    <td class="table-cell">{{item.quantity}}</td>
                    <td class="table-cell">{{item.content}}</td>
                    <td class="table-cell"><span class="remove-span" (click)="removeItem(i)">Remover</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</form>

<ng-container>
  <app-add-client [_visible]="cadVisible" [_userInfo]="userInfo"></app-add-client>
</ng-container>
