import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-numbers-mobile',
  templateUrl: './big-numbers-mobile.component.html',
  styleUrls: ['./big-numbers-mobile.component.scss']
})
export class BigNumbersMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
