<section #loginP class="login">
    <div class="page login-banner">
        <div class="wrapper">
            <div class="page-banner" aria-label="Page Banner" [inlineSVG]="'assets/svg/Icone_Branco_SVG.svg'"></div>
            <div class="banner-content">
                <p>Bem-vindo de volta a</p>
                <h2 class="text-secondary">Pic Envios!</h2>
            </div>
            <!-- <div class="flex flex-column align-items-start">
                <button (click)="log(1)" class="btn btn-blue">LOGAR COMO MASTER</button>
                <button (click)="log(2)" class="btn btn-blue">LOGAR COMO GERENTE</button>
                <button (click)="log(3)" class="btn btn-blue">LOGAR COMO OPERADOR</button>
                <button (click)="logOut()" class="btn btn-blue">DESLOGAR</button>
            </div> -->
        </div>
    </div>
    <div class="page login-form">
        <div class="login-container">
            <span class="back-page" [routerLink]="['/']">
                <div class="icon">
                    <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
                </div>
                Voltar para home
            </span>
            <div class="login-card shadow">
                <h2 class="mb-3">Fazer Login</h2>
                <div *ngIf="loginWrong" class="login-wrong">
                    <p><b>Ops!</b> Login e/ou senha inválido!</p>
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
                    <div class="form-field login-field">
                        <label for="login">Usuário ou e-mail</label>
                        <input type="text" formControlName="login" id="login">
                        <div class="icon">
                            <fa-icon [icon]="faUser"></fa-icon>
                        </div>
                    </div>
                    <div class="form-field login-field">
                        <label for="password">Senha</label>
                        <input type="password" formControlName="password" autocomplete="new-password" id="password">
                        <div class="icon">
                            <fa-icon [icon]="faLock"></fa-icon>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-lg btn-secondary mt-5">Entrar</button>
                </form>
                <div class="login-options">
                    
                </div>
            </div>
        </div>
    </div>
</section>