<div #cadClient class="add-modal">
    <button class="btn btn-close-tab" (click)="closeTab()">
        <div class="icon">
            <fa-icon [icon]="faTimes"></fa-icon>
        </div>
    </button>
    <div class="wrapper">
        <span class="add-modal-head">
            <div class="icon">
                <fa-icon [icon]="faUserPlus"></fa-icon>
            </div>
            Adicionar Cliente
        </span>

        <form [formGroup]="clientForm" class="clientForm" (ngSubmit)="addClient(clientForm)">
            <span class="my-5">Dados da Empresa</span>
            <div class="bg-primary-light p-5">
                <div class="row w-100">

                    <div class="form-field" [class.invalid]="isIncorrect('cnpj')">
                        <!-- <label>CPF ou CNPJ</label> -->
                        <div class="row mx-0">
                            <div class="btn-group" dropdown>
                                <button id="button-animated" dropdownToggle type="button"
                                    class="dropdown-toggle d-flex align-items-center" aria-controls="dropdown-animated">
                                    Inserir {{ doc }} <span class="caret"></span>
                                </button>
                                <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
                                    aria-labelledby="button-animated">
                                    <li *ngIf="doc != 'CPF'" class="p-3" (click)="doc = 'CPF'">CPF</li>
                                    <li *ngIf="doc != 'CNPJ'" class="p-3" (click)="doc = 'CNPJ'">CNPJ</li>
                                </ul>
                            </div>
                        </div>
                        <input  (keyup)="followField()" formControlName="cnpj" type="text" [mask]="mask" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                    <div class="form-field" *ngIf="doc == 'CNPJ'">
                        <label>Nome da Loja*</label>
                        <input formControlName="nomeLoja" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                    <div class="row w-100">
                        <div class="form-field" *ngIf="doc == 'CNPJ'">
                            <label>Razão Social*</label>
                            <input formControlName="razaoSocial" type="text" required>
                            <small class="text-danger">Campo Inválido</small>
                        </div>
                        <div class="form-field" *ngIf="doc == 'CPF'">
                            <label>Nome*</label>
                            <input  formControlName="nomeLoja" type="text" required>
                            <small class="text-danger">Campo Inválido</small>
                        </div>
                        <div class="form-field">
                            <label>Responsável <small>(Este nome aparecerá na etiqueta)</small></label>
                            <input  type="text" formControlName="responsavel">

                        </div>
                    </div>

                    <div class="row w-100" *ngIf="doc == 'CNPJ'">
                        <div class="form-field">
                            <label for="stateRegistration">Inscrição Estadual*</label>
                            <input type="text" id="stateRegistration" class="nfe-input" [readonly]="isIe == true"
                                formControlName="stateRegistration" required>
                            <small class="text-danger">Campo obrigatório. Caso seja pessoa física, marcar Isento.
                            </small>
                        </div>
                        <div class="form-field form-top">
                            <input formControlName="stateRegistration" name="stateRegistration" id="isIe" value="Isento"
                                type="radio" (click)="forIe()">
                            <label for="isIe" class="input-radio mx-5 label-radio">Isento
                            </label>
                        </div>
                        <div class="form-field form-top clear-btn">
                            <small class="clear-btn text-secondary" (click)="clearCheckIe()"> Limpar
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="form-field">
                        <label>E-mail</label>
                        <input id-="documento" formControlName="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                    </div>
                    <div class="form-field">
                        <label>Telefone</label>
                        <input formControlName="telefone" type="telefone" mask="(00)0000-0000 || (00)00000-0000">
                    </div>
                </div>
                <span class="my-5">Endereço</span>
                <div class="row w-100">
                    <div class="form-field">
                        <label>CEP*</label>
                        <input formControlName="cep" mask="00000-000" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>

                    <div class="form-field">
                        <label>Endereço*</label>
                        <input formControlName="endereco" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>

                    <div class="form-field">
                        <label>Número*</label>
                        <input formControlName="numero" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                    <div class="form-field">
                        <label>Complemento</label>
                        <input formControlName="complemento" type="text" maxlength="25">
                    </div>

                    <div class="row w-100">
                        <div class="form-field">
                            <label>Cidade*</label>
                            <input [readonly]="addressLock == true"  id="city" formControlName="cidade" type="text" required>
                            <small class="text-danger">Campo Inválido</small>
                        </div>
                        <div class="form-field">
                            <label>Bairro*</label>
                            <input formControlName="bairro" type="text" required>
                            <small class="text-danger">Campo Inválido</small>
                        </div>
                        <div class="form-field">
                            <label>Estado*</label>
                            <input [readonly]="addressLock == true" id="uf" formControlName="uf" type="text" required>
                            <small class="text-danger">Campo Inválido</small>
                        </div>
                    </div>
                </div>
                <span class="my-5">Opções de Pagamento*</span>
                <div *ngIf="formUpdated" class="card-paymentOptions">
                    <ul class="options-list options-billed">
                        <li *ngFor="let option of paymentDeadline; let i = index">
                            <div class="form-field">
                                <input name="billed" [id]="option.Id" type="radio">
                                <label [for]="option.Id" class="input-radio mx-5">{{ option.PaymentCondition }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <button class="btn btn-blue mx-auto my-3 d-block" type="submit">Salvar</button>
        </form>

    </div>
</div>
