<div class="carriers-slide">
    <owl-carousel-o [options]="customOptions">
 
        <ng-container *ngFor="let carrier of carriers">
          <ng-template carouselSlide [id]="carrier.name">
            <a [href]="carrier.url" target="_blank">
                <img [src]="'./assets/img/carriers/' + carrier.name + '.png'" [alt]="carrier.name">
            </a>
          </ng-template>
        </ng-container>
     
      </owl-carousel-o>
</div>