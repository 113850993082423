import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InlineSVGModule } from 'ng-inline-svg';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AppComponent } from './app.component';

import { CalculadoraComponent } from './components/calculadora/calculadora.component';
import { LoadingComponent } from './components/layout/loading/loading.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ToastrModule } from 'ngx-toastr';
import { CalcPanelComponent } from './painel/components/calc-panel/calc-panel.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { AddClientComponent } from './components/add-client/add-client.component';
import { FinishUserComponent } from './components/finish-user/finish-user.component';
 
@NgModule({
  declarations: [CalculadoraComponent, FinishUserComponent, LoadingComponent, CalcPanelComponent, AddClientComponent ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    InlineSVGModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      progressAnimation: 'increasing',
      maxOpened: 3,
      autoDismiss: true,
            
    }),
    TypeaheadModule.forRoot(),
    NgxCurrencyModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CarouselModule,

  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [CalculadoraComponent, FinishUserComponent, CalcPanelComponent, AddClientComponent, LoadingComponent, TooltipModule, TypeaheadModule, BsDropdownModule, PaginationModule, CarouselModule, NgxCurrencyModule]
})
export class SharedModule { }
