
<head>
    <script src="js/jquery.timelinr-0.9.54.js"></script>
    <script>
      $(function(){
                  $().timelinr({
                      arrowKeys: 'true'
                  })
              });
    </script>
  </head>
  
  <body>
  
    <div id="timeline">
      <ul id="dates">
        <li ><a (click)="scrollTo2019()">2019</a></li>
        <li><a (click)="scrollTo2020()">2020</a></li>
        <li><a (click)="scrollTo2021()">2021</a></li>
        <li><a (click)="scrollTo2022()">2022</a></li>

  
      </ul>
      <ul id="issues">
        <li #fstYear id="2019">
  
          <h1>1984</h1>
          <p>DKN Hotels founded with the acquisition of the Surf Motel Carlsbad, CA</p>
          <br>
          <p>Acquisition of the Marco Polo Inn Anaheim, CA (repositioned as: Days Inn)</p>
        </li>
  
        <li #scndYear id="2020">
          <h1>1987</h1>
          <p>Acquisition of the Park Vue Anaheim, CA (repositioned as: Econo Lodge)</p>
        </li>
        <li #trdYear id="2021">
  
          <h1>1991</h1>
          <p>Acquisition of the Quality Inn in Ontario, CA (repositioned as: Holiday Inn Express)</p>
          <br>
          <p>Construction of the Econo Lodge Flagstaff East, AZ (repositioned as: Days Inn)</p>
        </li>
        <li #frtYear id="2022">
  
          <h1>1992</h1>
          <p>Acquisition of the Sandman Inn Anaheim, CA (repositioned as: Ramada Limited)</p>
        </li>
      </ul>
      <div id="grad_left"></div>
      <div id="grad_right"></div>
      <a href="#" id="next">+</a>
      <a href="#" id="prev">-</a>
    </div>
  
  
  </body>