import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { faLongArrowAltLeft, faUser, faLock } from '@fortawesome/pro-light-svg-icons'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('loginP', { static: false }) loginP : ElementRef

  // Icons
  faLongArrowAltLeft = faLongArrowAltLeft
  faUser = faUser
  faLock = faLock

  loginForm: FormGroup
  loginWrong

  constructor(
    private sharedService: SharedService,
    private userService: UserService,
    private router: Router,
    private FormBuilder: FormBuilder,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) {
    sharedService.changePanel(false)
  }

  ngOnInit(): void {
    this.createLoginForm()
  }

  createLoginForm(){
    this.loginForm = this.FormBuilder.group({
      login: [null, Validators.required],
      password: [null, Validators.required]
    })

  }

  async login(form) {


    this.loginWrong = false

    const values = form.value
    const params = {
      email: values.login,
      password: values.password
    }

    const response = await this.userService.authenticate(params)
    if(response.status){
      this.animateLogin()

    } else {
      this.loginWrong = true
    }
  }
  logOut(){
    this.userService.logOut()
  }

  async log(type){

    let params = {
      email: '',
      password: '12345'
    }
    switch (type){
      case 1:
        params.email = 'fernandinho@fernandinho.com'
      break
      case 2 :
        params.email = 'fernando@fernandinho.com'

      break
      case 3:
        params.email = 'email123@email.com'
      break
    }


    const response = await this.userService.authenticate(params)
    if(response.status){
      this.animateLogin()
    } else {
      this.toastr.error('Usuario ou senha incorreto!', 'Erro!');
    }
  }

  animateLogin(){
    this.renderer.addClass(this.loginP.nativeElement, 'animate')
    setTimeout(()=>{
      this.redirect()
    }, 2000)
  }

  redirect(){
    this.router.navigate(['painel/bem-vindo'])
  }

}
