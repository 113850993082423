import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

interface Carrier {
  name: string
  url: string
}

@Component({
  selector: 'app-pic-carrier-slide',
  templateUrl: './pic-carrier-slide.component.html',
  styleUrls: ['./pic-carrier-slide.component.scss']
})
export class PicCarrierSlideComponent implements OnInit {

  carriers: Carrier[] = [
    {
      name: 'correios',
      url: 'https://www.correios.com.br/'
    },
    {
      name: 'jadlog',
      url: 'https://www.jadlog.com.br/jadlog/home'
    },
    {
      name: 'onlog',
      url: 'http://onlogexpress.com.br/'
    },
    {
      name: 'sequoia',
      url: 'https://www.sequoialog.com.br/'
    },
    {
      name: 'loggi',
      url: 'https://www.loggi.com/'
    },
    {
      name: 'gol',
      url: 'https://www.gollog.com.br/'
    },
    {
      name: 'azul',
      url: 'https://www.jadlog.com.br/jadlog/home'
    },
    {
      name: 'tnt',
      url: 'https://www.tnt.com/express/pt_br/site/home.html'
    }, 

    {
      name: 'rodojr',
      url: 'https://www.rodojr.com.br/'
    }, 
    {
      name: 'amazonas',
      url: 'https://amazonasexpress.com.br/'
    }, 

  ]   

  
  constructor() { }

  ngOnInit(): void {
  }

  customOptions: OwlOptions  = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 2000,
    dots: false,
    rewind: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }

}
