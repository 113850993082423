import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faWhatsapp, faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import { faMailbox, faEnvelope } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // Icons
  faFacebook = faFacebook
  faInstagram = faInstagram
  faTwitter = faTwitter
  faWhatsapp = faWhatsapp
  faMailbox = faMailbox
  faEnvelope = faEnvelope;

  // data control
  cardNices = ['mastercard', 'visa', 'elo', 'amex']

  constructor(
    public router: Router
  ) { 
    
  }

  ngOnInit(): void {
  }

}
