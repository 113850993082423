import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { API_Routes } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient
  ) { }

  getClients(id, search?, page?, rows?) {

    const params = {
      "userId": id,
      "search": search || '',
      "pageNumber": page || 1,
      "rowsPerPage": rows || 10
    }
    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/Client/GetClient`, params)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }
  getClientsByName(id, search?, page?, rows?) {
    const params = {
      "userId": id,
      "search": search || '',
      "pageNumber": page || 1,
      "rowsPerPage": rows || 10
    }
    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/Client/GetClientByName`, params)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }

  getClientsO(id, search?, page?, rows?): Observable<any> {
    const params = {
      "userId": id,
      "search": search || '',
      "pageNumber": page || 1,
      "rowsPerPage": rows || 10
    }

    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/Client/GetClient`, params)
  }
  getClientsByNameO(id, search?, page?, rows?): Observable<any> {
    const params = {
      "userId": id,
      "search": search || '',
      "pageNumber": page || 1,
      "rowsPerPage": rows || 10
    }

    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/Client/GetClientByName`, params)
  }

  getPaymentDeadline() {
    return this.http.get<any>(`${API_Routes.DATA_USER_API}/api/Client/GetPaymentDeadline`)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(e => console.error(e))
  }

  getDeadlineByClient(id) {

    return this.http.post<any>(`${API_Routes.DATA_ORDER_API}/api/Order/GetClientPaymentDead?idClient=${id}`, {})
      .toPromise()
      .then(response => {
        return response.data
      })
      .catch(e => console.error(e))

  }
  addClient(client) {
    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/Client`, client)
      .toPromise()
      .then(response => {

        return response
      }).catch(e => console.error(e))
  }

  editClient(client) {
    return this.http.put<any>(`${API_Routes.DATA_USER_API}/api/Client`, client)
      .toPromise()
      .then(response => {

        return response
      }).catch(e => console.error(e))
  }
}


