import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCalculatorAlt, faHandHoldingUsd, faMoneyBillAlt, faSearchLocation } from '@fortawesome/pro-light-svg-icons'
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/services/home.service';
import { ValidateCnpj } from 'src/app/services/validators/validate-cnpj.service';

@Component({
  selector: 'app-seja-parceiro',
  templateUrl: './seja-parceiro.component.html',
  styleUrls: ['./seja-parceiro.component.scss']
})
export class SejaParceiroComponent implements OnInit {

  // Icons
  faCalculatorAlt = faCalculatorAlt
  faHandHoldingUsd = faHandHoldingUsd
  faMoneyBillAlt = faMoneyBillAlt
  faSearchLocation = faSearchLocation

  partnerForm: FormGroup

  constructor(
    private formBuider: FormBuilder,
    private validateCnpj: ValidateCnpj,
    private homeService: HomeService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.createPartnerForm()
  }

  createPartnerForm() {
    this.partnerForm = this.formBuider.group({
      name: [null, Validators.required],
      cnpj: [null, [Validators.required, this.validateCNPJ.bind(this)]],
      phone: [null, Validators.required],
    })
  }

  validateCNPJ = (control: AbstractControl) => {
    if(control.value) {
        let cnpj = control.value
  
        if (cnpj) {
          const result = this.validateCnpj.validate(cnpj)
          return result ? null : { invalid: true }
        }
        return true;
      
    }
    
  }

  async buildForm(form){
    const params = {

    }
    const response : any = await this.homeService.bePartner(params)
    if(response.sucess) {
      this.toastr.success('Dentro de alguns dias entraremos em contato.', "Sucesso!")
    } else {
      this.toastr.error('Não foi possível concluir sua solicitação.', 'Erro!')
    }
  }

}
