<div class="d-flex truck-icon">
    <img class="icon-text" src="../../../assets/img/shipped.png">
    <h2>Alta<br><h3>Eficiência</h3></h2>
</div> 
<img class="w-100" src="../../../assets/img/about-us/Grupo 1740.png">
<div class="banner-text mb-3">

    <span>
        Nós da Pic envios temos um alto índice<br> de eficiência (comparado com o do mercado) em relação a entrega das suas mercadorias.
    </span>
</div>

<div class="d-flex truck-icon">
    <img class="icon-text mt-3" src="../../../assets/img/about-us/hand (1).png">
    <h2>Melhores<br><h3>preços</h3></h2>
</div> 
<img class="w-100" src="../../../../assets/img/about-us/girl-with-boxes.png">
<div class="banner-text mb-3">

    <span>
        Nossas cotações possuem os melhores<br> preços do mercado.
    </span>
</div>
<div class="d-flex truck-icon  w-70">
    <img class="icon-text mt-3" src="../../../assets/img/about-us/support.png">
    <h2>Integrações</h2>
</div> 
<div class="w-100 banner-text-transport">
    <app-pic-carrier-slide></app-pic-carrier-slide>
</div>




