<nav *ngIf="this.route != '/painel/minha-conta'" id="mySidebar" class=" side-menu-container sidebar" (mouseenter)="openNav(); showTag = true" (mouseleave)="closeNav(); showTag = false">
  <ul class="menu">
    <li class="side-menu-item" >
      <a class="menu-link" routerLink="painel/bem-vindo" >
        <div class="contaniner-icon">
          <div class="icon" >
            <fa-icon [icon]="faHouse"></fa-icon>
          </div>

        </div>
        <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
          Início
        </div>
      </a>
    </li>
    <!-- <li  class="side-menu-item">
        <div  class="menu-link" >
          <div class="contaniner-icon">
            <div class="icon ">
              <fa-icon [icon]="faUser"></fa-icon>
            </div>
          </div >
          <div   class="d-flex " [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
              <div> Minha Conta   </div>
          </div>
        </div>
     </li> -->

        <li class="side-menu-item" *ngIf="userInfo?.outpost != 1">
            <a routerLink="painel/cotacao" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Cotação
            </div>
            </a>
        </li>
        <li class="side-menu-item" *ngIf="userInfo?.typeUserId != 1 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6 && userInfo?.outpost != 1">
            <a routerLink="painel/atendimento" routerLinkActive="active"     [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faUserHeadset"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Atendimento
                </div>
            </a>
        </li>

        <li class="side-menu-item" *ngIf="userInfo?.outpost == 1 || userInfo?.typeUserId == 7">
                    <a routerLink="painel/cotacao-pa" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                        true}" class="menu-link">
                        <div class="contaniner-icon">
                          <div class="icon">
                              <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                          </div>
                        </div>
                        <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                       Cotação e Etiqueta
                       </div>
                    </a>
                </li>

        <li class="side-menu-item" *ngIf="userInfo?.typeUserId != 6">
            <a routerLink="/painel/envios" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                        true}" class="menu-link">
                        <div class="contaniner-icon">
                          <div class="icon">
                              <fa-icon [icon]="faBoxCheck"></fa-icon>
                          </div>
                        </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Meus Envios
                </div>

            </a>
        </li>
        <li class="side-menu-item" *ngIf="userInfo?.typeUserId == 5 || userInfo?.typeUserId == 1 && userInfo?.typeUserId != 6">
            <a routerLink="painel/minha-expedicao" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faShippingFast"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
               Minha Expedição
               </div>

            </a>
        </li>
        <li class="side-menu-item" *ngIf="userInfo?.outpost != 1 && userInfo?.typeUserId !=  5 && userInfo?.typeUserId != 6">
            <a routerLink="painel/clientes" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faUsers"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Meus Clientes
                </div>
                </a>
        </li>
        <li class="side-menu-item" *ngIf="userInfo?.outpost === 1 && userInfo?.typeUserId != 6">
            <a routerLink="painel/destinatarios" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faUsers"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Meus Destinatários
                </div>
            </a>
        </li>
        <li class="side-menu-item" *ngIf="userInfo?.typeUserId == 0 || userInfo?.typeUserId == 2 && userInfo?.typeUserId != 6">
            <a routerLink="painel/expedition" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faShippingFast"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Expedição
                </div>
            </a>
        </li>

        <li class="side-menu-item" *ngIf="userInfo?.typeUserId != 1 && userInfo?.typeUserId != 3  && userInfo?.outpost != 1 && userInfo?.typeUserId != 6">
            <a routerLink="painel/postagem" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faPallet"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Postagem
                </div>
            </a>
        </li>
        <li class="side-menu-item">
            <a routerLink="painel/rastreio" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon ">
                      <fa-icon [icon]="faMapMarker"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Rastreio
                </div>
            </a>
        </li>
        <li class="side-menu-item" *ngIf="userInfo?.typeUserId == 1 && userInfo?.outpost != 1 && userInfo?.typeUserId != 3 && userInfo?.typeUserId != 6" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a *ngIf="route != '/painel/minha-unidade'" routerLink="painel/minhas-unidades" routerLinkActive="active" class="menu-link">
              <div class="contaniner-icon">
                <div class="icon">
                      <fa-icon [icon]="faStore"></fa-icon>
                  </div>
              </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Minhas Unidades
                </div>
            </a>
            <a *ngIf="route == '/painel/minha-unidade' && userInfo?.typeUserId != 6" routerLink="painel/minha-unidade" routerLinkActive="active" class="menu-link">
              <div class="contaniner-icon">
                <div class="icon">
                      <fa-icon [icon]="faStore"></fa-icon>
                </div>
              </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Unidade
                </div>
            </a>
        </li>
        <!-- <li class="side-menu-item" *ngIf="userInfo?.outpost != 1 && userInfo?.typeUserId != 5">
            <a routerLink="painel/cofre" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="icon">
                    <fa-icon [icon]="faPiggyBank"></fa-icon>
                </div>
                Cofre
            </a>
        </li> -->
            <li class="side-menu-item" *ngIf="userInfo?.typeUserId == 1 && userInfo?.typeUserId != 6">
                <a routerLink="painel/listagem-pa" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                    true}" class="menu-link">
                    <div class="contaniner-icon">
                      <div class="icon">
                          <fa-icon [icon]="faPiggyBank"></fa-icon>
                      </div>
                    </div>
                    <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                    PA
                    </div>
                </a>
            </li>
        <!-- <li class="side-menu-item" *ngIf="userInfo?.outpost == 1 || userInfo?.typeUserId == 1">
            <a routerLink="painel/fatura" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="icon">
                    <fa-icon [icon]="faPiggyBank"></fa-icon>
                </div>
                Fatura
            </a>
        </li> -->
        <li class="side-menu-item" *ngIf="userInfo?.typeUserId != 1 &&  userInfo?.typeUserId != 3 && userInfo?.outpost != 1 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6">
            <a routerLink="painel/minha-unidade" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}" class="menu-link">
                <div class="contaniner-icon">
                  <div class="icon">
                      <fa-icon [icon]="faStore"></fa-icon>
                  </div>
                </div>
                <div [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
                Minha Unidade
                </div>
            </a>
        </li>

        <li class="side-menu-item log-out"  [ngStyle]="{'visibility': !showTag ? 'hidden' : 'visible'}">
            <a class="menu-link" (click)="logOut()">
                <div class="icon">
                    <fa-icon [icon]="faSignOutAlt"></fa-icon>
                </div>
                Sair
            </a>
        </li>
    </ul>
</nav>
