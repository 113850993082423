import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { ClientService } from 'src/app/services/client.service';
import { OrderService } from 'src/app/services/order.service';
import { ValidateCnpj } from 'src/app/services/validators/validate-cnpj.service';
import { ToastrService } from 'ngx-toastr';
import { ValidateCpf } from 'src/app/services/validators/validate-cpf.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {
  //decorators
  @ViewChild('cadClient', { static: false }) cadClient: ElementRef
  @Output() tabVisible = new EventEmitter();
  @Output() clientAdded = new EventEmitter();
  @Input() set _visible(v: any) {
    this.visible = v;
    v ? this.renderer.addClass(this.cadClient.nativeElement, 'visible') : null
  }
  @Input() set _userInfo(v: any) {
    this.userInfo = v;
  }

  // Icons
  faTimes = faTimes
  faUserPlus = faUserPlus

  //variables
  userInformation: any;
  isIe: boolean;
  userInfo
  addressData
  addressLock: boolean = false
  clientForm: FormGroup
  paymentDeadline
  visible = true
  mask
  doc = 'CNPJ'
  formUpdated = false
  paymentOption: any[] = [
    {
      name: 'PagéPay (Débito/Crédito)',
      selected: false,
      id: 3,
    },
    {
      name: 'Dinheiro',
      selected: false,
      id: 2,
    },
    {
      name: 'Faturado',
      selected: false,
      id: 1,
    }
  ]
  constructor(
    private FormBuilder: FormBuilder,
    private renderer: Renderer2,
    private validateCnpj: ValidateCnpj,
    private validadeCpf: ValidateCpf,
    private orderService: OrderService,
    private clientService: ClientService,
    private toastr: ToastrService,
    private actRoute: ActivatedRoute,
  ) {
    actRoute.data.subscribe(data => {
      this.userInformation = data.userInfo;
    })
  }

  ngOnInit(): void {
    this.createClientForm()
    this.getDeadline()
    this.clientForm.get('cep').valueChanges.subscribe(x => {
      x.length == 8 ? this.getAddress(x) : null
    });
  }

  createClientForm() {

    this.clientForm = this.FormBuilder.group({
      cnpj!: [null, [Validators.required, this.validateCNPJ.bind(this)]],
      razaoSocial: [null, [Validators.required]],
      nomeFantasia: [null, Validators.nullValidator],
      email: [null, Validators.nullValidator],
      telefone: [null, Validators.nullValidator],
      nomeLoja: [null, Validators.required],
      box: [null, Validators.nullValidator],
      cep: [null, Validators.required],
      numero: [null, Validators.required],
      endereco: [null, Validators.required],
      cidade: [null, Validators.required],
      uf: [null, Validators.required],
      bairro: [null, Validators.required],
      complemento: [null],
      stateRegistration: [null, Validators.required],
      responsavel: [null]

    })
  }
  forIe() {
    this.isIe = true;
    const ie = document.getElementById('stateRegistration') as HTMLInputElement;
    if (this.isIe == true || this.doc == 'CPF') {
      ie.value = 'Isento';
    }
  }

  clearCheckIe() {
    this.isIe = false;
    const ie = document.getElementById('isIe') as HTMLInputElement;
    const state = document.getElementById('stateRegistration') as HTMLInputElement;
    this.clientForm.value.stateRegistration = null
    if (this.isIe == false) {
      ie.checked = false;
      state.value = null
    }
  }

  get paymentOptions() {
    return (this.clientForm.get('paymentOption') as FormArray).controls
  }

  buildOptions() {
    const arr = this.paymentDeadline.map(option => this.FormBuilder.control(option))
    return this.FormBuilder.array(arr)
  }

  closeTab() {
    this.renderer.removeClass(this.cadClient.nativeElement, 'visible')
    this.tabVisible.emit()
  }

  async getDeadline() {
    const response = await this.clientService.getPaymentDeadline()
    if (response.sucess) {
      this.paymentDeadline = response.data

      this.clientForm = this.FormBuilder.group({
        ...this.clientForm.controls,
        paymentOption: this.buildOptions(),
      })
      this.formUpdated = true
    }
  }


  validateCNPJ = (control: AbstractControl) => {

    if (control.value) {
      if (this.doc == 'CNPJ') {
        this.mask = '00.000.000/0000-00'
        let cnpj = control.value

        if (cnpj) {
          const result = this.validateCnpj.validate(cnpj)
          return result ? null : { invalid: true }
        }
        return true;
      } else if (this.doc == 'CPF') {
        this.mask = '000.000.000-00'

        let cpf = control.value
        if (cpf) {
          const result = this.validadeCpf.validate(cpf)
          return result ? null : { invalid: true }
        }
        return true
      } else {
        this.mask = '00.000.000/0000-00'
      }
    }

  }

  isIncorrect(field: string) {
    const f = this.clientForm.get(field)
    return f.errors && (f.dirty || f.touched) ? true : false
  }

  async addClient(form) {

    let billed: any = document.getElementsByName('billed')
    let paymentCondition = []
    billed.forEach(v => {
      paymentCondition.push(v)
    })
    let paymentConditionId = paymentCondition.find(v => v.checked)
    if (paymentConditionId < 0 || paymentConditionId == undefined) {
      this.toastr.warning('Selecione um metódo de pagamento.')
      return false
    }

    //avisos de form inválido
    if (form.value.nomeLoja == null) {
      this.toastr.warning('Informe o nome.');
      return false
    }
    if (form.value.cnpj == null) {
      this.toastr.warning('Informe o documento.');
      return false
    }
    if (form.value.endereco == null) {
      this.toastr.warning('Informe  o Endereço');
      return false
    }
    if (form.value.cep == null) {
      this.toastr.warning('Informe o CEP');
      return false
    }
    if (form.value.numero == null) {
      this.toastr.warning('Informe o número');
      return false
    }


    const values = form.value

    const params = {
      "name": values.nomeLoja,
      "documentNumber": values.cnpj,
      "email": values.email,
      'stateRegistration': values.stateRegistration,
      "razaoSocial": values.razaoSocial,
      "box": values.box,
      "phone": values.telefone,
      "typeClient": 2,
      "userId": this.userInformation.id,
      "paymentConditionId": paymentConditionId.id,
      "city": values.cidade || '',
      "uf": values.uf || '',
      "address": values.endereco,
      "zipCode": values.cep,
      "nameSender": values.responsavel || null,
      "neighborhood": values.bairro || '',
      "complement": values.complemento,
      "number": values.numero,
      "enabled": true
    }
    const response: any = await this.clientService.addClient(params)
    if (response.sucess) {
      this.toastr.success('Cliente cadastrado!', 'Sucesso!')
      this.clientAdded.emit(response.data)
      this.closeTab()
      localStorage.setItem('client', JSON.stringify(params))
    } else if (response.message) {
      this.toastr.error(response.message, 'Erro!')
    } else {
      this.toastr.error('Erro ao cadastrar cliente!', 'Erro!')
    }

  }

  async getAddress(x) {
    let cep = x
    const response: any = await this.orderService.getAddress(cep)

    if (response?.sucess) {
      const values = response.data
      this.addressLock = true
      this.addressData = {
        address: values.address,
        city: values.city,
        neighborhood: values.neighborhood,
        state: values.state,
      }
      this.clientForm.patchValue({
        endereco: values.address,
        bairro: values.neighborhood,
        cidade: values.city,
        uf: values.state,
      })
    }
  }
  followField(){

    this.focoInput()

  }


  focoInput() {
    this.renderer.selectRootElement('#documento').focus();
  }

}
