<section>
<h1>Números da PIC envios</h1>

<div class="grid">
    <div class="item">+1.1 mil clientes fidelizados</div>
	<div class="item">+166 mil objetos enviados</div>
	<div class="item">Mais de 20 parceiros homologados</div>
	<div class="item">Alta eficiência nas entregas</div>
</div>
<div class="container-btn">
    <a class="btn btn-lg btn-orange" href="seja-um-franqueado">Seja um franqueado</a>
</div>
</section>