<section class="page home-banner">
    <div class="wrapper " style="padding-top: 42vh;">
            <h1 class="home-title">
                A 1º franquia de hub logístico em soluções de encomendas para e-commerce
            </h1>
            <div class="container-btn">
                <a class="btn btn-lg btn-orange" href="seja-um-franqueado">Seja um franqueado</a>
            </div>
        </div>
</section>
