import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { API_Routes } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  host = window.location.hostname
  constructor(
    private cookieService: CookieService
  ) { }

  add(name, value) {
    try {
      this.cookieService.set(name, value, 2, '/', this.host, false, 'Strict')
    }catch(e){
      console.error(e)
    }
  }

  get(name):any {
    return this.cookieService.get(name)
  }

  remove(name) {
    this.cookieService.delete(name, '/', this.host)
  }
}
