import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { ClientService } from 'src/app/services/client.service';
import { OrderService } from 'src/app/services/order.service';
import { ValidateCnpj } from 'src/app/services/validators/validate-cnpj.service';
import { ToastrService } from 'ngx-toastr';
import { ValidateCpf } from 'src/app/services/validators/validate-cpf.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-finish-user',
  templateUrl: './finish-user.component.html',
  styleUrls: ['./finish-user.component.scss']
})
export class FinishUserComponent implements OnInit {

  // Icons
  faTimes = faTimes
  faUserPlus = faUserPlus

  @ViewChild('finishUser', { static: false }) finishUser: ElementRef

  @Output() tabVisible = new EventEmitter();

  @Output() clientAdded = new EventEmitter();
  userInformation: any;
  isIe: boolean;

  @Input() set _visible(v: any) {
    this.visible = v;
    v ? this.renderer.addClass(this.finishUser.nativeElement, 'visible') : null
  }

  @Input() set _userInfo(v: any) {
    this.userInfo = v;
  }

  userInfo
  addressData
  clientForm: FormGroup
  paymentDeadline
  visible = true
  mask
  doc = 'CNPJ'
  formUpdated = false
  paymentOption: any[] = [
    {
      name: 'PagéPay (Débito/Crédito)',
      selected: false,
      id: 3,
    },
    {
      name: 'Dinheiro',
      selected: false,
      id: 2,
    },
    {
      name: 'Faturado',
      selected: false,
      id: 1,
    }
  ]
  constructor(
    private FormBuilder: FormBuilder,
    private renderer: Renderer2,
    private validateCnpj: ValidateCnpj,
    private validadeCpf: ValidateCpf,
    private orderService: OrderService,
    private userService: UserService,
    private toastr: ToastrService,
    private actRoute: ActivatedRoute,
  ) {
    actRoute.data.subscribe(data => {
      this.userInformation = data.userInfo;
    })
  }

  ngOnInit(): void {

    this.createClientForm()
    this.clientForm.get('cep').valueChanges.subscribe(x => {
      x.length == 8 ? this.getAddress(x) : null
    });


  }

  createClientForm() {
    this.clientForm = this.FormBuilder.group({
      documentNumber: [this.userInfo.documentNumber != null ? this.userInfo.documentNumber : null, [this.validateCNPJ.bind(this)], Validators.required],
      nome: [this.userInfo.name != null ? this.userInfo.name : null, Validators.required],
      telefone: [null, Validators.nullValidator],
      cep: [null, Validators.required],
      numero: [null, Validators.required],
      endereco: [null, Validators.required],
      cidade: [null, Validators.required],
      uf: [null, Validators.required],
      bairro: [null, Validators.required],
      complemento: [null],
      email: [this.userInfo.email != null ? this.userInfo.email : null, Validators.required]
    })
  }


  closeTab() {
    this.renderer.removeClass(this.finishUser.nativeElement, 'visible')
    this.tabVisible.emit()
  }

  validateCNPJ = (control: AbstractControl) => {
    if (control.value) {
      if (this.doc == 'CNPJ') {
        this.mask = '00.000.000/0000-00'
        let cnpj = control.value

        if (cnpj) {
          const result = this.validateCnpj.validate(cnpj)
          return result ? null : { invalid: true }
        }
        return true;
      } else if (this.doc == 'CPF') {
        this.mask = '000.000.000-00'

        let cpf = control.value
        if (cpf) {
          const result = this.validadeCpf.validate(cpf)
          return result ? null : { invalid: true }
        }
        return true
      } else {
        this.mask = '00.000.000/0000-00'
      }
    }

  }

  isIncorrect(field: string) {
    const f = this.clientForm.get(field)
    return f.errors && (f.dirty || f.touched) ? true : false
  }

  async finishClient(form) {
    if (form.value.endereco == null) {
      this.toastr.warning('Verifique o Endereço');
    }
    if (form.value.cep == null) {
      this.toastr.warning('Verifique o CEP');
    }
    if (form.value.numero == null) {
      this.toastr.warning('Verifique o número');
    }
    const values = form.value

    const params = {
      "id": this.userInfo.id,
      "name": values.nome,
      "documentNumber": values.documentNumber,
      "unitId": 0,
      "typeUserId": 7,
      "updatedAt": "2021-11-05T14:49:29.471Z",
      "enabled": true,
      "email": values.email,
      "userId": this.userInfo.id,
      "zipCode": values.cep,
      "address": values.endereco,
      "neighborhood": values.bairro,
      "city": values.cidade,
      "uf": values.uf,
      "complement": values.complemento,
      "number": values.numero,
      "phone": values.telefone,
      "shortAddress": values.endereco + ', ' + values.numero,
      "outpost": 1,
      "clientId": 0,
      "paymentDeadlineId": 3,
      "payTypeId": 8,
      "freightTypeId": 0,
      "password": this.userInfo.password
    }
    const response: any = await this.userService.updateCotacao(params)
    if (response.sucess) {
      this.toastr.success('Cliente cadastrado!', 'Sucesso!');
      this.clientAdded.emit(response.data)
      this.closeTab()
      location.reload()
    } else if (response.message) {
      this.toastr.error(response.message, 'Erro!');
    } else {
      this.toastr.error('Erro ao cadastrar cliente!', 'Erro!');
    }

  }

  async getAddress(x) {
    let cep = x
    const response: any = await this.orderService.getAddress(cep)

    if (response?.sucess) {
      const values = response.data
      this.addressData = {
        address: values.address,
        city: values.city,
        neighborhood: values.neighborhood,
        state: values.state,
      }
      this.clientForm.patchValue({
        endereco: values.address,
        bairro: values.neighborhood,
        cidade: values.city,
        uf: values.state,
      })
    }
  }

}

