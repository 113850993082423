<div class="work-with-us-page page page-container my-5">
    <h1 class="title">
        Trabalhe conosco
    </h1>
    <div class="text-container text-gray mt-3">
        <div class="col-12 px-0">
            <p>
                Somos uma plataforma digital do Pagé Group Participações Ltda. e respondemos pelas marcas PagéPay, PagéExpress, PagéMarketPlace, conectando pessoas aos mais diversos produtos e serviços.
            </p>
            <p>
                Investimos na valorização e formação de novos líderes, trabalhamos na retenção de talentos e aprimoramento dos processos de contratação de novos profissionais, oferecemos remuneração equilibrada e diversos benefícios. Se você tem espírito inovador e busca oportunidades para desenvolver seu potencial em uma empresa de crescimento sólido, envie-nos seu currículo. Ele será imediatamente inserido em nosso banco de dados, aguardando possíveis oportunidades.
            </p>
            <p>
                Envie para: <a href="mailto:contato@pageexpress.com.br" target="_blank">contato@pageexpress.com.br</a>
            </p>
            <p>
                Buscamos resultados, inovação e trabalho de forma colaborativa. Faça parte de nossa história!
            </p>
            <p class="mb-0">
                <b>Junte-se a Nós!</b>
            </p>
        </div>
    </div>
</div>