import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faLongArrowAltLeft, faUser, faLock, faHome } from '@fortawesome/pro-light-svg-icons'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { UnitService } from 'src/app/services/unit.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cad-cotacao',
  templateUrl: './cad-cotacao.component.html',
  styleUrls: ['./cad-cotacao.component.scss']
})
export class CadCotacaoComponent implements OnInit {
  @ViewChild('cadCot', { static: false }) cadCot: ElementRef


  //icons
  faLongArrowAltLeft = faLongArrowAltLeft
  faUser = faUser
  faHome = faHome

  activeUser
  userInfo
  emailValido: boolean = false
  filledData: boolean = false
  newUserForm: FormGroup
  activeUnit = this.sharedService._unitActive
  isCad: boolean;

  constructor(
    private FormBuilder: FormBuilder,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private unitService: UnitService,
    private userService: UserService
  ) {
    this.userInfo = this.userService.getUserData()
  }

  ngOnInit(): void {
    this.createUserForm()
    this.isCad = false
  }

  createUserForm() {

    this.newUserForm = this.FormBuilder.group({
      name: [null, Validators.nullValidator],
      cpf: [null, Validators.required],
      email: [null, Validators.nullValidator],
      password: [null, Validators.nullValidator]
    })
  }

  async addUser(form) {


    const values = form.value

    this. checksData(values)


    if(this.filledData == true ){

      this.validaEmail(values.email)

      if(this.emailValido == true){




         const params = {
            "name": values.name,
            "documentNumber": values.cpf,
            "typeUserId": 6,
            "enabled": true,
            "email": values.email,
            "login": {
              "userName": values.email,
              "email": values.email,
              "password": values.password,
              "enabled": true
              }
          }

              const response = await this.unitService.createUser(params)

              if (response.sucess) {
                this.isCad = true

              } else {
                this.toastr.error(response.message, 'Erro')
              }


      }
    }



  }
  validaEmail(a){


    let validPoint = false
     const emailComplete =  a.split('@');
     const usuario = emailComplete[0];
     const dominio = emailComplete[1];
     validPoint =  dominio?.includes(".");
     const validaSpace = a.includes(" ")


     if (usuario.length >= 3 &&  dominio?.length >=3  && validPoint == true  &&  validaSpace == false){

     return this.emailValido = true
    }else{
      this.filledData = false
      this.emailValido = false
      this.toastr.error(null, 'Email inválido.', {
        positionClass: 'toast-top-right',
        timeOut: 5000,



      });
    }


  }


  checksData(values){


    if((values.name != null && values.name != " " ) && (values.cpf != null ) && values.email != null  && values.password !=  null ){
      return this.filledData = true
    }else{
      this.filledData = false
      this.toastr.error(null, 'Preencha os campos obrigatórios.', {
        positionClass: 'toast-top-right',
        timeOut: 5000,
      });
    }



  }


}
