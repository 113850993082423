<div class="about-page-container page mt-4 mb-5">
    <h1 class="title">
        Sobre nós
    </h1>
    <div class="text-container text-gray mt-4">
        <div class="col-12 row px-0 about-us-data">
            <div class="col-md-3 col-sm-12 pl-0 carousel">
                <owl-carousel-o [options]="dailyOffersCarousel" class="default-carousel about-us-carousel">
                    <ng-container *ngFor="let number of [1,2,3]; let i = index">
                        <ng-template carouselSlide [id]="'dailyOffer-' + i">
                            <img [src]="'assets/img/about-us/about_' + number + '.jpg'" alt="Imagens do prédio da galeria pagé">
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="col-md-9 col-sm-12 pr-0 text">
                <p class="mb-3 title">Tenha todos os benefícios da Galeria Pagé na palma da sua mão!</p>
                <p class="text">
                    Considerada o primeiro shopping de São Paulo, a Galeria Pagé está instalada desde 1963 em um dos pontos mais visitados da cidade, a
                    região da rua 25 de Março. Conhecida nacionalmente como o “Mundo encantado das compras e dos eletrônicos”, a Galeria Pagé é a maior referência
                    em comércio popular do país, agora lança o mais completo marketplace para vendas online, reunindo ofertas e condições incríveis para os mais
                    diversos produtos. Todos os meses a galeria recebe em torno de 1 milhão de pessoas, na sua maioria atacadistas de todo o Brasil, além do
                    público varejista que visita a região central da cidade de São Paulo. Seu prédio de 12 andares conta com cerca de 170 lojas repletas de produtos,
                    que vão desde artigos eletrônicos, acessórios para celular, perfumaria e cosméticos, aos mais diversos itens importados, que agora estarão disponíveis
                    de modo prático e seguro na palma da sua mão.
                </p>
            </div>
        </div>
    </div>
</div>