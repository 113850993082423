

<div class="wrapper">
  <div class="carriers-slide">
    <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let unit of unityAdd">
          <ng-template carouselSlide>
            <div class="card" style="width: 25rem; height: 38vh;">
              <iframe [src]="unit.map | safeUrl"  style="border:0;" allowfullscreen=""  referrerpolicy="no-referrer-when-downgrade"></iframe>
              <div class="card-body">
                <h5 class="card-title">{{unit.name}}</h5>
                <p class="card-text p-0">{{unit.address}}</p>
              </div>
            </div>
          </ng-template>
        </ng-container>
     
      </owl-carousel-o>
  </div>
</div>