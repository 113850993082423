import { MyAccountComponent } from './components/my-account/my-account.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { TermosComponent } from './pages/institutional/termos/termos.component';
import { WorkWithUsComponent } from './pages/institutional/work-with-us/work-with-us.component';
import { AboutComponent } from './pages/institutional/about/about.component';
import { AuthGuardService } from './services/auth-guard.service';
import { SejaParceiroComponent } from './pages/seja-parceiro/seja-parceiro.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CadCotacaoComponent } from './pages/cad-cotacao/cad-cotacao.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FranchisingComponent } from './pages/franchising/franchising.component';
import { BeAFranchiseeComponent } from './pages/be-a-franchisee/be-a-franchisee.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'termos-de-uso', component: TermosComponent },
  { path: 'trabalhe-conosco', component: WorkWithUsComponent },
  { path: 'quem-somos', component: AboutComponent },
  { path: 'seja-parceiro', component: SejaParceiroComponent },
  { path: 'cadastre-se', component: CadCotacaoComponent },
  { path: 'seja-um-franqueado', component: FranchisingComponent },
  { path: 'cadastro-franqueado', component: BeAFranchiseeComponent },
  { path: 'painel', loadChildren: () => import('./painel/painel.module').then(m => m.PainelModule), canActivate: [AuthGuardService] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
