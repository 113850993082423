
<div class="fixed-head">
    <div class="wrapper">
        <div class="logo-box">
            <div class="logo logo-classic" aria-label="Logotipo Express" routerLink=""
                [inlineSVG]="'assets/svg/Logo_FundoEscuro_SVG.svg'" style="display: block;"></div>
        </div>
  
            <nav class="nav-menu" >
                <ul class="menu">
                    <li *ngIf="userSigned" class="menu-item box-submenu" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseOver($event)" >
                        <div class="menu-item-content">
                            <div class="user-icon">
                                <div class="icon">
                                    <fa-icon [icon]="faUserCircle"></fa-icon>
                                </div>
                            </div>
                            <a class="menu-link">Olá, <b>{{ userInfo?.name }}</b>!</a>
                            <div class="submenu-toggler">    
                                <div class="icon">
                                    <fa-icon [class.toggler-up]="submenuVisible" [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <ul [class.active]="submenuVisible" class="submenu" >
                            <li class="menu-item" *ngIf="userInfo.outpost != 1">
                                <a [routerLink]="'painel/cotacao'" class="submenu-link">
                                    <div class="icon">
                                        <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                                    </div>
                                    Cotação
                                </a>
                            </li>
                         </ul>
                    <li *ngIf="!userSigned" [routerLink]="['login']" class="menu-item"><a class="menu-link">Login</a></li>
                    <li *ngIf="!userSigned" [routerLink]="['cadastre-se']" class="menu-item"><a class="menu-link">Cadastre-se</a></li>
                </ul>
            </nav>
        </div>