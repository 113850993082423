<section #cadCot class="login">
    <div class="page login-banner">
        <div class="wrapper">
            <div class="page-banner" aria-label="Page Banner" [inlineSVG]="'assets/svg/Icone_Branco_SVG.svg'"></div>
            <div class="banner-content">
                <p>Bem-vindo ao</p>
                <h2 class="text-secondary">Pic Envios!</h2>
            </div>

        </div>
    </div>
    <div class="page login-form" *ngIf="!isCad">
        <div class="login-container">
            <span class="back-page" [routerLink]="['/']">
                <div class="icon">
                    <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
                </div>
                Voltar para home
            </span>
            <div class="login-card shadow">
                <h2 class="mb-3">Cadastre-se</h2>
                <form [formGroup]="newUserForm" (ngSubmit)="addUser(newUserForm)">
                    <div class="form-field">
                        <label>Nome*</label>
                        <input formControlName="name" type="text" required>
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
                    <div class="form-field mt-2">
                        <label>CPF/CNPJ*</label>
                        <input formControlName="cpf" mask="CPF_CNPJ" type="text" required>
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
                    <div class="form-field mt-2">
                        <label>E-mail*</label>
                        <input formControlName="email" type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required >
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
                    <div class="form-field">
                        <label>Senha*</label>
                        <input type="password" formControlName="password" autocomplete="new-password" required>
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
                    <button type="submit" class="btn btn-lg btn-secondary mt-5">Entrar</button>
                </form>
            </div>
        </div>
    </div>

    <div class="page login-form" *ngIf="isCad">
        <div class="login-container">
            <div class="login-card shadow">
                <h2 class="mb-3 text-secondary" style="text-align: center;">Cadastro concluído com sucesso!</h2>
                <p style="text-align: center;">O que deseja fazer agora?</p>
                <div class="row" style="margin-top: 40px;">
                    <button class="btn btn-scndr mx-auto my-3 d-block" [routerLink]="['/login']">
                        <div class="row">
                        <div class="icon">
                            <fa-icon [icon]="faUser" style="padding-right: 15px;"></fa-icon>
                        </div>
                        Ir para Login
                    </div></button>
        
                    <button class="btn mx-auto my-3 d-block" [routerLink]="['/home']">
                        <div class="row" style="padding: 0 10px 0 2px;">
                        <div class="icon" style="padding-right: 15px;">
                            <fa-icon [icon]="faHome"></fa-icon>
                        </div>
                        Voltar para a home
                    </div>
                </button>
        
                 </div>
              
                </div>
            </div>
        </div>

</section>