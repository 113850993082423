import { Component, ElementRef, Renderer2, HostListener, OnInit, ViewChild } from '@angular/core';
import { faLongArrowAltLeft,faCalculatorAlt, faBoxCheck, faUsers, faSignOutAlt, faUserTie, faPiggyBank, faPallet, faUserCrown, faMapMarker,  faMapMarkedAlt, faMapMarkerSmile, faHandHoldingBox, faSearchLocation, IconDefinition, faFileDownload, faTruckLoading, faShippingFast, faBan, faHistory, faBoxHeart, faChevronDown, faUserCircle
} from '@fortawesome/pro-light-svg-icons';
import {} from '@fortawesome/pro-light-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeService } from 'src/app/services/home.service';
import { OrderService } from 'src/app/services/order.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { Router }  from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})


export class LandingPageComponent implements OnInit {
  @ViewChild('cotacao') cotacao: ElementRef;
  @ViewChild('rastreio') rastreio: ElementRef;


  @ViewChild('header', { static: false }) header: ElementRef
  route: string;


  @HostListener("window:scroll", [])
  onWindowScroll() {

    const scroll = window.scrollY
    if(this.router.url != '/login'){

      if(scroll > 0 && !this.headVisible ){
        this.renderer.addClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(true)
      } else if(!this.isPanel && scroll <= 0) {
        this.renderer.removeClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(false)
      }
    }
    if(this.router.url != '/cadastre-se'){

      if(scroll > 0 && !this.headVisible ){
        this.renderer.addClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(true)
      } else if(!this.isPanel && scroll <= 0) {
        this.renderer.removeClass(this.html, 'scroll')
        this.sharedService.changeHeadVisible(false)
      }
    }
  }
  //Icons

  faLongArrowAltLeft = faLongArrowAltLeft;
  faMapMarkedAlt = faMapMarkedAlt;
  faMapMarkerSmile = faMapMarkerSmile;
  faHandHoldingBox = faHandHoldingBox;
  faSearchLocation = faSearchLocation;
  faFileDownload = faFileDownload;
  faCalculatorAlt = faCalculatorAlt;
  faChevronDown = faChevronDown;
  faUserCircle = faUserCircle;
  faBoxCheck = faBoxCheck
  faUsers = faUsers
  faSignOutAlt = faSignOutAlt
  faUserTie = faUserTie
  faPiggyBank = faPiggyBank
  faPallet = faPallet
  faShippingFast = faShippingFast
  faUserCrown = faUserCrown
  faMapMarker = faMapMarker

  isTrack: boolean = true;
  isLoading = false;
  trackNotFound = false;
  trackCode;
  trackForm: FormGroup;
  trackList;
  message = '';
  userSigned: any;
  userInfo: any;
  submenuVisible: boolean;
  isPanel = this.sharedService._isPanel
  headVisible: boolean = false
  html = document.getElementById('scroll')

  constructor(
    private homeService: HomeService,
    private orderService: OrderService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private router: Router,
    private userService: UserService
  ) {
    sharedService.userSigned.subscribe(v => {

      this.userSigned = v
      this.userInfo = v ? this.userService.getUserData() : undefined
    })

    sharedService.userSigned.subscribe(v => {


      this.userInfo = v ? this.userService.getUserData() : undefined
    })
    sharedService.isPanel.subscribe(v => {
      this.isPanel = v
    })
    sharedService.headVisible.subscribe(v => {
      this.headVisible = v
    })
    router.events.subscribe(r  => {
      this.submenuVisible = false
    })

  }

  ngOnInit() {
    this.createTrackForm();
  }


  createTrackForm() {
    this.trackForm = this.formBuilder.group({
      trackCode: [null, Validators.required]
    })
  }
  async trackDeliver(form) {
    const value = form.value
    const response = await this.homeService.getTracking(value.trackCode)

    this.trackCode = value.trackCode

    if (!response.sucess || !response.data) {
      this.trackNotFound = true
      this.message = response.message
    } else {
      this.trackNotFound = false
      this.trackList = response.data.tracking
    }

    this.isTrack = !this.isTrack
  }

  scrollToCalc() {
    this.cotacao.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" })
  }
  scrollTracking() {
    this.rastreio.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" })
  }

  getTrackIcon(status): IconDefinition {
    let icon: IconDefinition
    switch (status) {
      case 1:
        icon = faHandHoldingBox
        break
      case 2:
        icon = faTruckLoading
        break
      case 3:
        icon = faShippingFast
        break
      case 4:
        icon = faBan
        break
      case 5:
        icon = faHistory
        break
      case 6:
        icon = faBoxHeart
        break
    }

    return icon
  }

  async downloadTracking() {
    const trackCode = this.trackCode

    const response: any = await this.orderService.downloadTracking(trackCode)


    if (!response.sucess) {
      this.toastr.error('Não foi possivel fazer o download!', 'Erro!');
    }
  }


  logOut() {

    this.sharedService.changeUserSigned(false)
    this.userService.logOut()
    localStorage.removeItem('dados')
    localStorage.removeItem('dc')
  }


  mouseEnter(event){
    this.submenuVisible = true
  }
  mouseOver(event){
    this.submenuVisible = false
  }
}
