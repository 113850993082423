import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { faCalculatorAlt, faChevronDown, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { LandingPageComponent } from 'src/app/pages/landing-page/landing-page.component';

@Component({
  selector: 'app-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss']
})
export class HeaderLandingComponent implements OnInit {
  @ViewChild('header', { static: false }) header: ElementRef;


  //icons
  // faMapLocationDot = faMapLocationDot;
  faCalculatorAlt = faCalculatorAlt;
  faChevronDown = faChevronDown;
  faUserCircle = faUserCircle;

  isPanel = this.sharedService._isPanel
  html = document.getElementById('scroll')
  headVisible: boolean = false
  submenuVisible: boolean = false;
  userInfo;
  userSigned = false


  constructor(
    private router: Router,
    private renderer: Renderer2,
    private sharedService: SharedService,
    private userService: UserService,
    private landingPageComp: LandingPageComponent
  ) {
    sharedService.userSigned.subscribe(v => {
      this.userSigned = v
      this.userInfo = v ? this.userService.getUserData() : undefined
    })
    sharedService.headVisible.subscribe(v => {
      this.headVisible = v
    })
  }

  ngOnInit(): void {
  }

  
  mouseEnter(event){
    this.submenuVisible = true
  }
  mouseOver(event){
    this.submenuVisible = false
  }


}
