<section class="page home-banner">
    <div class="wrapper "  style="padding-top: 33vh;">

            <h1 class="home-title">Vocês vendem...</h1>
            <h1 class="home-title-orange">Nós cuidamos do envio...</h1>
            <h1 class="home-title">Eles recebem em casa!</h1>
            <span class="home-subtitle">
                Somos uma empresa de <strong>hub logístico</strong> voltado para <strong>envio de
                    encomendas</strong>, que tem como visão auxiliar nos envios de seus produtos.
            </span>
            <div class="container-btn">
                <a class="btn btn-lg btn-orange" href="area-do-cliente" target="_blank">Área do
                    Cliente</a>
            </div>
        </div>
</section>
