import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {


  _isPanel = false
  _userSigned = false
  _headVisible = false
  _unitActive
  _calc
  _volume
  _mountCalc

  _currentOrder
  _mktpSend



  constructor() {

   }
  // Observable to track current route

  public trackCurrentRoute = new Subject<any>();
  routeChange = this.trackCurrentRoute.asObservable();


  public trackPanel = new Subject<any>();

  // Save the route change
  isPanel = this.trackPanel.asObservable();

  // Emit the current route to subscribers
  changePanel(panel: any) {
    this.trackPanel.next(panel);
    this._isPanel = panel
  }

  // Observable to track current route
  public trackUserSigned = new Subject<any>();

  // Save the route change

  userSigned = this.trackUserSigned.asObservable();


  // Emit the current route to subscribers
  changeUserSigned(userInfo: any) {

    this.trackUserSigned.next(userInfo);
    this._userSigned = userInfo
    if (userInfo == false){
      localStorage.clear()
    }
  }

  // Observable to track if the header is visible
  public trackheadVisible = new Subject<any>();

  headVisible = this.trackheadVisible.asObservable();

  // Emit the current head state to subscribers
  changeHeadVisible(_headVisible: any) {
    this.trackheadVisible.next(_headVisible);
    this._headVisible = _headVisible
  }


  // Observable to track if the header is visible
  public trackUnitActive = new Subject<any>();

  unitActive = this.trackUnitActive.asObservable();

  // Emit the current head state to subscribers

  changeUnitActive(_unitActive: any) {
    this.trackUnitActive.next(_unitActive);
    this._unitActive = _unitActive
  }

  // Observable to track if there is new calc
  public trackCalc = new Subject<any>();

  calc = this.trackCalc.asObservable();

  // Emit the current head state to subscribers
  changeCalc(_calc: any) {
    this.trackCalc.next(_calc);
    this._calc = _calc
  }

  // Observable to add volume to calc
  public trackVolume = new Subject<any>();

  volume = this.trackVolume.asObservable();

  // Emit the current head state to subscribers
  changeVolume(_volume: any) {
    this.trackVolume.next(_volume);
    this._volume = _volume
  }

  // Observable to mount calc
  public trackMountCalc = new Subject<any>();

  mountCalc = this.trackMountCalc.asObservable();

  // Emit the current head state to subscribers
  changeMountCalc(_mountCalc: any) {
    this.trackMountCalc.next(_mountCalc);
    this._mountCalc = _mountCalc
  }


  // Observable to transfer order between components
  public trackCurrentOrder = new Subject<any>();

  currentOrder = this.trackCurrentOrder.asObservable();

  // Emit the current head state to subscribers
  changeCurrentOrder(_currentOrder: any) {
    this.trackCurrentOrder.next(_currentOrder);
    this._currentOrder = _currentOrder
  }


  public trackMKTPSend = new Subject<any>();

  MKTPSend = this.trackMKTPSend.asObservable();

  changeMKTPSend(_mktpSend: any) {
    this.trackMKTPSend.next(_mktpSend)
    this._mktpSend = _mktpSend
  }

  emitRoute(currentRoute: any) {
    this.trackCurrentRoute.next(currentRoute);
  }
}
