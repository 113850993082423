// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};


export const API_Routes = {
  DATA_API: 'http://apihomexpress.picenvios.com.br',
  DATA_USER_API: 'http://api.hom.user.picenvios.com.br',
  DATA_ORDER_API: 'http://api.hom.order.picenvios.com.br',
  DOMAIN: 'localhost',
  SITE_URL: 'http://hom.picenvios.com.br'
};

// export const API_Routes = {
//   DATA_API: 'https://apihomexpress.pageexpress.com.br',
//   DATA_USER_API: 'https://apiuser.pageexpress.com.br',
//   DATA_ORDER_API: 'https://apiorder.pageexpress.com.br',
//   DOMAIN: 'localhost',
//   SITE_URL: 'https://pageexpress.com.br'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
