import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }


  addDays = (data, days) => {
    var date = new Date(data.valueOf());
    date.setDate(date.getDate() + days);

    return this.formatDate(date, 1);
  }

  dateLess(date, days) {

    var result = new Date(date);
    result.setDate(result.getDate() - days);

    const finalDate = this.formatDate(result);
    return finalDate
  }

  formatDate(date, type?) {
    let today = new Date(date)
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    // let todayFormated = dd + '/' + mm + '/' + yyyy;
    let todayFormated = type ? dd + '/' + mm + '/' + yyyy : yyyy + '-' + mm + '-' + dd;
    return todayFormated
  }

  dayNMonth(date) {
    let today = new Date(date)

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!

    let todayFormated = dd + '/' + mm
    return todayFormated

  }

  getDateFromDays(date, days) {
    let today = new Date(date)

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    let dateFormated = dd + '/' + mm + '/' + yyyy;
    return dateFormated
  }

  getLabelName() {
    let dateT = new Date()

    var dd = String(dateT.getDate()).padStart(2, '0');
    var mm = String(dateT.getMonth() + 1).padStart(2, '0');
    var yyyy = dateT.getFullYear();
    var h = dateT.getHours()
    var m = dateT.getMinutes()
    var s = dateT.getSeconds()
    var ms = dateT.getMilliseconds()

    let dateFormated = `${yyyy + mm + dd + h + m + s + ms}`
    return dateFormated
  }

}
