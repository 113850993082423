<section #cadCot class="login">
    <div class="page login-banner">
        <div class="wrapper">
            <div class="page-banner" aria-label="Page Banner" [inlineSVG]="'assets/svg/Icone_Branco_SVG.svg'"></div>
            <div class="banner-content">
                <h2 class="text-secondary">Seja um franqueado</h2>
                <p>Falta pouco para fazer parte da família Pic envios...</p>
            </div>

        </div>
    </div>
    <div class="page login-form" *ngIf="!isCad">
        <div class="login-container">
            <span class="back-page" [routerLink]="['/']">
                <div class="icon">
                    <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
                </div>
                Voltar para home
            </span>
            <div class="login-card shadow">
                <h2 class="mb-3">Ficha cadastral</h2>
                <form [formGroup]="newUserForm" (submit)="sendEmail()">
                    <div class="form-field">
                        <label>Nome*</label>
                        <input formControlName="nome" type="text" required>
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
                    <div class="form-field mt-2">
                        <label>E-mail*</label>
                        <input formControlName="email" type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required >
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
                    <div class="form-field">
                        <label>Telefone*</label>
                        <input formControlName="fone" required>
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
              
                    <div class="form-field">
                        <label>Estado*</label>
                        <input formControlName="uf" required>
                        <small class="text-danger">Valor obrigatório</small>
                    </div>
                    <div class="form-field">
                        <label>Cidade*</label>
                        <input formControlName="cidade" required>
                        <small class="text-danger">Valor obrigatório</small>
                    </div>


                    <div class="form-field mt-5 mb-5">
                        <label>Quanto espera investir?*:</label>
                    <select class="input"  formControlName="investimento"  aria-label="Default select example">
                        <option *ngFor="let valor of investment" value="{{valor.name}}">{{valor.name}}</option>

                      </select>
                    </div>

                    <div class="form-field">
                        <label>Quando pretende iniciar?*:</label>
                        <select class="input" placeholder="Selecione um período" formControlName="inicio"  aria-label="Default select example">
                            <option *ngFor="let data of start" value="{{data.name}}">{{data.name}}</option>
                          </select>
                    </div>
              
                    <button type="submit" class="btn btn-lg btn-secondary mt-5">Enviar</button>
                </form>
            </div>
        </div>
    </div>

    <div class="page login-form" *ngIf="isCad">
        <div class="login-container">
            <div class="login-card shadow">
                <h2 class="mb-3 text-secondary" style="text-align: center;">Cadastro concluído com sucesso!</h2>
                <div class="row" style="margin-top: 40px;">
                    <button class="btn mx-auto my-3 d-block" [routerLink]="['']">
                        <div class="row" style="padding: 0 10px 0 2px;">
                        <div class="icon" style="padding-right: 15px;">
                            <fa-icon [icon]="faHome"></fa-icon>
                        </div>
                        Voltar para a home
                    </div>
                </button>
        
                 </div>
              
                </div>
            </div>
        </div>

</section>