<div #finishUser class="add-modal">
    <button class="btn btn-close-tab" (click)="closeTab()">
        <div class="icon">
            <fa-icon [icon]="faTimes"></fa-icon>
        </div>
    </button>
    <div class="wrapper">
        <span class="add-modal-head">
            <div class="icon">
                <fa-icon [icon]="faUserPlus"></fa-icon>
            </div>
            
            FINALIZAR CADASTRO
        </span>
 


        <form [formGroup]="clientForm" (ngSubmit)="finishClient(clientForm)" class="clientForm">
<!-- 
            <img src="../../../assets/img/Aviso_PicEnvios.png" style="width:100%"> -->
            <span class="my-5">Dados da Empresa</span>
            <div class="bg-primary-light p-5">
                <div class="row w-100">
                    <div class="form-field">
                        <label>Nome*</label>
                        <input formControlName="nome"  type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>

                    <div class="form-field">
                        <label for="documentNumber">CPF ou CNPJ</label>
                        <input type="text" id="documentNumber" class="doc-input" formControlName="documentNumber"
                            mask="CPF_CNPJ" required>
                            <small class="text-danger">Campo Inválido</small>
                    </div>
                </div>

                <span class="my-5">Endereço</span>
                <div class="row w-100">
                    <div class="form-field">
                        <label>CEP*</label>
                        <input formControlName="cep" mask="00000-000" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                    <div class="form-field">
                        <label>Número*</label>
                        <input formControlName="numero" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="form-field">
                        <label>Endereço*</label>
                        <input formControlName="endereco" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                </div>

                <div class="row w-100">
                    <div class="form-field">
                        <label>Complemento</label>
                        <input formControlName="complemento" type="text" maxlength="25">
                    </div>
                </div>

                <div class="row w-100">
                    <div class="form-field">
                        <label>Cidade*</label>
                        <input formControlName="cidade" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                    <div class="form-field">
                        <label>Bairro*</label>
                        <input formControlName="bairro" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>
                    <div class="form-field">
                        <label>Estado*</label>
                        <input formControlName="uf" type="text" required>
                        <small class="text-danger">Campo Inválido</small>
                    </div>

                    <div class="form-field">
                        <label>Email</label>
                        <input formControlName="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                    </div>
                    <div class="form-field">
                        <label>Telefone</label>
                        <input formControlName="telefone" type="telefone" mask="(00)0000-0000 || (00)00000-0000">
                    </div>
                </div>
            </div>
            <button class="btn btn-blue mx-auto my-3 d-block" type="submit">Salvar</button>
        </form>

    </div>

    