import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import anime from 'animejs/lib/anime.es.js';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faSortAlt, faCalculatorAlt, faToggleOn, faToggleOff, faLongArrowAltLeft, faInfoCircle, faPlus, faTrashAlt, faLongArrowAltRight } from '@fortawesome/pro-light-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';

import { Calculo, Volume } from 'src/app/models/volume.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-calculadora-h',
  templateUrl: './calc-home.component.html',
  styleUrls: ['./calc-home.component.scss']
})
export class CalculadoraHComponent implements OnInit {
  @Input() set _unit(v: any) {
    this.unit = v.unitData
  }
  @Output() userAdd = new EventEmitter();
  @Input() set _calcType(v: any) {
    this.type = v
  }
  @Input() set _userInfo(v: any) {
    this.userInfo = v
  }
  @ViewChild('volumeRow', { static: false }) volumeRow: ElementRef




  //Icons
  faSortAlt = faSortAlt
  faToggleOff = faToggleOff
  faToggleOn = faToggleOn
  faLongArrowAltLeft = faLongArrowAltLeft
  faInfoCircle = faInfoCircle
  faPlus = faPlus
  faTrashAlt = faTrashAlt
  faLongArrowAltRight = faLongArrowAltRight
  faCalculatorAlt = faCalculatorAlt

  // Form
  freightForm: FormGroup
  volumesForm: FormArray;


  // Exhibition Variables
  isForm: boolean = true
  isLoading = false
  cadVisible;
  reverseLogistic: boolean = false
  freightList: any[] = []
  type
  formUpdated = false
  activeFreight
  destinatario
  consultedData: any
  volume
  userInfo
  unit;
  cadForm;
  isCad
  userCookies
  isDc;
  dc: string;
  address: any;
  logged
  userSigned = this.userService.checkLoggedIn()
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  constructor(
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private orderService: OrderService,
    private toastr: ToastrService,
    private userService: UserService,
    private storageService: StorageService


  ) { }

  @ViewChild('calcSection', { static: false }) calcTable: ElementRef

  ngOnInit(): void {
    // this.createFreightFormTest();
    this.createFreightForm();
    this.createCadForm();
    this.userCookies = this.storageService.get('userInfo')
    if (this.userCookies == '') {
      this.isCad = false
    } else if (this.userCookies != '') {
      this.isCad = true

    }

    this.freightForm.get('cepDestino').valueChanges.subscribe(x => {
      x.length == 8 ? this.getAddress(x) : null
    });

  }

  createFreightForm() {
    this.freightForm = this.formBuilder.group({
      cepOrigem: [this.userInfo?.zipCode || null, [Validators.required]],
      cepDestino: [null, [Validators.required]],
      quantity: [1, [Validators.required, Validators.min(1)]],
      height: [null, [Validators.required]],
      width: [null, [Validators.required]],
      value: [null, [Validators.nullValidator]],
      length: [null, [Validators.required]],
      weight: [null, [Validators.required]],
      nfe: [null]
    })


  }


  async getAddress(x) {

    let cepDestino = x
    const response: any = await this.orderService.getAddress(cepDestino)
    this.address = response.data
    if (this.userCookies != '')
      localStorage.setItem('endereco', JSON.stringify(this.address))
  }

  createCadForm() {
    this.cadForm = this.formBuilder.group({
      nome: [null, [Validators.required]],
      email: [null, [Validators.required]],
      telefone: [null, [Validators.required]]
    })

  }

  createFields() {
    return this.formBuilder.group({
    })

  }
  get f() { return this.volumesForm.controls; }
  get volumesFormFor() {
    return this.volumesForm && this.volumesForm.controls ? this.volumesForm.controls : [{
      quantity: 1,
      height: null,
      width: null,
      value: null,
      length: null,
      weight: null,
      nfe: null
    }]
  }

  renderWeightSuffix(value) {
    if (value && value.slice(0, 1) == 0) {
      return ' gramas'
    } else {
      return ' kg'
    }

  }
  checkWeight(e) {
    const weight = this.freightForm.value.weight
    const newValue = weight + '.'

    if (e.key === ',') {
      this.freightForm.patchValue({
        weight: newValue
      })
      event.preventDefault()
    }
    // Condição 1: Se peso for apagado
    // Condição 2: Se peso for zero
    // Condição 3: Se valor digitado for diferente de zero e diferente de backspace depois do ponto
    if (e.key === "0" && weight === '' ||
      e.key === "0" && weight === "0" ||
      e.key != '0' && e.key != "Backspace" && weight === "0"
    ) {

      this.freightForm.patchValue({
        weight: weight ? newValue + e.key : '0.'
      })

      event.preventDefault()
    }
  }

  async getFreight(form) {



    this.freightList = []
    this.isLoading = true
    this.isForm = false
    this.type == 1 ? this.calcTable.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" })
      : null
    const values = this.reverseLogistic ? Object.assign({}, {
      ...form.value,
      cepOrigem: form.value.cepDestino,
      cepDestino: form.value.cepOrigem
    }) : form.value;

    if (this.isDc == true) {
      values.nfe = false
    } else if (this.isDc == undefined || values.nfe == null) {
      this.isDc = false
      values.nfe = true
    }

    const destinatario = {
      "recipient": values.recipient,
      "email": values.email,
      "address": values.address,
      "documentNumber": values.documentNumber,
      "cep": values.cep,
      "city": values.city,
      "uf": values.uf,
      "number": values.number,
      "neighborhood": values.neighborhood,
      "complement": values.complement,
    }
    this.consultedData = values


    // const items: Volume[] = values.volumesForm.map(v =>
    let vol = new Volume(
      values.cepDestino,
      Number(values.quantity),
      Number(values.weight),
      Number(values.length),
      Number(values.height),
      Number(values.width),
      "N",
      values.value,
      "N",
      values.nfe,
      values.semCPF
    )
    // )

    const calculo = new Calculo(
      values.cepOrigem,
      [vol]
    )

    this.homeService.calculateFrete(calculo)
      .then(res => {
        if (res) {

          this.freightList = res.groupedVolumes
          for (let freight of this.freightList) {
            freight.active = false
          }
          this.fadeTable()
          this.destinatario = destinatario
          this.volume = calculo


          for (let i = 0; i < this.freightList.length; i++) {
            if (this.isCad == false && this.userCookies == '') {
              this.freightList = this.freightList.slice(0, 3)
            }
            if (this.userCookies != '') {
              this.freightList = res.groupedVolumes
            }
          }

        }
      })
    if (this.userCookies != '') {
      localStorage.setItem('data', JSON.stringify(this.freightForm.value))
    }

  }



  fadeTable() {
    setTimeout(() => {

      this.isLoading = false
      anime({
        targets: '.table-row',
        opacity: 1,
        delay: anime.stagger(300),
        easing: 'spring(1, 80, 10, 0)'
      })
    }, 0);
  }

  resetCalc() {
    this.freightList = [];
    this.isForm = !this.isForm
    this.isLoading = false
    if (this.userInfo?.typeUserId != 1) {
      this.freightForm.patchValue({
        cepOrigem: this.userInfo?.zipCode
      })
    }

    this.freightForm.patchValue({
      quantity: 1
    })

  }

  goToAttendance() {
    this.router.navigate(['/painel/atendimento'])

    localStorage.setItem('dados', JSON.stringify(this.consultedData));

  }
  goToAttendancePA() {
    this.router.navigate(['/painel/cotacao-pa'])
  }

  forDc() {
    this.isDc = true;
    if (this.freightForm.value.nfe == null) {
      this.freightForm.value.nfe = true
    }
  }

  clearCheck() {
    this.isDc = false;
    const dc = document.getElementById('isDc') as HTMLInputElement;
    if (this.isDc == false) {
      dc.checked = false;

    }
    this.freightForm.value.nfe = true
  }

}

