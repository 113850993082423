import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from './services/shared.service';
import { UserService } from './services/user.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isPanel: boolean = this.sharedService._isPanel
  html = document.getElementById('scroll')
  userInfo;
  logged: boolean
  userSigned = this.userService.checkLoggedIn()
  isNotToShow: any;
  faWhatsapp = faWhatsapp
  constructor(
    private renderer: Renderer2,
    private sharedService: SharedService,
    private userService: UserService,
    public router: Router
  ) {

    sharedService.isPanel.subscribe(panel => {
      this.isPanel = panel
    })
    sharedService.userSigned.subscribe(v => {
      this.userSigned = v
      this.userInfo = v ? this.userService.getUserData() : null
    })
    this.renderer.addClass(this.html, 'scroll')
  }

  ngOnInit() {
    this.getUserInfo();

  }

  async getUserInfo() {
    if (this.userSigned) {

      this.sharedService.changeUserSigned(true)
      this.userInfo = await this.userService.getUserData()
      this.logged = true;
    } else {
      this.userService.logOut()
      this.logged = false;
    }
  }

  ngOnDestroy(): void {
    this.userService.logOut()
  }

  isLogin() {
    return this.router.url == '/login'
  }
  isCad() {
    return this.router.url == '/cadastre-se'
  }
  isHome() {
    return this.router.url == '' ||this.router.url == '/'
  }

}
