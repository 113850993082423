import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faLongArrowAltLeft, faUser, faLock, faHome } from '@fortawesome/pro-light-svg-icons'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { UnitService } from 'src/app/services/unit.service';
import * as $ from 'jquery';
declare var  Email: any;
// import "https://smtpjs.com/v3/smtp.js"

interface Investiment {
  name: string;
}
interface Start {
  name: string;
}

@Component({
  selector: 'app-be-a-franchisee',
  templateUrl: './be-a-franchisee.component.html',
  styleUrls: ['./be-a-franchisee.component.scss']
})
export class BeAFranchiseeComponent implements OnInit {
  @ViewChild('franchising', { static: false }) cadCot: ElementRef

  //icons
  faLongArrowAltLeft = faLongArrowAltLeft
  faUser = faUser
  faHome = faHome

  activeUser
  userInfo
  newUserForm: FormGroup
  activeUnit = this.sharedService._unitActive
  isCad: boolean;

  investment: Investiment[] = [
    {
      name: 'Até R$50.000',
    },
    {
      name: 'Até R$100.000',
    },
    {
      name: 'Mais de R$ 100.000',
    },
  ]
  start: Start[] = [
    {
      name: 'Imediato',
    },
    {
      name: 'De 03 a 06 Meses',
    },
    {
      name: 'De 07 a 12 Meses',
    },
  ]
  constructor(
    private FormBuilder: FormBuilder,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private unitService: UnitService,
    private userService: UserService
  ) {
    this.userInfo = this.userService.getUserData()
  }

  ngOnInit(): void {
    this.createUserForm()
    this.isCad = false

  
    
  }

  createUserForm() {
    this.newUserForm = this.FormBuilder.group({
      nome: [null, Validators.required],
      email: [null, Validators.required],
      fone: [null, Validators.required],
      uf: [null, Validators.required],
      cidade: [null, Validators.required],
      investimento: [null, Validators.required],
      inicio: [null, Validators.required],
    })
  }



  sendEmail(){
    let email = this.newUserForm.value

     const params = {
      "id": 0,
      "name": email.nome,
      "email": email.email,
      "number": email.fone,
      "city": email.cidade,
      "uf": email.uf,
      "investiment": email.investimento,
      "period": email.inicio,
      "franchise": true,
      "enabled": true
     }
     const response =  this.userService.mailing(params)

     if(response){
      this.isCad = true
     }
  }


}
