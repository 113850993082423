<div class="page px-5">
    <div class="page-wrapper">


        <h5 class="format"> TERMOS DE USO, REGRAS E POLÍTICA DE PRIVACIDADE DA Pic Envios </h5>

        <p> Pelo presente Termo de Uso, Regras e Política de Privacidade da Pic Envios (o <b>“Contrato”</b>), <b>Pic Envios INTERMEDIAÇÃO DE NEGÓCIOS LTDA.</b>, sociedade limitada inscrita no CNPJ/MF sob o n. 32.618.607/0001-26, com sede na Rua Gomes de Carvalho, 768, salas 31/32 “h”, CEP 04547-003, Vila Olímpia, SP, doravante “<u>Contratada</u>” e o Usuário que acessar a Plataforma Digital e/ou Usuário que efetivamente adquirir um ou mais serviços disponibilizados na Plataforma Digital, doravante denominado “<u>Usuário</u>”; </p>

        <p> Contratada e Usuário doravante denominados, individualmente, como <b> “Parte” </b> e, em conjunto, como <b> “Partes”.</b> </p>

        <p> Resolvem as Partes firmar o presente Contrato, o que fazem nos termos e para os fins seguintes:</p>

        <h5> CLÁUSULA PRIMEIRA - DEFINIÇÕES </h5>

        <ul>

            <li>
                “<u>Contrato</u>”: trata-se do presente Termo de Uso, Regras e Política de Privacidade da Pic Envios.
            </li>

            <li>
                “<u>Dados pessoais</u>”: serão consideradas todas as informações pessoais colhidas do Usuário durante seu acesso e/ou cadastro na Plataforma Digital.p
            </li>

            <li>
                “<u>Informações pessoais</u>”: qualquer informação que possa ser usada para identificar um indivíduo, incluindo seu nome, qualificação pessoal, inscrição no CPF/MF ou CNPJ/MF, endereço físico ou de e-mail, número de telefone, demais dados de contato, dados de cobranças, ou quaisquer outros dados que possam ser suficientes para serem vinculados a essas informações.
            </li>

            <li>
                “<u>Informações de acesso</u>”: qualquer informação que possa ser usada para identificar o Usuário, sua solicitação e atividades na Internet, as mensagens enviadas ou divulgadas, o Endereço de Protocolo de Internet ("IP") e horário de conexão, as URLs do site de origem e do site que o usuário pode visitar em seguida, o tipo e o idioma do navegador, um ou mais Cookies que possam identificar o navegador ou a conta da Usuário, informações gerais e específicas sobre as atividades do Usuário. <b class="underline"> Por este instrumento, o Usuário autoriza a Contratada a solicitar o provedor de acesso o número de IP e quaisquer informações que possibilite a identificação do Usuário.</b>
            </li>

            <li>
                “<u>Negócio(s)</u>”: trata-se de compra e venda de serviços realizada mediante a utilização da Plataforma Digital.
            </li>

            <li>
                “<u>Plataforma Digital</u>” ou “<u>website</u>“: trata-se da plataforma completa de gestão de fretes para o e-commerce da Contratada, na qual é possível realizar cotações, pagar fretes, gerar etiquetas de postagem, além de realizar todo rastreamento e monitoramento da carga até o destinatário final.
            </li>

            <li>
                “<u>Propriedade Intelectual</u>”: para o presente instrumento será considerada toda e qualquer propriedade imaterial que abranja bens intangíveis com valor para a vida empresarial, tais como marcas, patentes, nomes de domínio, softwares, e qualquer outro bem intangível relacionado.
            </li>

            <li>
                <u>“Usuário(s)”</u>: trata-se da pessoa física ou pessoa jurídica que acessar a Plataforma Digital e/ou o usuário que efetivamente adquirir um ou mais serviços disponibilizados na Plataforma Digital.
            </li>

            <li>
                <u>“Etiqueta”</u> : é a autorização de envio; uma espécie de cupom emitido pelo sistema que autoriza a transportadora a realizar o serviço.
            </li>

            <li>
                <u> “Transportadora(s)”</u>: Toda empresa especializada no transporte de cargas, que possui convênio com a Contratada, e aceita as etiquetas geradas pelo sistema.
            </li>

            <li>
                <u> “Painel do usuário”</u>: É o sistema on-line de gerenciamento disponibilizado pela Pic Envios ao Usuário, para que este tenha acesso às etiquetas, às configurações de perfil, aos relatórios, além dos outros serviços e ferramentas da Plataforma Digital.
            </li>

            <li>
                <u>“Carteira”</u>: É a conta virtual que armazena e registra toda a movimentação financeira do Usuário junto à Contratada. Pode ser acessada por meio do painel do usuário e, ao fim, é o meio pelo qual o usuário realiza o pagamento de seus fretes.
            </li>

        </ul>

        <h5> CLÁUSULA SEGUNDA - OBJETO E FUNCIONAMENTO DO SISTEMA </h5>
        <ul>


            <li>
                <b> 2.1. </b> O presente Contrato tem por objeto o estabelecimento de regras para que o Usuário possa acessar o website da Contratada, bem como todas as suas ferramentas digitais, realizar cotações, pagar fretes, gerar etiquetas de postagem, além de realizar todo rastreamento e monitoramento da carga até o destinatário final pelas Transportadoras.
            </li>

            <li>
                <b>2.2.</b> O Usuário está ciente de que:
            </li>

            <ul class="margin">

                <li>
                    (i)Empresa Integradora: A Contratada é uma empresa integradora, que fornece tecnologia para integrar e-commerces, marketplace e intermediadores de pagamentos às Transportadoras.
                </li>

                <li>
                    (ii)Empresa complementar: A Contratada não é uma transportadora, de modo que suas atividades são apenas complementares à cadeia logística de encomendas - cotações, faturamento, conciliação de pagamentos de fretes, rastreamento etc.
                </li>

            </ul>

        </ul>

        <h5> CLÁUSULA TERCEIRA: DO FUNCIONAMENTO DO SISTEMA. </h5>

        <b> 3.1. </b> O sistema disponibilizado na Plataforma Digital funciona da seguinte forma:

        <ul class="margin">
            <li>
                (i)A Contratada disponibiliza no seu site uma ferramenta de cotação de fretes e faz a intermediação para a aquisição de etiquetas junto às Transportadoras.
            </li>

            <li>
                (ii)O Usuário, sob sua responsabilidade, insere os critérios de cotação, a exemplo: (a) CEP de origem, (b) CEP de destino, (c) dimensões do objeto, (d) peso do objeto, dentre outros; e
            </li>

            <li>
                (iii) A partir dos critérios de cotação informados pelo Usuário, a Contratada informa o resultado da pesquisa, com o valor de cada Transportadora, podendo o Usuário gerar uma etiqueta pelo preço cotado à Transportadora de sua escolha.
            </li>
        </ul>

        <ul>
            <li>
                <b> 3.2.</b> O Usuário terá limite dinâmico de envios simultâneos, de acordo com o algoritmo de segurança da plataforma. Este limite aumenta automaticamente com o uso do sistema, até o máximo de 50 (cinquenta) por dia. Para demandas maiores, o Usuário pode pedir análise especial à Contratada, que analisará caso a caso, mediante assinatura de adendo contratual.
            </li>

            <li>
                <b> 3.3. </b> O Usuário pode consultar suas etiquetas geradas, envios, relatórios e demais funcionalidades da Plataforma Digital da Contratada por meio do painel do usuário, mediante seu e-mail e senha cadastrada.
            </li>

            <ul class="margin">
                <b>Parágrafo único: </b> A Contratada nunca solicitará a senha do usuário em nenhuma comunicação. O Usuário jamais deve fornecer os seus dados cadastrais a ninguém.
            </ul>


            <li>
                <b> 3.4. </b> Todas as operações financeiras do Usuário ficarão registradas em sua carteira, disponível no painel de controle. O Usuário pode adicionar fundos na sua carteira, utilizando os meios de pagamento disponíveis, ou, ainda, sacar o valor creditado.
            </li>
        </ul>

        <h5> CLÁUSULA QUARTA - CADASTRO</h5>
        <ul>
            <li>
                <b>4.1. </b> Apenas poderão acessar o website Usuários com capacidade legal, nos termos do Código Civil para exercer a contratação e efetuar negócios jurídicos, bem como maiores de 18 (dezoito) anos de idade.
            </li>

            <li>
                <b> 4.2. </b> O cadastro de Usuários pessoas físicas não identificadas é terminantemente proibido, bem como o cadastro de Usuários pessoas jurídicas sem representante(s) legal(is) e/ou sem estarem devidamente constituído(s) segundo as leis brasileiras.
            </li>

            <li>
                <b> 4.3. </b> Os Usuários que se cadastrarem fora dos termos legais serão suspensos da Plataforma Digital, temporária ou definitivamente, sem prejuízo das sanções legais previstas e leis brasileiras.
            </li>
        </ul>

        <h5> CLÁUSULA QUINTA - OBRIGAÇÕES DO USUÁRIO </h5>

        <ul>
            <li>
                <b> 5.1. </b> O Usuário declara que todas as informações fornecidas para cadastro na Plataforma Digital serão verídicas, sob pena de falsidade ideológica. O Usuário se compromete a atualizar todas as informações fornecidas.
            </li>

            <li>
                <b> 5.2. </b> O Usuário se compromete a não divulgar sua senha da Plataforma Digital para terceiros e declara ter ciência de que este comportamento é perigoso e pode acarretar em danos materiais para si próprio e terceiros. O Usuário não pode imprimir, distribuir, postar, repostar em redes sociais ou em qualquer ambiente e/ou gravar qualquer material apresentado na Plataforma Digital, seja para uso próprio ou para terceiros.
            </li>

            <li>
                <b> 5.3. </b> São ainda obrigações do Usuário:

            </li>
        </ul>


        <ul class="margin">
            <li>
                a) Responsabilizar-se pela legalidade do objeto postado;
            </li>

            <li>
                b) Pagar antecipadamente a totalidade do preço cotado para gerar uma etiqueta;
            </li>

            <li>
                c) Respeitar os termos de uso e a documentação de todos os serviços adicionais, gratuitos ou não, oferecidos pela Contratada;
            </li>

            <li>
                d) Observar as regras internas e requisitos da Transportadora escolhida, uma vez que o frete será efetivamente prestado tão somente pela Transportadora, que poderá, inclusive, recusar a prestação do serviço em razão de suas próprias políticas, não tendo a Contratada qualquer ingerência e/ou responsabilidade nesse sentido;
            </li>

            <li>
                e) Informar corretamente o CEP de origem e destino, além do peso e dimensões do objeto a ser postado- informações indispensáveis para a correta cotação do frete junto às transportadoras;
            </li>

            <li>
                f) Verificar, na hora da postagem, se o funcionário da Transportadora está inserido no sistema da transportadora e se os valores estão corretos, assim como peso e dimensões do objeto;
            </li>

            <li>
                g) É terminantemente proibido ao Usuário revender as etiquetas ou qualquer outro serviço que integre a plataforma.
            </li>
        </ul>

        <h5> CLÁUSULA SEXTA – OBRIGAÇÕES DA CONTRATADA </h5>

        <b> 6.1.</b> São obrigações da Contratada:

        <ul class="margin">
            <li>
                a) Permitir que o Usuário realize cotações em tempo real em todas as Transportadoras conveniadas que atendam ao CEP informado;
            </li>

            <li>
                b) Possibilitar que o Usuário gere uma etiqueta pelo preço cotado com base nas informações de envio fornecidas;
            </li>

            <li>
                c) Manter o sistema online, permitindo acesso ao painel de controle, às etiquetas, aos relatórios etc.
            </li>

        </ul>
        <h5> CLÁUSULA SÉTIMA: DO SISTEMA DE CONCILIAÇÃO DE DADOS </h5>

        <b> 7.1 </b> O Sistema de Conciliação de Dados consiste em realizar uma cotação com os parâmetros de peso e dimensões efetivamente recebidos pela Transportadora e compará-la com a cotação realizada para gerar a etiqueta utilizada, nos seguintes moldes:

        <ul class="margin">

            <li>
                (i) se da diferença resultar saldo credor para o Usuário, o Usuário pode resgatá-lo para sua carteira e dar a destinação que bem entender;
            </li>

            <li>
                (ii) Se, por outro lado, da diferença resultar saldo devedor para o Usuário, a Contratada está autorizada a cobrar do Usuário mediante débito em sua carteira.
            </li>

        </ul>


        <b>Parágrafo único:</b> O débito aludido supra refere-se a uma obrigação líquida e certa, sendo que sua ocorrência suspende a geração de envios até a efetiva quitação. O não pagamento caracteriza descumprimento contratual, com todas as sanções cabíveis, inclusive inserção em cadastro de inadimplentes e nos órgãos de proteção de crédito.

        <h5> CLÁUSULA OITAVA – PROTEÇÃO E UTILIZAÇÃO DE DADOS </h5>

        <ul>

            <li>
                <b> 8.1. </b> As informações fornecidas pelo Usuário ficarão armazenadas na base de dados da Contratada, sendo garantida assim a proteção das mesmas, podendo configurar em informações pessoais e informações de acesso.
            </li>

            <li>
                <b> 8.2. </b> O Usuário concorda que a Contratada poderá, a qualquer momento, confirmar a validade dos Dados Pessoais informados pelo Usuário consultando entidades públicas, companhias especializadas e banco de dados de proteção ao crédito.
            </li>

            <li>
                <b> 8.3. </b> A Contratada tem sempre o objetivo de sigilo total acerca dos dados pessoais do Usuário, com o intuito de proteger os direitos fundamentais de liberdade e de privacidade. O usuário referido deve estar ciente que a Contratada utilizará os dados para seguintes fins:
            </li>

            <ul class="margin">
                <li>
                    (i) Fornecer, manter, proteger, desenvolver e melhorar o site virtual da Contratada e sua segurança;
                </li>

                <li>
                    (ii )Enviar comunicados aos demais usuários da Plataforma Digital;
                </li>

                <li>
                    (iii) Manter atualizados os cadastros dos usuários para fins de contato telefônico, por correio eletrônico, SMS ou outros meios de comunicação;
                </li>

                <li>
                    (iv) Constituir, defender ou exercer regularmente direitos da Contratada em âmbito judicial ou administrativo;
                </li>

                <li>
                    (v) Realizar pesquisas e campanhas de comunicação e marketing de relacionamento; e
                </li>

                <li>
                    (vi) Compartilhar com terceiros as informações pessoais e informações de acesso dos Usuários para prestação dos serviços oferecidos pela Contratada. O Usuário desde já autoriza expressamente este compartilhamento.
                </li>
            </ul>

            <p>
                <b> 8.4.</b> O Usuário autoriza expressamente a utilização de seus dados e informações para que sejam compartilhados, entre empresas do mesmo grupo societário da Contratada e parceiros comerciais e, também, para a Contratada negociar a adesão de novas transportadoras à plataforma.
            </p>

            <p>
                <b> 8.5. </b> A Contratada não se responsabiliza por falhas no sistema, qualquer dano, perda, ou falhas de internet decorrentes de condutas de terceiros, caso fortuito ou força maior ou qualquer vírus que possa atacar o equipamento do Usuário em consequência do acesso na Plataforma Digital.
            </p>



            <b> 8.6. </b> São direitos do Usuário, nos termos da legislação: I - confirmação da existência de tratamento; II - acesso aos dados; III - correção de dados incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; VI - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados; VII - não fornecer consentimento, porém as funcionalidades e serviços do site poderão não funcionar completamente; VIII - revogação do consentimento.

        </ul>

        <!-- </div>

        <div> -->
        <p>
            <b> 8.7.</b> O Usuário poderá, a qualquer momento, entrar em contato com a Contratada através do email conta <u class="font"> contato@pageexpress.com.br</u> para o esclarecimento de quaisquer dúvidas e /ou informações atinentes ao tratamento de seus dados, nos termos da legislação em vigor.

        </p>

        <h5> CLÁUSULA NONA – PROPRIEDADE INTELECTUAL </h5>
        <ul>
            <li>
                <b>9.1.</b> A propriedade intelectual de todo o material apresentado na Plataforma Digital é de titularidade da Contratada, de forma que fica terminantemente proibida pelo Usuário sua cessão onerosa ou gratuita ou reprodução onerosa ou gratuita não autorizada, incluindo todas as formas possíveis de cópia e distribuição/veiculação.
            </li>

            <li>
                <b>9.2. </b> O uso indevido de propriedade intelectual será tratado nos termos da legislação cabível e sujeitará o infrator às sanções judiciais cabíveis, sem prejuízo de indenização pelos prejuízos causados à Contratada ou terceiros e, também, o cancelamento imediato da conta.

            </li>

        </ul>
        <h5> CLÁUSULA DÉCIMA - ISENÇÃO DE RESPONSABILIDADE DA CONTRATADA </h5>

        <b> 10.1.</b> O Usuário tem ciência de que:

        <ul class="margin">

            <li>
                (i) A Contratada não garante que os arquivos disponíveis para download e/ou acessados pelo Usuário estejam livres de vírus ou códigos que podem danificar e/ou prejudicar as funcionalidades do computador do usuário, sendo de responsabilidade do Usuário ter a proteção adequada para tanto;
            </li>

            <li>
                (ii) O pagamento de indenizações em caso de extravios, roubo ou qualquer infortúnio é de responsabilidade da Transportadora escolhida pelo Usuário ou sua seguradora, sendo os valores pagos de acordo com a política de cada uma, quando da contratação, sendo uma opção do Usuário;
            </li>

            <li>
                (iii) Por questões contratuais, algumas empresas de transporte podem optar por realizar o depósito do valor do extravio para a Contratada, que fica obrigada a repassar, após ter recebido, o montante à carteira do Usuário, sem descontos, tão logo receba da transportadora;
            </li>

            <li>
                (iv) Se o meio de pagamento cobrar taxas para processar a operação, estas serão devidas pela parte que está recebendo os valores;
            </li>

            <li>
                (v) A Contratada não tem qualquer ingerência no serviço de transporte em si, pois é apenas intermediadora da contratação do serviço de frete. A responsabilidade pela entrega e pelo cuidado com a encomenda é exclusivamente da transportadora escolhida.
            </li>

        </ul>

        <!-- </div> -->

        <!-- <div> -->
        As empresas de transporte são partes independentes e sem qualquer relação societária com a Contratada, de modo que a Contratada não é responsável pelas obrigações de tais empresas.

        <h5> CLÁUSULA DÉCIMA PRIMEIRA - PREÇOS E FORMA DE PAGAMENTO </h5>
        <ul>
            <b> 11.1.</b> O cálculo de cotação da etiqueta é composto pelo valor do frete já abatido de parte do eventual desconto concedido pela transportadora por grandes volumes. A outra parte do aludido desconto compõe a remuneração da Contratada.

            <li>
                <b> Parágrafo único:</b> o não pagamento dos serviços acarretará na imediata suspensão dos serviços e funcionalidades da Plataforma.
            </li>
        </ul>
        <h5> CLÁUSULA DÉCIMA SEGUNDA–TÉRMINO DO CONTRATO </h5>
        <ul>
            <li>
                <b>12.1. </b> O Contrato se manterá vigente durante todo o relacionamento do Usuário na Plataforma Digital.

            </li>

            <li>
                <b>12.2. </b> Este Contrato poderá ser encerrado a qualquer momento pela Contratada com ou sem causa.
            </li>

            <li>
                <b>12.3. </b> O Contrato poderá ser encerrado a qualquer momento pelo Usuário, desde que este não acesse e não utilize mais os serviços da Plataforma Digital.
            </li>

            <li>
                <b> 12.4. </b> As cláusulas <b> Oitava (Proteção e Utilização de Dados), Nona (Propriedade Intelectual), Décima (Isenção de Responsabilidade da Contratada) e Décima Quarta (Cessão e Transferência)</b> deste Contrato se manterão vigentes mesmo após o término do Contrato.

            </li>


        </ul>

        <h5> CLÁUSULA DÉCIMA TERCEIRA –INFRAÇÕES </h5>

        <b>13.1. </b> O descumprimento de qualquer das Cláusulas aqui dispostas poderá ensejar suspensão e/ou cancelamento da conta do Usuário infringente, a critério único e exclusivo da Contratada.

        <h5> CLÁUSULA DÉCIMA QUARTA – CESSÃO E TRANSFERÊNCIA </h5>

        <ul>
            <li>

                <b>14.1. </b> Este Contrato não poderá ser cedido ou transferido pelo Usuário por qualquer razão.
            </li>

            <li>
                <b>14.2. </b> A Contratada poderá ceder ou transferir o presente Contrato a qualquer momento e em qualquer hipótese, em especial troca de controle ou qualquer operação de reestruturação societária.

            </li>
        </ul>

        <h5> CLÁUSULA DÉCIMA QUINTA – DISPOSIÇÕES FINAIS </h5>

        <!-- </div> -->
        <ul>
            <li>
                <b>15.1. </b> Fica estabelecido que este Contrato terá vigência indeterminada, assim permanecendo até o cumprimento de todas as obrigações nele estabelecidas.
            </li>

            <li>
                <b> 15.2.</b> O presente Contrato é obrigatório para as Partes e seus sucessores a qualquer título.
            </li>

            <li>
                <b> 15.3.</b> No caso de qualquer dispositivo do presente instrumento ter sua redação considerada nula ou inválida por decisão judicial, os demais dispositivos não perderão sua validade e continuarão vigendo normalmente.
            </li>

            <li>
                <b>15.4.</b> As Partes envidarão seus melhores esforços para resolver de boa-fé, atendendo a seus mútuos interesses, qualquer divergência que possa surgir em relação a este compromisso. O Contrato admite a execução específica das obrigações nele contidas, nos termos da legislação processual vigente.
            </li>

            <li>
                <b>15.5.</b> Quaisquer controvérsias ou disputas que venham a surgir, a qualquer tempo, entre as Partes, oriunda e/ou relacionada a este Contrato, inclusive qualquer questão referente à sua existência, validade, cumprimento, interpretação e execução, e que não sejam solucionadas amigavelmente entre as Partes, deverão ser decididas no Foro Central de São Paulo –SP, com a exclusão de qualquer outro, por mais privilegiado que seja.
            </li>

            <li>
                <b>15.6.</b> A Contratada a qualquer momento poderá alterar qualquer dispositivo e/ou anexo do presente instrumento contratual e enviará um e-mail para o Usuário cadastrado com aviso de alteração. Ao continuar acessando o site virtual, o Usuário automaticamente aceita o teor e eventuais modificações realizadas posteriormente à assinatura deste Contrato.
            </li>

            <li>
                <b>15.7.</b> Qualquer alteração realizada no presente Contrato vigerá imediatamente após sua publicação no website e envio nos termos da Cláusula acima.
            </li>

            <li>
                ASSINATURAS ELETRÔNICAS: O Usuário declara e concorda, de forma irrevogável e irretratável, que o presente instrumento é assinado de forma eletrônica e reconhece nos termos do Código Civil e da Medida  Provisória 2.200-2, em especial o seu § 2o , art. 10, como plenamente válidas: (i) as assinaturas eletrônicas das pessoas físicas e/ou jurídicas, nesse último caso através da assinatura do representante legal, para vinculação aos direitos e obrigações aqui estabelecidos; e (ii) as anuências eletrônicas através de plataformas na internet para tanto, sem o uso de certificados digitais, como prova de vinculação. 
            </li>
        </ul>

    </div>
</div>