<img class="w-100" src="../../../assets/img/about-us/Grupo 1739 (1).png">
<div class="banner-text mb-3">
    <h2>Quem somos</h2>
    <div class="d-flex pb-3">
        <img class="icon-text w-30" src="../../../assets/img/about-us/magic copiar.png">
        <h3>Fornecemos os melhores<br> serviços de transporte<br> de encomendas</h3>
    </div>
    <span>
        Somos uma empresa de <strong>hub logístico</strong> voltado para <strong>envio de
            encomendas</strong>,
        oferecendo <strong>agilidade</strong> e cotações com valores competitivos.
    </span>
</div>
<img class="w-100" src="../../../assets/img/about-us/Grupo 1699.png">
<div class="banner-text mb-5">
    <h2>Nossa história</h2>
    <span>
        Inspirados pelo espirito <strong>empreendedor</strong> dos lojistas empresários brasileiros, nós
        da Pic Envios nascemos
        com o intuito de <strong>facilitar</strong> e <strong>agilizar</strong> as vendas do
        comerciante.
    </span>
</div>

<hr>

<div class="container services">
    <div class="row pb-5">
        <img class="w-50"  src="../../../assets/img/services/pic-pc.png"  alt="etiqueta rápida">
        <h2>Etiqueta<br>Rápida</h2>
        </div>
        <span>
            Possuímos um sistema de etiqueta online pelo qual você consegue gerar a etiquetação da sua
            mercadoria diretamente pela internet, agilizando sua entrega na agência.
        </span>
</div>


<div class="container services">
    <div class="row pb-5">
        <h2>Rastreio</h2>
        <img class="w-50"  src="../../../assets/img/services/Correndo com a caixa (1) copiar.png"  alt="rastreio">
        </div>
        <span>
            Acompanhe sua mercadoria a qualquer momento de qualquer lugar.
        </span>
</div>

<div class="container services">
    <div class="row pb-5">
        <img class="w-50"  src="../../../assets/img/services/app.png"  alt="Aplicativo">
        <h2>Aplicativo</h2>
        </div>
        <span>
            Pelo <strong><i>App Pic Envios</i></strong> você tem na palma da mão todos os serviços que oferecemos em nosso site, de qualquer lugar do Brasil.
        </span>
</div>
<div class="container services">
    <div class="row pb-5">
        <h2>Posto<br>Avançado</h2>
        <img class="w-50"  src="../../../assets/img/services/deliveryman.png"  alt="Posto Avançado">
        </div>
        <span>
            Possuímos um serviço onde criamos uma estrutura dentro do ambiente cliente, agilizando a emissão das etiquetas, efetuando coletas programadas, economizando tempo no processo dos envios dae suas encomendas.
        </span>
</div>

<div class="container services">
    <div class="row pb-5">
        <img class="w-50"  src="../../../assets/img/services/Click Retire copiar.png"  alt="Aplicativo">
        <h2 style="padding-top: 1rem;">Click<br>Retire</h2>
        </div>
        <span>
            Faça seu pedido através de um marketplace ou e-commerce e economize na entrega utilizando nosso  <i>Click Retire</i>
        </span>
</div>