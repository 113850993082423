import { Component, OnInit } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.startLoad()

  }

  startLoad() {

    anime({
      targets: '.staggering-grid-demo .el',
      scale: [
        {value: .1, easing: 'easeOutSine', duration: 700},
        {value: 1, easing: 'easeInOutQuad', duration: 500}
      ],
      delay: anime.stagger(100, {grid: [15, 3], from: 'center'}),
      loop: true
    });
  }
}
