<div class="containerExpress" [class.master]="userInfo?.typeUserId == 1" [class.login]="isLogin()"  [class.cad]="isCad()" [class.painel]="isPanel" [class.notPanel]="!isPanel">
  <header class="header" [class.isHome]="isHome()">
    <app-header ></app-header>
  </header>
  <a href="https://api.whatsapp.com/send?phone=5511911246997&text=Ol%C3%A1%20Pic%20Envios!" target="_blank">
    <div class="environment-flag">
        <fa-icon [icon]="faWhatsapp"></fa-icon>
        <span> Dúvidas? <b>Fale com a gente!</b>
        </span>
      </div>
    </a> 
    <aside class="side-menu" >
      <app-side-menu [ngStyle]="{'display': router.url == '/login' || router.url == '/cadastro-franqueado'|| router.url == '/cadastre-se' || router.url == '/seja-um-franqueado'  || router.url == '/area-do-cliente' || router.url == '/' || router.url == '/home' || router.url == '/seja-parceiro' || router.url == '/quem-somos'  || router.url == '/termos-de-uso'  || router.url == '/trabalhe-conosco'   ? 'none' : 'block'}" ></app-side-menu>
    </aside>
    <main id="main" class="main">
      <router-outlet></router-outlet>
    </main>
    </div>
