import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { DateService } from '../date.service';

@Injectable({
    providedIn: 'root'
})
export class OrderCleaner {
    constructor(
        private http: HttpClient,
        private dateService: DateService
    ) { }

    cleanOrder(order) {
        let orderCleaned = Object.assign([], order)

        for (let o of orderCleaned) {
            o.date = o.data
            o.data = this.dateService.formatDate(o.data, 1)

            o.collapse = false
            o.modalidade = o.modalidade == 'Marketplace' ? 'MKTP' : o.modalidade
            switch (o.status) {
                case 'Aguardando Pagamento':
                    o.status = 'Aguardando'
                    o.statusClass = 'warning'
                    break
                case 'Aguardando Cancelamento':
                    o.status = 'Aguardando Cancel.'
                    o.statusClass = 'warning'
                    break
                case 'Aguardando Envio':
                    // o.status = 'Aguardando Envio'
                    o.statusClass = 'sucess'
                    break
                case 'Pago':
                    o.statusClass = 'sucess'
                    break
                case 'Cancelado':
                    o.statusClass = 'danger'
                    break
            }
            o.unitDto = o.unitDto[0]
            o.clientDto = o.clientDto[0]
            o.orderFreightDto = o.orderFreightDto[0]
            o.payTypeDto = o.payTypeDto[0]
            o.carrier = o.carrierDto[0]
            if (o.carrier?.name) {
                let freightArr = o.carrier?.name.split(" ")
            }

            o.deliveryTime = this.dateService.addDays(o.date, o.carrier?.deliveryTime)

        };
        return orderCleaned
    }

    cleanCarriers(data) {
        const carriers = data
        carriers.map(c => c = { id: c.id, name: c.name })
        return carriers
    }
}