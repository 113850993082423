import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cotacao-simples',
  templateUrl: './cotacao-simples.component.html',
  styleUrls: ['./cotacao-simples.component.scss']
})
export class CotacaoSimplesComponent implements OnInit {
  userInfo
  constructor(
    private actRoute: ActivatedRoute
  ) {
    this.actRoute.data.subscribe(v => {
      this.userInfo = v.userInfo
    })
  }

  ngOnInit(): void {
  }

}
