import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  dailyOffersCarousel: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    freeDrag: true,
    autoWidth: true,
    stagePadding: 0,
    dots: true,
    navSpeed: 700,
    autoHeight: true,
    navText: ['<img src="assets/img/chevron-left.png" alt="Retroceder item no carrosel">', '<img src="assets/img/chevron-right.png" alt="Avançar item no carrosel">'],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      768: {
        items: 1
      },
      992: {
        items: 1
      },
      1200: {
        items: 1
      }
    },
    nav: false
  }


  constructor() { }

  ngOnInit() {
  }

}
