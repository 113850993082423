<div class="wrapper" #calcSection>
    <h2 class="section-title">Calculadora de Frete</h2>
    <p class="section-subtitle">Faça a medição do seu pacote e insira os dados abaixo para comparar as cotações de entrega!</p>
    <ng-container #calcForm *ngIf="isForm">
        <div class="calc-form">
            <form class="form" autocomplete="off" [formGroup]="freightForm" (submit)="getFreight(freightForm)">
                <div class="row">
                    <input mask="00000-000" type="text" placeholder="CEP Origem" name="cepOrigem" #cepOrigem class="cepInput" formControlName="cepOrigem">
                    <input mask="00000-000" type="text" placeholder="CEP Destino" name="cepDestino" #cepDestino class="cepInput" formControlName="cepDestino">
                </div>
                <div class="my-5">
                    <div class="row">
                        <div class="form-field">
                            <label class="mt-auto" for="height">Quantidade</label>
                            <input type="text" id="height" name="height" formControlName="quantity" required>
                            <p class="input-placeholder">itens</p>
                        </div>
                        <div class="form-field">
                            <label class="mt-auto" for="height">Altura <small>(unitário)</small></label>
                            <input type="text" maxlength="3" id="height" name="height" formControlName="height" required>
                            <p class="input-placeholder">cm</p>
                        </div>
                        <div class="form-field">
                            <label class="mt-auto" for="width">Largura <small>(unitário)</small></label>
                            <input type="text" id="width" maxlength="3"  name="width" formControlName="width" required>
                            <p class="input-placeholder">cm</p>
                            
                        </div>

                        <div class="form-field">
                            <label class="mt-auto" for="length">Comprimento <small>(unitário)</small></label>
                            <input type="text" id="length" maxlength="3" name="length" formControlName="length" required>
                            <p class="input-placeholder">cm</p>
                        </div>
                        <div class="form-field">
                            <label class="mt-auto" for="weight">Peso <small>(unitário)</small></label>
                            <input type="text" id="weight" name="weight" #weight [suffix]="renderWeightSuffix(weight.value)" mask="separator.3" (keydown)="checkWeight($event)" formControlName="weight" required>
                            
                        </div>
                        <div class="form-field">
                            <label class="mt-auto" for="value">Valor da Carga <small><br>(por volume)</small></label>
                            <input type="text" currencyMask [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }" id="value" name="value" formControlName="value" required>
                            <p class="input-placeholder">R$</p>

                        </div>
                    </div>

                    <small style="color: #FD5E5E;">*Cotação demonstrativa. O tipo de usuário pode interfeir no valor final do pedido.</small>
                        <div class="row row-adjust">     
                        <div class="form-field">
                            <input formControlName="nfe" id="isDc" type="radio" (click)="forDc()">
                            <label for="isDc" class="input-radio mx-5 label-radio">Declaração Conteúdo
                            </label>
                        </div>
                    

                    <div class="form-field form-top clear-btn">
                        <small class="clear-btn text-secondary" (click)="clearCheck()"> Limpar
                        </small>
                </div>
            </div>
              
                </div>

                <div class="row center mt-5 pt-5">
                    <button type="submit" [disabled]="!freightForm.valid" class="btn btn-lg btn-white">
                        Fazer cálculo
                    </button>
                </div>

            </form>
        </div>
    </ng-container>
    <ng-container *ngIf="freightList.length || isLoading">
        <span class="d-block text-center" *ngIf="freightList.length && this.type == 2">Selecione uma opção de envio para cada volume.</span>
        <div class="calc-table" #calcTable id='calcTable'>
            <table class="table">
                <thead class="table-head">
                    <tr class="table-row">
                        <th class="table-cell"><span>Transportadora</span></th>
                        <th class="table-cell"><span>Modalidade</span></th>
                        <th class="table-cell"><span>Prazo <br>Estimado</span></th>
                        <th class="table-cell"><span>Valor <br>do frete</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="isLoading" class="loading-container" style="border: none;">
                        <app-loading></app-loading>
                    </tr>
                    <ng-container *ngIf="freightList.length > 0">
                        <tr class="table-row {{'calcLine-' + freight.item}}" *ngFor="let freight of freightList; let i = index" [ngStyle]="isCad == false && {'filter':'blur(6px)'} || isCad == true && {'filter': 'none'}"[class.disabled]="freight.erro" (click)="freight.active = !freight.active">
                            <td class="table-cell cell-img"><img [src]="freight.logo" [alt]="freight.carrier"></td> 
                            <td class="table-cell">{{ freight.modality }}</td>
                            <td class="table-cell" *ngIf="freight.erro">{{ freight.erro }}</td>
                            <td class="table-cell" *ngIf="!freight.erro">{{ freight.deliveryTime + ' a ' + (freight.deliveryTime + 1) }} dias</td>
                            <td class="table-cell" *ngIf="!freight.erro"><b>{{ freight.value | currency }}</b></td>
                        </tr>
                    </ng-container>
                    <div class="cad">
                     
                    <span class="text-cad" *ngIf="!isLoading && userCookies == '' && !isCad">
                        <fa-icon class="text-red" [icon]="faInfoCircle"></fa-icon>
                        Gostaria de ver as opções? <span class="text-red" routerLink="cadastre-se">Cadastre-se</span> </span>
                </div>
                </tbody>
            </table>
        </div>
        <div class="row space mt-5">
            <button class="btn btn-blue btn-withicon btn-withicon-toLeft" (click)="resetCalc()" [disabled]="freightList.length <= 0 || isLoading">
                <div class="icon">
                    <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
                </div>
                Refazer cálculo
            </button>
            <button  class="btn btn-blue btn-withicon btn-withicon-toLeft" (click)="goToAttendance()" *ngIf="userCookies != '' && userInfo?.typeUserId != 6 && userInfo?.outpost == 0 " [disabled]="freightList.length <= 0 || isLoading">
                <div class="icon" >
                    <fa-icon [icon]="faLongArrowAltRight"></fa-icon>
                </div>
                Ir para atendimento
            </button>
            <button  class="btn btn-blue btn-withicon btn-withicon-toLeft" (click)="goToAttendancePA()" *ngIf="userCookies != '' && userInfo?.outpost == 1 " [disabled]="freightList.length <= 0 || isLoading">
                <div class="icon" >
                    <fa-icon [icon]="faLongArrowAltRight"></fa-icon>
                </div>
                Ir para atendimento
            </button>
            <button  class="btn btn-blue btn-withicon btn-withicon-toLeft" (click)="cadVisible = !cadVisible" *ngIf="userCookies != '' && userInfo?.typeUserId === 6" [disabled]="freightList.length <= 0 || isLoading">
                <div class="icon" >
                    <fa-icon [icon]="faLongArrowAltRight"></fa-icon>
                </div>
                Criar Etiqueta
            </button>
       
    </div>
    </ng-container>

    <ng-container>
        <app-finish-user [_visible]="cadVisible" [_userInfo]="userInfo"></app-finish-user>
    </ng-container>
</div>
