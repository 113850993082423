<div class="reasons">

    <div class="card mb-5" style="width: 30rem;">
        <img style="width: 33%; margin: 15px 16px 0;" src="../../../assets/img/pic-env/caixa copiar.png">
        <div class="card-body">
            <h5 class="card-title">Envie vários pacotes de uma só vez</h5>
            <p class="card-text">Você pode enviar um só pacote, ou se preferir vários de uma só vez. Encontramos a transportadora certa para sua quantidade!</p>
        </div>
    </div>

    <div class="card mb-5" style="width: 30rem;">
        <img style="width: 33%; margin: 15px 16px 0;" src="../../../assets/img/pic-env/clock copiar.png">
        <div class="card-body">
            <h5 class="card-title">Sem demora para entregar suas encomendas</h5>
            <p class="card-text">Sempre terá uma transportadora parceira que irá corresponder à sua
                necessidade. Com a Pic envios é sem complicações!</p>
        </div>
    </div>

    <div class="card mb-5" style="width: 30rem;">
        <img style="width: 33%; margin: 15px 16px 0;" src="../../../assets/img/pic-env/delivery copiar.png">
        <div class="card-body ">
            <h5 class="card-title">Envio adaptado<br> ao seu tipo de encomenda</h5>
            <p class="card-text">Temos a solução ideal para envios pessoais. Para os empreendedores,
                ajudamos a enviar sua mercadoria direto para seus clientes.</p>
        </div>
    </div>

    <div class="card" style="width: 30rem;">
        <img style="width: 33%; margin: 15px 16px 0;" src="../../../assets/img/pic-env/price-tag copiar.png">
        <div class="card-body">
            <h5 class="card-title">Melhores preços do mercado</h5>
            <p class="card-text">Você pode escolher a melhor logística para o seu bolso. Quer saber mais sobre nossos preços? Clique e faça uma cotação na  <a routerLink="area-do-cliente">"Área do cliente"</a></p>
        </div>
    </div>
</div>
