<div class="fixed-head" [class.headVisible]="headVisible">
    <div class="header-container">
        <div class="logo-box">
            <div class="logo logo-white" aria-label="Logotipo Express" routerLink="" [inlineSVG]="'assets/svg/Logo_Branco_SVG.svg'"></div>
            <div class="logo logo-classic" aria-label="Logotipo Express" routerLink="" [inlineSVG]="'assets/svg/Logo_Principal_SVG.svg'"></div>
        </div>
        <nav class="nav-menu" >
            <ul class="menu">
                <li style="display: none;" class="menu-item"><a class="menu-link">Suporte</a></li>
                <li *ngIf="userSigned" class="menu-item box-submenu" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseOver($event)" >
                    <div class="menu-item-content">
                        <div class="user-icon">
                            <div class="icon">
                                <fa-icon [icon]="faUserCircle"></fa-icon>
                            </div>
                        </div>
                        <a class="menu-link">Olá, <b>{{ userInfo?.name }}</b>!</a>
                        <div class="submenu-toggler">
                            <div class="icon">
                                <fa-icon [class.toggler-up]="submenuVisible" [icon]="faChevronDown"></fa-icon>
                            </div>
                        </div>
                        <div class="badge-access" *ngIf="userInfo?.outpost != 1">
                            <div class="icon">
                                <fa-icon *ngIf="userInfo.typeUserId != 1" [icon]="faUserTie"></fa-icon>
                                <fa-icon *ngIf="userInfo.typeUserId == 1" [icon]="faUserCrown"></fa-icon>
                            </div>
                            {{ userInfo?.typeUser }}
                        </div>
                    </div>
                    <ul [class.active]="submenuVisible" class="submenu" >
                        <li class="menu-item" *ngIf="userInfo.outpost != 1">
                            <a [routerLink]="'painel/cotacao'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                                </div>
                                Cotação
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6 && userInfo?.typeUserId !=1 && userInfo?.outpost != 1">
                            <a [routerLink]="'painel/atendimento'" class="submenu-link">
                                <div class="icon">
                                <img style="width: 1.5rem;"
                                    src="../../../../assets/svg/customer-service-cinza.svg" alt="atendimento">
                                </div>
                                Atendimento
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.outpost == 1 && userInfo?.typeUserId !=6">
                            <a [routerLink]="'painel/cotacao-pa'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                                </div>
                                Cotação e Etiqueta
                            </a>
                        </li>

                        <li class="menu-item" *ngIf="userInfo?.typeUserId !=6">
                            <a [routerLink]="'painel/envios'" class="submenu-link" >
                                <div class="icon">
                                    <fa-icon [icon]="faBoxCheck"></fa-icon>
                                </div>
                                Envios
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.outpost != 1 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6">
                            <a [routerLink]="'painel/clientes'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faUsers"></fa-icon>
                                </div>
                                Clientes
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.outpost == 1 || userInfo?.typeUserId !=6">
                            <a [routerLink]="'painel/destinatarios'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faUsers"></fa-icon>
                                </div>
                                Destinatários
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.typeUserId == 0 || userInfo?.typeUserId == 2 && userInfo?.typeUserId != 6">
                            <a [routerLink]="'painel/expedition'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faShippingFast"></fa-icon>
                                </div>
                                Expedição
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.typeUserId == 5 || userInfo?.typeUserId == 1 && userInfo?.typeUserId != 6">
                            <a [routerLink]="'painel/minha-expedicao'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faShippingFast"></fa-icon>
                                </div>
                                Minha Expedição
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.typeUserId != 1 && userInfo?.outpost != 1 && userInfo?.typeUser == 3 && userInfo?.typeUserId != 6">
                            <a [routerLink]="'painel/postagem'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faPallet"></fa-icon>
                                </div>
                                Postagem
                            </a>
                        </li>
                        <li class="menu-item" >
                            <a [routerLink]="'painel/rastreio'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faMapMarker"></fa-icon>
                                </div>
                                Rastreio
                            </a>
                        </li>
                        <li class="menu-item" *ngIf="userInfo?.outpost != 1 && userInfo?.typeUserId != 3 && userInfo?.typeUserId != 5 && userInfo?.typeUserId != 6">
                            <a [routerLink]="'painel/minhas-unidades'" class="submenu-link">
                                <div class="icon">
                                <img style="width: 1.5rem;"
                                    src="../../../../assets/svg/commerce-azul.svg" alt="atendimento">
                                </div>
                                Unidades
                            </a>
                        </li>
                        <!-- <li class="menu-item" *ngIf="userInfo?.outpost != 1  && userInfo?.typeUserId != 5">
                            <a [routerLink]="'painel/cofre'" class="submenu-link">
                                <div class="icon">
                                    <fa-icon [icon]="faPiggyBank"></fa-icon>
                                </div>
                                Cofre
                            </a>
                    </li>
                    <li class="menu-item" *ngIf="userInfo?.typeUserId == 3">
                        <a [routerLink]="'painel/cofre'" class="submenu-link">
                            <div class="icon">
                                <fa-icon [icon]="faPiggyBank"></fa-icon>
                            </div>
                            Fatura
                        </a>
                    </li> -->
                        <li class="menu-item log-out">
                            <div class="submenu-separator"></div>
                            <a class="submenu-link" (click)="logOut()">
                                <div class="icon">
                                    <fa-icon [icon]="faSignOutAlt"></fa-icon>
                                </div>
                                Sair
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="!userSigned" [routerLink]="['login']" class="menu-item"><a class="menu-link">Login</a></li>
                <li *ngIf="!userSigned" [routerLink]="['cadastre-se']" class="menu-item"><a class="menu-link">Cadastre-se</a></li>
            </ul>
        </nav>
    </div>
</div>
