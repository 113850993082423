<div class="demo-content staggering-grid-demo">
        <div class="grid">
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
            <div class="small square el" style="transform: scale(1);"></div>
        </div>
        <div class="grid shadow">
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
            <div class="small square"></div>
        </div>
    </div>