import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { faSortAlt, faToggleOn, faToggleOff, faLongArrowAltLeft, faInfoCircle, faPlus, faTrashAlt, faLongArrowAltRight, faSearch, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import anime from 'animejs/lib/anime.es.js';
import { SharedService } from 'src/app/services/shared.service';
import { Volume } from 'src/app/models/volume.model';
import { ValidateCnpj } from 'src/app/services/validators/validate-cnpj.service';
import { ValidateCpf } from 'src/app/services/validators/validate-cpf.service';
import { StorageService } from 'src/app/services/storage.service';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ClientService } from 'src/app/services/client.service';
import { Observable, Observer, of } from 'rxjs';


@Component({
  selector: 'app-calc-panel',
  templateUrl: './calc-panel.component.html',
  styleUrls: ['./calc-panel.component.scss']
})


export class CalcPanelComponent implements OnInit {
  //Icons
  faSortAlt = faSortAlt
  faToggleOff = faToggleOff
  faToggleOn = faToggleOn
  faLongArrowAltLeft = faLongArrowAltLeft
  faInfoCircle = faInfoCircle
  faPlus = faPlus
  faTrashAlt = faTrashAlt
  faLongArrowAltRight = faLongArrowAltRight
  faSearch = faSearch
  faUserPlus = faUserPlus

  // Form
  freightForm: FormGroup
  volumesForm: FormArray;
  mask;

  tabz = [
    { title: 'First', content: 'First Tab Content' },
    { title: 'Second', content: 'Second Tab Content', active: true },
    { title: 'Third', content: 'Third Tab Content', removable: true },
    { title: 'Four', content: 'Fourth Tab Content', disabled: true }
  ];

  //Radio Buttons Nº da nota

  isDc = false;


  // Exhibition Variables
  isForm: boolean = true
  isLoading = false
  reverseLogistic: boolean = false
  freightList: any[] = []
  formUpdated = false
  activeFreight
  destinatario
  receiveData: any
  volume
  userInfo
  calculos = [{}]
  activeCalc = 1
  calculo;
  dados;
  dado = 'destinatario'
  data: any;
  userInformation: any;
  cadVisible = false

  //populateForms
  recipient;
  documento
  nota;
  chaveNFE;
  SerieNFE;
  inscricao;
  endereco;
  numero;
  complemento;
  bairro;
  cidade;
  estado;
  altura;
  peso;
  cep;
  cepFromCot;
  addressLock: boolean = false
  largura;
  quantidade;
  valor;
  comprimento;
  clientForm: FormGroup
  dc;
  search
  search$: Observable<any>
  isIe: boolean;
  ieValue: any;
  docValue
  dataAddress: any;
  observation: any;
  email: string;
  phone: string
  client: any;
  paymentDeadline: any;
  CadVisible = false
  isDoc: boolean;
  setSearchNum: string;
  contentForm: FormGroup
  items: Array<any> = [];
  newItem: any = {};
  docNum: any;


  @Input() set _calculo(v: any) {
    this.calculo = v
  }

  @Input() set _userInfo(v: any) {
    this.userInfo = v
  }
  @Input() set _receiveData(v: any) {
    this.receiveData = v

    if(this.receiveData){
      this.populateAtdFromCot()
      this.getAddress(this.receiveData.cepDestino)
    }
  }

  @Output() calcMounted = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private sharedService: SharedService,
    private actRoute: ActivatedRoute,
    private validateCnpj: ValidateCnpj,
    private validadeCpf: ValidateCpf,
    private clientService: ClientService
  ) {

    actRoute.data.subscribe(data => {
      this.userInfo = data.userInfo
    })

    this.sharedService.volume.subscribe(v => {
      if (v.id == this.calculo.id) this.addVolume()
    })
    this.sharedService.mountCalc.subscribe(v => {
      this.mountCalc()
    })
    actRoute.data.subscribe(data => {
      this.userInformation = data.userInfo;
    })

  }

  ngOnInit(): void {

    localStorage.setItem('dc', JSON.stringify(this.isDc))
    this.search$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search);
    }).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((query: string) => {
        if (query) {
          this.setSearchNum = query
          return this.clientService.getClientsO(this.userInformation.id, query)
            .pipe(
              map((data: any) => data.data ? data.data.map(data => data.name) || [] : [])
            );
        }

        return of([]);
      })
    );
    // this.createFreightFormTest();
    this.createFreightForm();
    this.createClientForm();
    this.createContentDeclaration();


    if (localStorage.getItem('data') == null) {
      this.populateAtd();
    } else if (localStorage.getItem('data') != null) {
      this.populateAtdFromCot();
      this.populateAddressFromCot()
    }

    this.freightForm.get('cepDestino').valueChanges.subscribe(x => {
      x.length == 8 ? this.getAddress(x) : null
    });

  }

  onSelect($e) {
    this.setSearch($e.value)
  }

  setSearch($e) {
    const search = $e
    this.getClients(search)
  }

  async getClients(search) {
    const params = {
      "userId": this.userInformation.id,
      "search": this.search || this.setSearchNum || '',
      "pageNumber": 1,
      "rowsPerPage": 10
    }
    this.search = search
    const response = await this.clientService.getClients(params.userId, params.search, params.pageNumber, params.rowsPerPage)
    this.client = response.data[0]
    this.populateClient()
    //this.getDeadlineByClient(this.client.id)
  }



  createContentDeclaration() {
    this.contentForm = this.formBuilder.group({
      content: [null, Validators.required],
    typePacking: [null, Validators.required],
      quantity: [null, Validators.required],
      value: [null, Validators.required],
    })


  }


  addItems() {

    if (this.contentForm.valid == true) {
      this.items.push(this.contentForm.value);
      this.newItem = {};

      const prod = document.getElementById('content') as HTMLInputElement;
      const embalagem = document.getElementById('typePacking') as HTMLInputElement;
      const quantidade = document.getElementById('quantity') as HTMLInputElement;
      const valor = document.getElementById('valor') as HTMLInputElement;

      prod.value = null
      embalagem.value = null
      quantidade.value = null
      valor.value = null

      localStorage.setItem('contentData', JSON.stringify(this.items))
    }
  }

  removeItem(index) {
    this.items.splice(index, 1); // remove 1 item at ith place
  }


  createClientForm() {
    this.clientForm = this.formBuilder.group({
      cnpj: [null, Validators.required],
      nome: [null, Validators.required],
      loja: [null, Validators.required],
      email: [null, [Validators.email]],
      paymentClient: [null],
      responsavel: [null]
    })
  }

  populateClient() {

    this.clientForm.patchValue({
      cnpj: this.client.documentNumber,
      nome: this.client.name,
      loja: this.client.razaoSocial,
      email: this.client.email,
      responsavel: this.client.nameSender
    })

    localStorage.setItem('data-rem', JSON.stringify(this.client))
  }
  async getDeadline() {
    const paymentDeadline = await this.clientService.getPaymentDeadline()
    this.paymentDeadline = paymentDeadline
  }

  async getDeadlineByClient(id) {
    const paymentDeadline = await this.clientService.getDeadlineByClient(id)
    this.paymentDeadline = paymentDeadline
  }


  renderWeightSuffix(value) {
    if (value && value.slice(0, 1) == 0) {
      return ' gramas'
    } else {
      return ' kg'
    }

  }
  checkWeight(e, i) {
    const weight = this.volumesFormFor[i].value.weight
    const newValue = weight + '.'

    let weightControls: FormGroup = this.volumesFormFor[i] as FormGroup
    // let weightControls
    if (e.key === ',') {
      weightControls.controls.weight.patchValue(newValue)
      event.preventDefault()
    }
    // Condição 1: Se peso for apagado
    // Condição 2: Se peso for zero
    // Condição 3: Se valor digitado for diferente de zero e diferente de backspace depois do ponto
    if (e.key === "0" && weight === '' ||
      e.key === "0" && weight === "0" ||
      e.key != '0' && e.key != "Backspace" && weight === "0"
    ) {

      weightControls.controls.weight.patchValue(weight ? newValue + e.key : '0.')

      event.preventDefault()
    }
  }

  populateAtd() {

    this.setPanelForm();
    this.createFreightForm()
  }

  populateAtdFromCot() {

    //popula os campos baseado nos dados colocados na cotação
    // this.data = JSON.parse(localStorage.getItem('data'))

    //volume

    this.altura = Number(this.receiveData.height);
    this.peso = Number(this.receiveData.weight);
    this.cep = this.receiveData.cepDestino;
    this.largura = Number(this.receiveData.width);
    this.quantidade = Number(this.receiveData.quantity);
    this.comprimento = Number(this.receiveData.length);
    this.valor = Number(this.receiveData.value);


    this.createFreightForm()
  }

  populateAddressFromCot() {

    //popula os campos baseado nos dados colocados na cotação
    this.dataAddress = JSON.parse(localStorage.getItem('endereco'))

    //volume

    this.bairro = this.dataAddress.neighborhood;
    this.cidade = this.dataAddress.city;
    this.estado = this.dataAddress.state;
    this.endereco = this.dataAddress.address;

    this.setPanelForm()
  }



  createFreightForm() {

    if (this.data == undefined) {

      this.freightForm = this.formBuilder.group({
        cepOrigem: [this.userInformation?.zipCode || null, [Validators.required]],
        cepDestino: [ this.receiveData?.cepDestino || null, [Validators.required]],
        volumesForm: this.formBuilder.array([this.createFields()]),
      })

      this.volumesForm = this.freightForm.get('volumesForm') as FormArray;
      this.setPanelForm()
    } else if (this.data != undefined) {
      this.freightForm = this.formBuilder.group({
        cepOrigem: [this.userInformation?.zipCode || null, [Validators.required]],
        cepDestino: [this.receiveData?.cepDestino, [Validators.required]],
        volumesForm: this.formBuilder.array([this.createFields()]),
      })

      this.volumesForm = this.freightForm.get('volumesForm') as FormArray;
      this.setPanelForm()
    }
  }

  createFreightFormTest() {
    this.freightForm = this.formBuilder.group({
      cepOrigem: [this.userInformation?.zipCode || null, [Validators.required]],
      cepDestino: ['22011970', [Validators.required]],
      volumesForm: this.formBuilder.array([this.createFieldsTest()]),
    })
    this.volumesForm = this.freightForm.get('volumesForm') as FormArray;

    this.setPanelFormTest()
  }

  setPanelForm() {

    if (this.dataAddress == undefined) {
      this.freightForm = this.formBuilder.group({
        ...this.freightForm.controls,
        recipient: [null, Validators.required],
        documentNumber: [null, [Validators.nullValidator, this.validateCNPJ.bind(this)]],
        address: [null, Validators.required],
        nota: [null, Validators.nullValidator],
        number: [null, Validators.required],
        neighborhood: [null, Validators.required],
        city: [null, Validators.required],
        uf: [null, Validators.required],
        email: [null],
        phone: [null],
        complemento: [null],
        keyNFE: [null],
        serieNFE: [null],
        registration: [null, Validators.nullValidator],
        observation: [null]
      })
      this.formUpdated = true
    } else if (this.dados != undefined && this.dataAddress == undefined) {
      this.freightForm = this.formBuilder.group({
        ...this.freightForm.controls,
        recipient: [this.recipient, Validators.required],
        documentNumber: [this.documento, [Validators.required, this.validateCNPJ.bind(this)]],
        address: [this.endereco, Validators.required],
        nota: [this.nota, Validators.nullValidator],
        number: [this.numero, Validators.required],
        neighborhood: [this.bairro, Validators.required],
        city: [this.cidade, Validators.required],
        uf: [this.estado, Validators.required],
        complemento: [this.complemento],
        keyNFE: [this.chaveNFE],
        email: [this.email],
        phone: [this.phone],
        serieNFE: [this.SerieNFE],
        registration: [this.inscricao, Validators.required],
        observation: [this.observation]
      })
      this.formUpdated = true

    } else if (this.dados == undefined && this.dataAddress != undefined) {
      this.freightForm = this.formBuilder.group({
        ...this.freightForm.controls,
        address: [this.endereco, Validators.required],
        neighborhood: [this.bairro, Validators.required],
        city: [this.cidade, Validators.required],
        uf: [this.estado, Validators.required],


      })
      this.formUpdated = true
    }
  }

  setPanelFormTest() {
    this.freightForm = this.formBuilder.group({
      ...this.freightForm.controls,
      recipient: ['Fernando de Oliveira Laurino', Validators.required],
      //email: ['fernandolaurino28@gmail.com', [Validators.required, Validators.email]],
      documentNumber: ['51197251000149', Validators.required],
      address: ['Rua Felicio Geronazo', Validators.required],
      nota: ['07033040', Validators.nullValidator],
      number: ['108', Validators.required],
      neighborhood: ['Ponte Grande', Validators.required],
      city: ['Guarulhos', Validators.required],
      uf: ['SP', Validators.required],
      complement: [null, Validators.nullValidator],
    })
    this.formUpdated = true
  }

  fadeTable() {
    setTimeout(() => {

      this.isLoading = false
      anime({
        targets: '.table-row',
        opacity: 1,
        delay: anime.stagger(300),
        easing: 'spring(1, 80, 10, 0)'
      })
    }, 0);
  }

  createFields() {
    if (this.receiveData == undefined ) {
      return this.formBuilder.group({
        quantity: [1, [Validators.required, Validators.min(1)]],
        height: [null, [Validators.required]],
        width: [null, [Validators.required]],
        value: [null, [Validators.nullValidator]],
        length: [null, [Validators.required]],
        weight: [null, [Validators.required]],
        semCPF: [null]
      })
    } else {
      return this.formBuilder.group({
        quantity: [this.quantidade, [Validators.required, Validators.min(1)]],
        height: [this.altura, [Validators.required]],
        width: [this.largura, [Validators.required]],
        value: [this.valor, [Validators.nullValidator]],
        length: [this.comprimento, [Validators.required]],
        weight: [this.peso, [Validators.required]]
      })
    }
  }


  createFieldsTest() {
    return this.formBuilder.group({
      quantity: [1, [Validators.required, Validators.min(1)]],
      height: [25, [Validators.required]],
      width: [10, [Validators.required]],
      value: [60, [Validators.nullValidator]],
      length: [15, [Validators.required]],
      weight: [2, [Validators.required]]
    })

  }

  get f() { return this.volumesForm.controls; }

  get volumesFormFor() {
    return this.volumesForm && this.volumesForm.controls ? this.volumesForm.controls : [{
      quantity: null,
      height: null,
      width: null,
      value: null,
      length: null,
      weight: null,
      semCPF: null
    }]
  }

  addVolume(): void {

    this.volumesForm = this.freightForm.get('volumesForm') as FormArray;

    this.volumesForm.push(this.createFields());
  }

  addQuantity(i) {

    let controls: FormGroup = this.volumesFormFor[i] as FormGroup


    controls.patchValue({
      quantity: this.volumesFormFor[i].value.quantity + 1
    })
  }

  removeQuantity(i) {
    let controls: FormGroup = this.volumesFormFor[i] as FormGroup


    controls.patchValue({
      quantity: this.volumesFormFor[i].value.quantity - 1
    })
  }

  async getAddress(x) {

    let cepDestino = x
    const response: any = await this.orderService.getAddress(cepDestino)
    if (response?.sucess) {

      this.patchDestinatarioForm(x, response.data)
    }
    this.addressLock = true
  }

  patchDestinatarioForm(cep, data) {
    this.freightForm.patchValue({
      address: data.address,
      neighborhood: data.neighborhood,
      city: data.city,
      uf: data.state
    })
  }

  mountCalc() {
    const values = this.freightForm.value

    const invalid = [];
    const controls = this.freightForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    ;

    for (let i = 0; i < this.volumesFormFor.length; i++) {
      if (values.nota != "0" && values.nota != "DC") {
        this.volumesFormFor[i].value.nfe = true
      } else {
        this.volumesFormFor[i].value.nfe = false;
        values.registration = 'Isento'
      }

    }

    for (let i = 0; i < this.volumesFormFor.length; i++) {
      if (values.documentNumber != null && values.documentNumber != "Indefinido") {
        this.volumesFormFor[i].value.semCPF = false
      } else {
        this.volumesFormFor[i].value.semCPF = true
      }
    }


    const destinatario = {
      "recipient": values.recipient,
      "address": values.address,
      "number": values.number,
      "documentNumber": values.documentNumber,
      "nota": values.nota,
      "city": values.city,
      "complement": values.complemento,
      "uf": values.uf,
      "neighborhood": values.neighborhood,
      "keyNFE": values.keyNFE,
      "serieNFE": values.serieNFE,
      "stateRegistration": values.registration,
      "observation": values.observation,
      "email": values.email,
      "phone": values.phone,
    }


    const items: Volume[] = values.volumesForm.map(v =>
      v = new Volume(
        values.cepDestino,
        v.quantity,
        Number(v.weight),
        Number(v.length),
        Number(v.height),
        Number(v.width),
        "N",
        v.value,
        "N",
        v.nfe,
        v.semCPF
      ))
    const volume = Object.assign({}, {
      cepOrigem: values.cepOrigem,
      destinatario: destinatario,
      volume: items,
      volumeLength: items.length,
      valid: this.freightForm.valid
    })
    this.calcMounted.emit(volume)

  }


  forDc() {
    this.isDc = !this.isDc;
    const note = document.getElementById('nota') as HTMLInputElement;
    if (this.isDc == true) {
      const chave = document.getElementById('keyNFE') as HTMLInputElement;
      const serie = document.getElementById('serieNFE') as HTMLInputElement;
      const ie = document.getElementById('inscricao') as HTMLInputElement;
      const ieCheck = document.getElementById('isIe') as HTMLInputElement;
      note.value = 'DC'
      chave.value = ''
      serie.value = '';
      ie.value = 'Isento';
      ieCheck.checked = true;
      this.isIe = true;
     this.forIe();
      localStorage.setItem('dc', JSON.stringify(this.isDc))
    }
  }
  clearCheck() {

    this.isDc = false;
    const dc = document.getElementById('isDc') as HTMLInputElement;
    const note = document.getElementById('nota') as HTMLInputElement;
    const ie = document.getElementById('inscricao') as HTMLInputElement;
    const ieCheck = document.getElementById('isIe') as HTMLInputElement;
    this.freightForm.value.nota = null
    dc.checked = false;
    note.value = ''
    this.dc = null;
    ie.value = '';
    this.isIe = false
    ieCheck.checked = false;
    localStorage.setItem('dc', JSON.stringify(this.isDc))
  }

  forDoc() {
    this.isDoc = true;
    const doc = document.getElementById('documentNumber') as HTMLInputElement;
    if (this.isDoc == true) {
      doc.value = 'Indefinido';
      this.docValue = doc.value
    } else if (this.isDoc == false) {
      doc.value = null;
    }
  }
  clearCheckDoc() {
    this.isDoc = false;
    const doc = document.getElementById('isDoc') as HTMLInputElement;
    const docum = document.getElementById('documentNumber') as HTMLInputElement

    if (!this.isDoc) {
      this.isDoc = false
      doc.checked = false;
      docum.value = null
      this.freightForm.value.documentNumber = null
      this.docValue = null
    }
  }


  forIe() {
    this.isIe = true;
    const ie = document.getElementById('inscricao') as HTMLInputElement;
    if (this.isIe == true || this.docNum.length > 11) {
      ie.value = 'Isento';
      this.ieValue = ie.value
    } else if (this.isIe == false) {
      ie.value = null;
    }
  }
  clearCheckIe() {
    this.isIe = false;
    const ie = document.getElementById('isIe') as HTMLInputElement;
    const state = document.getElementById('inscricao') as HTMLInputElement

    if (!this.isIe) {
      this.isIe = false
      ie.checked = false;
      state.value = ''
      this.freightForm.value.registration = null
      this.ieValue = null
    }
  }


  validateCNPJ = (control: AbstractControl) => {
    this.docNum = control.value

    if (control.value) {
      if (control.value.length == 14) {

        let cnpj = control.value
        if (cnpj) {
          const result = this.validateCnpj.validate(cnpj)
          return result ? null : { invalid: true }
        }
        return true;
      } else if (control.value.length == 11) {

        let cpf = control.value
        if (cpf) {

          const result = this.validadeCpf.validate(cpf)
          return result ? null : { invalid: true }



        }
        return true
      }
    }

  }

}
