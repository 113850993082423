<div class="footer">
    <div class="wrapper">
        <div class="footer-container">
            <div class="footer-column">
                <nav class="footer-nav">

                    <ul>
                        <li class="margin"> </li>
                        <li><a class="footer-link" routerLink="">Pagina Inicial</a></li>
                        <!-- <li><a class="footer-link" routerLink="/quem-somos">Quem somos</a></li> -->
                        <li><a class="footer-link" routerLink="">Suporte</a></li>
                        <!-- <li><a class="footer-link" routerLink="/termos-de-uso">Termos de uso</a></li> -->
                        <li><a class="footer-link" routerLink="/trabalhe-conosco">Trabalhe conosco</a> </li>
                        <!-- <li><a class="footer-link" routerLink="/seja-parceiro">Seja Parceiro</a> </li> -->
                    </ul>
                </nav>
            </div>
            <div class="footer">
                <div class="footer-info">
                    <div class="footer-info-row footer-link">
                    </div>
                    <a class="footer-info-row footer-link" href="https://api.whatsapp.com/send?phone=5511911246997&text=Ol%C3%A1%20Pic%20Envios!" target="_blank" placement="bottom" ngbTooltip="Enviar uma mensagem!">
                        <div class="icon">
                            <fa-icon [icon]="faEnvelope"></fa-icon>
                        </div>
                        contato@picenvios.com.br
                    </a>
                    <a *ngIf="router.url != '/seja-um-franqueado'" class="footer-info-row footer-link" href="https://api.whatsapp.com/send?phone=5511911246997&text=Ol%C3%A1%20Pic%20Envios!" target="_blank" placement="bottom" ngbTooltip="Enviar uma mensagem!">
                        <div class="icon">
                            <fa-icon [icon]="faWhatsapp"></fa-icon>
                        </div>
                        (11) 91124-6997
                    </a>
                    <a *ngIf="router.url == '/seja-um-franqueado'" class="footer-info-row footer-link" href="https://api.whatsapp.com/send?phone=5511971460844&text=Ol%C3%A1%20Pic%20Envios!" target="_blank" placement="bottom" ngbTooltip="Enviar uma mensagem!">
                        <div class="icon">
                            <fa-icon [icon]="faWhatsapp"></fa-icon>
                        </div>
                        (11) 97146-0844
                    </a>
 
                    <div class="social-media">
                        <a class="footer-link" target="_blank" href="https://www.facebook.com/profile.php?id=100081172035666">
                            <div class="icon icon-facebook">
                                <fa-icon [icon]="faFacebook"></fa-icon>
                            </div>
                        </a>
                        <a class="footer-link" target="_blank" href="https://www.instagram.com/pic_envios">
                            <div class="icon icon-instagram">
                                <fa-icon [icon]="faInstagram"></fa-icon>
                            </div>
                        </a>
                        <a class="footer-link" target="_blank" href="https://twitter.com/pic_envios">
                            <div class="icon icon-youtube">
                                <fa-icon [icon]="faTwitter"></fa-icon>
                            </div>
                        </a>
                    </div>
                </div>
            </div>


        </div>
    </div>

</div>
<div class="white-bar">
    <div class="site-seals">
        <div class="seal-box">
           <small><strong>Pic envios  - 2022 </strong> CNPJ: 32.618.607/0001-26 </small>
        </div>
    </div>
</div>