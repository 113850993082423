<div class="seja-parceiro">
    <div class="page-head page">
        <h1 class="text-center">Seja Um Parceiro</h1>
        <p class="text-center">Cadastre-se e faça parte de uma das maiores centrais de transportadora do Brasil!</p>
    </div>
    <div class="page-content">
        <ul class="page-vantagens wrapper">
            <li>
                <div class="icon">
                    <fa-icon [icon]="faCalculatorAlt"></fa-icon>
                </div>
                <span>Cotações Simultâneas</span>
                <p>Aqui você consulta diversas transportadoras para encontrar o frete ideal para você.</p>
            </li>
            <li>
                <div class="icon">
                    <fa-icon [icon]="faHandHoldingUsd"></fa-icon>
                </div>
                <span>Compare as diversas opções de fretes</span>
                <p>Trabalhamos com as melhores empresas de logísticas que alcançam todo o Brasil.</p>
            </li>
            <li>
                <div class="icon">
                    <fa-icon [icon]="faMoneyBillAlt"></fa-icon>
                </div>
                <span>Diversos meios de pagamento e preços</span>
                <p>Pague usando as melhores e mais seguras formas de pagamento, tudo rápido e fácil.</p>
            </li>
            <li>
                <div class="icon">
                    <fa-icon [icon]="faSearchLocation"></fa-icon>
                </div>
                <span>Monitore a movimentação dos seus envios</span>
                <p>Rastreie os seus envios por qualquer transportadora.</p>
            </li>
        </ul>
        <div class="page-transportadoras">
            <div class="wrapper">
                <h2>Transportadoras</h2>
                <app-carriers-slide></app-carriers-slide>
            </div>
        </div>
        <div class="page-form wrapper">
            <h1>Cadastre-se</h1>
            <p class="text-center">Preencha o formulário abaixo e em breve nossa equipe entrará em contato.</p>
            <form class="form" [formGroup]="partnerForm" (ngSubmit)="buildForm(partnerForm)">
                <div class="row mx-0 justify-content-between mt-5 pt-5">
                    <div class="form-field">
                        <label for="name">Nome</label>
                        <input type="text" name="name" formControlName="name" id="name">
                    </div>
                    <div class="form-field">
                        <label for="cnpj">CNPJ</label>
                        <input type="text" name="cnpj" formControlName="cnpj" id="cnpj" mask="00.000.000/0000-00">
                    </div>
                    <div class="form-field">
                        <label for="phone">Telefone</label>
                        <input type="text" name="phone" formControlName="phone" id="phone">
                    </div>
                </div>
                <div class="row mx-0 mb-3 pt-5 justify-content-end">
                    <div class="form-field form-checkbox checkcontainer">
                        <label for="wpp" class="input-checkbox mx-1">
                            Esse número possui <b>WhatsApp</b> ?
                            <input name="wpp" id="wpp" type="checkbox">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary btn-border d-block mx-auto">Enviar</button>
            </form>
        </div>
    </div>
</div>

<app-footer></app-footer>