import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FreightCleaner {

    constructor() {
    }
    cleanTrackingInfo(data) {
        let evento = data.objeto.evento

        for (let e of evento) {
            switch (e.tipo) {
                case 'PO':
                    e.statusTrack = 1
                    break
                case 'OEC':
                    e.statusTrack = 2
                    break
                case ('RO' || 'DO' || 'BDI' || 'FC' || 'LDE' || 'LDI'):
                    e.statusTrack = 3
                    break
                case 'BLQ':
                    e.statusTrack = 4
                    break
                case 'EST':
                    e.statusTrack = 5
                    break
                case 'BDE':
                    e.statusTrack = 6
                    break
                default:
                    e.statusTrack = 3
                    break
            }
        }

        return evento

    }
}