import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faCalculatorAlt, faArrowAltFromLeft, faUserHeadset, faMapMarker, faHouse, faBoxCheck, faUsers,faUser, faSignOutAlt, faUserCircle, faStore, IconDefinition, faPallet, faPiggyBank, faShippingFast } from '@fortawesome/pro-light-svg-icons';
import { UnitService } from 'src/app/services/unit.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';



@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  // Icons
  faCalculatorAlt = faCalculatorAlt
  faBoxCheck = faBoxCheck
  faUser = faUser
  faUsers = faUsers
  faSignOutAlt = faSignOutAlt
  faUserCircle = faUserCircle
  faStore = faStore
  faPallet = faPallet
  faMapMarker = faMapMarker
  faPiggyBank = faPiggyBank
  faShippingFast = faShippingFast
  faArrowAltFromLeft = faArrowAltFromLeft
  faUserHeadset = faUserHeadset
  faHouse = faHouse

  userSigned
  userInfo
  route;
  rotaPag;
  isSVG = false


  $route = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map(e => e.url)
  )
  showTag: boolean;

  constructor(
    private sharedService: SharedService,
    private unitService: UnitService,
    private userService: UserService,
    public router: Router
  ) {
    sharedService.userSigned.subscribe(v => {
      this.userSigned = v
      this.userInfo = this.userService.getUserData()
    })
    this.$route.subscribe(v => {
      this.route = v

      if (v != '/painel/atendimento' && v != '/painel/pagamento') {
        localStorage.removeItem('dados')
      }
    })

  }

  ngOnInit(): void {
    this.rotaPag = this.router.url

    window.addEventListener("mouseup", this.closeNav);
  }

  logOut() {

    this.sharedService.changeUserSigned(false)
    this.userService.logOut()
  }

  openNav() {
    let sidebar = document.getElementById("mySidebar")!;
    sidebar.style.width = '250px'
    this.showTag = true
  }

  closeNav() {
    let sidebar = document.getElementById("mySidebar")!;
    sidebar.style.width = '80px'
    this.showTag = true
  }


}


