import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnitCleanerService {

  constructor() { }

  cleanLoja(units:any) {
    
    let unitArr = []
    for (const data of units){
      const users = data.userDto

      const user = users.map(u => {
        return {
          id: u.id,
          name: u.name,
          unit: u.unit,
          unitId: u.unitId,
          type: u.typeUserId,
          email: u.email,
          documentNumber: u.documentNumber,
        }
      });

      let manager = user.filter(u => u.type == 2) 
      let managerId = user.find(u => u.type == 2)
      let operational = user.filter(u => u.type == 3) 

      unitArr.push({
        id: data.id,
        name: data.name,
        status: 'ativo',
        manager: manager,
        operational: operational,
        managerId:  managerId,
        outpost: data.outpost
      })
    };
    
    return unitArr
  }
}
