import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_Routes } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private sharedService: SharedService,
    private router: Router
  ) { }

  authenticate(values) {

    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/Auth/Login`, {
      email: values.email,
      password: values.password
    }).toPromise()
      .then(response => {
        if (response.status) {
          this.handleUserInfo(Object.assign({}, {
            ...response,
            email: values.email,
            password: values.password
          }))

          this.sharedService.changeUserSigned(true)
          this.storageService.add('userSigned', true)
        }
        return response
      }).catch(error => {

        this.sharedService.changeUserSigned(false)
        this.storageService.remove('userSigned')
        return false
      })
  }

  async handleUserInfo(data) {


    let userData: any = {
      email: data.email,
      id: data.idUser,
      typeUserId: data.typeUserId,
      typeUser: data.typeUser,
      unitId: data.unitId,
      unit: data.unit,
      name: data.name,
      password: data.password,
      zipCode: data.zipCode,
      outpost: data.outpost,
      clientId: data.clientId,
      documentNumber: data.documentNumber,
      paymentCondition: data.paymentConditionId,
      paymentDeadlineId: data.paymentDeadlineId,
      token: data.token
    }

    this.sharedService.changeUnitActive({ id: userData.unitId })
    userData = btoa(JSON.stringify(userData))

    this.storageService.add("userInfo", userData)
  }

  logOut() {

    this.sharedService.changeUserSigned(false)
    this.sharedService.changePanel(false)
    this.storageService.remove('userSigned')
    this.storageService.remove('userInfo')
  }

  async checkLoggedIn() {

    let userCookies = this.getUserData()

    if (userCookies && userCookies != 'null') {

      const userInfo = await this.authenticate(userCookies)
      return true
    } else {
      this.logOut()
      return false
    }
  }

  getUserData(control?) {


    let userCookies = this.storageService.get('userInfo')
    userCookies = userCookies ? JSON.parse(atob(userCookies)) : null


    if (userCookies) {

      control ? null : this.sharedService.changeUnitActive({ id: userCookies.unitId })

      return userCookies
    } else {
      return undefined
    }
  }


  mailing(params) {
    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/Mailing`, params)
      .toPromise()
      .then(response => {
        return response
      }).catch(error => {
        return { sucess: false }
      })
  }


  updateCotacao(cotacao) {
    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/User/UpgradeCotacao`, cotacao)
      .toPromise()
      .then(response => {

        return response
      }).catch(e => console.error(e))
  }

}
