import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


interface Unity {
  name: string
  address: string
  map: string
}


@Component({
  selector: 'app-unity-card',
  templateUrl: './unity-card.component.html',
  styleUrls: ['./unity-card.component.scss']
})

export class UnityCardComponent implements OnInit {


@Pipe({
  name: 'safe'
})

  unityAdd: Unity [] = [
    {
      name: 'Galeria Pagé',
      address: 'Rua Comendador Afonso Kherlakian, 79 - 1º andar',
      map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.7445531229273!2d-46.633695284407196!3d-23.541688366752503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58f8a4fc60c5%3A0xbc6941f5a2abc5d1!2sR.%20Comendador%20Afonso%20Kherlakian%2C%2079%20-%20Centro%20Hist%C3%B3rico%20de%20S%C3%A3o%20Paulo%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001023-040!5e0!3m2!1spt-BR!2sbr!4v1654022110128!5m2!1spt-BR!2sbr'
    },
    {
      name: 'Galeria Pagé Bras',
      address: 'Rua Hannemann, 415 - 1º subsolo',
      map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.0148647330325!2d-46.6215605844076!3d-23.53196776639402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58ef087ad185%3A0x31c2acadb2286f42!2sRua%20Hannemann%2C%20415%20-%20Canind%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003031-040!5e0!3m2!1spt-BR!2sbr!4v1654022291663!5m2!1spt-BR!2sbr'
    },
    {
      name: 'Shopping 123 da Oriente',
      address: 'Rua Oriente , 123 - Térreo, Loja 57',
      map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.854646551003!2d-46.62327448461554!3d-23.537729784694594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58e4957fc721%3A0xabb499345652434!2sR.%20Oriente%2C%20123%20-%20Br%C3%A1s%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003016-001!5e0!3m2!1spt-BR!2sbr!4v1655219182922!5m2!1spt-BR!2sbr'
    },
    {
      name: 'Shopping Vautier Popular',
      address: 'Rua Vautier, 248 - 1ºo andar, Índigo 03',
      map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.9585091429517!2d-46.62218118440719!3d-23.533994666468672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58e578c2157d%3A0x4103cbc2f6da2213!2sAv.%20Vautier%2C%20248%20-%20Br%C3%A1s%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003032-000!5e0!3m2!1spt-BR!2sbr!4v1654022364102!5m2!1spt-BR!2sbr'
    },
    {
      name: 'Shopping Vautier Premium',
      address: 'Rua Tiers, 184 - 3° Andar, Loja 3047',
      map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.965839853783!2d-46.62528886993417!3d-23.533731017497306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58e5602cdb25%3A0x513a3a1429f39ea2!2sR.%20Tiers%2C%20184%20-%20Br%C3%A1s%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003031-000!5e0!3m2!1spt-BR!2sbr!4v1654022399893!5m2!1spt-BR!2sbr'
    },
    {
      name: 'Shopping Porto',
      address: 'Rua Tiers, 282 - 1° Andar, Loja 1023  ',
      map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.9827332568493!2d-46.62404277118879!3d-23.533123435749904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58ef977ce68d%3A0x27bd0f74c00105af!2sR.%20Tiers%2C%20282%20-%20Canind%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003031-000!5e0!3m2!1spt-BR!2sbr!4v1655219360394!5m2!1spt-BR!2sbr'

    },
    {
      name: 'Shopping Canindé',
      address: 'Rua Tiers, 341 - 1° Andar, Loja 1171  ',
      map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.005569718208!2d-46.62297788461552!3d-23.532302084697275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58efba96ad0d%3A0x35d4a5a7b862c415!2sR.%20Tiers%2C%20341%20-%20Canind%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003031-000!5e0!3m2!1spt-BR!2sbr!4v1655219428632!5m2!1spt-BR!2sbr'

    },
    {
      name: 'Feira da Concórdia',
      address: 'Rua da Juta, 47 - Corredor V, Loja 93',
      map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.7401863841365!2d-46.62019138447602!3d-23.541845366758203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58e213daaaab%3A0xcd26d3021b863c64!2sR.%20da%20Juta%2C%2047%20-%20Br%C3%A1s%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003010-010!5e0!3m2!1spt-BR!2sbr!4v1664560400444!5m2!1spt-BR!2sbr'

    },
    {
      name: 'Feira da Madrugada',
      address: 'Rua São Caetano, 812 - 1º Andar, Loja A06 Bl Satélite',
      map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.90262119288!2d-46.62741978543374!3d-23.536004584695455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce58fa7abf382b%3A0x7dfc5698216befb8!2sR.%20S%C3%A3o%20Caetano%2C%20812%20-%20Luz%2C%20S%C3%A3o%20Paulo%20-%20SP!5e0!3m2!1spt-BR!2sbr!4v1664561010925!5m2!1spt-BR!2sbr'

    }

  ]



  constructor(private sanitizer: DomSanitizer) {
    document.cookie = "witcher=Geralt; SameSite=None; Secure"
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit(): void {
  }

  customOptions: OwlOptions  = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: false,
    autoplayHoverPause: true,
    autoplayTimeout: 2000,
    dots: true,
    rewind: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
  }

}
