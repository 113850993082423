import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { API_Routes } from 'src/environments/environment';
import { UnitCleanerService } from './cleaners/unit.cleaner.service';
import { SharedService } from './shared.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  activeUnit

  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    private unitCleaner: UnitCleanerService,
    private userService: UserService
  ) {
    sharedService.unitActive.subscribe(v => {
      this.activeUnit = v
    })
  }

  async getUnit(id) {
    const params = {
      "userId": id,
      "search": "",
      "pageNumber": 1,
      "rowsPerPage": 10
    }


    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/User/GetUnit`, params)
      .toPromise()
      .then(response => {
        // response = this.unitCleaner.cleanLoja(response)


        return response
      }).catch(e => console.error(e))
  }

  getAllUnits(search?) {
    const user = this.userService.getUserData(1)
    let userId

    if (user) {
      userId = user.id
    } else {
      this.userService.logOut()
      return false
    }
    const params = {
      "userId": userId,
      "search": search || "",
      "pageNumber": 1,
      "rowsPerPage": 10
    }


    return this.http.post<any>(`${API_Routes.DATA_USER_API}/api/User/GetUserUnit`, params)
      .toPromise()
      .then(response => {
        response.data = this.unitCleaner.cleanLoja(response.data)
        return response
      }).catch(e => console.error(e))
  }

  createUser(params): any {
    return this.http.post(`${API_Routes.DATA_USER_API}/api/User`, params)
      .toPromise()
  }

  editUser(params): any {
    return this.http.put(`${API_Routes.DATA_USER_API}/api/User`, params)
      .toPromise()
  }
}
